import { Options, Vue, Prop } from 'vue-property-decorator'

@Options({
  name: 'ContentCardParamsMixin'
})
export default class ContentCardParamsMixin extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly showMinuteByMinuteLead!: boolean

  @Prop({ type: Number, default: 3 })
  readonly minuteByMinuteLength!: number

  @Prop({ type: Boolean, default: true })
  readonly showImage!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showTitle!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showLead!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showTag!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showDuration!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showGalleryBadge!: boolean

  @Prop({ type: Boolean, default: false })
  readonly showReleaseDate!: boolean

  @Prop({ type: Boolean, default: false })
  readonly showBorder!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showFadeout!: boolean

  @Prop({ type: String, default: 'small' })
  readonly size!: string

  @Prop({ type: Boolean })
  readonly cardHighlighted: boolean | undefined

  @Prop({ type: Boolean })
  readonly titleHighlighted: boolean | undefined
}
