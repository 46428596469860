"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeasurementDataToJSONTyped = exports.MeasurementDataToJSON = exports.MeasurementDataFromJSONTyped = exports.MeasurementDataFromJSON = void 0;
const runtime_1 = require("../runtime");
function MeasurementDataFromJSON(json) {
    return MeasurementDataFromJSONTyped(json, false);
}
exports.MeasurementDataFromJSON = MeasurementDataFromJSON;
function MeasurementDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentId': !(0, runtime_1.exists)(json, 'contentId') ? undefined : json['contentId'],
        'eventName': json['eventName'],
        'value': json['value'],
    };
}
exports.MeasurementDataFromJSONTyped = MeasurementDataFromJSONTyped;
function MeasurementDataToJSON(value) {
    return MeasurementDataToJSONTyped(value, false);
}
exports.MeasurementDataToJSON = MeasurementDataToJSON;
function MeasurementDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentId': value.contentId,
        'eventName': value.eventName,
        'value': value.value,
    };
}
exports.MeasurementDataToJSONTyped = MeasurementDataToJSONTyped;
