"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoxToJSONTyped = exports.BoxToJSON = exports.BoxFromJSONTyped = exports.BoxFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BoxFromJSON(json) {
    return BoxFromJSONTyped(json, false);
}
exports.BoxFromJSON = BoxFromJSON;
function BoxFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'componentName': json['componentName'],
        'id': json['id'],
        'pageId': !(0, runtime_1.exists)(json, 'pageId') ? undefined : json['pageId'],
        'containerId': !(0, runtime_1.exists)(json, 'containerId') ? undefined : json['containerId'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'titleUrl': !(0, runtime_1.exists)(json, 'titleUrl') ? undefined : json['titleUrl'],
        'timeFrom': !(0, runtime_1.exists)(json, 'timeFrom') ? undefined : (new Date(json['timeFrom'])),
        'timeUntil': !(0, runtime_1.exists)(json, 'timeUntil') ? undefined : (new Date(json['timeUntil'])),
        'parameters': (0, _1.BoxParametersFromJSON)(json['parameters']),
        'containedBoxes': !(0, runtime_1.exists)(json, 'containedBoxes') ? undefined : (json['containedBoxes'].map(BoxFromJSON)),
        'layoutId': !(0, runtime_1.exists)(json, 'layoutId') ? undefined : json['layoutId'],
        'column': !(0, runtime_1.exists)(json, 'column') ? undefined : json['column'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
    };
}
exports.BoxFromJSONTyped = BoxFromJSONTyped;
function BoxToJSON(value) {
    return BoxToJSONTyped(value, false);
}
exports.BoxToJSON = BoxToJSON;
function BoxToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'componentName': value.componentName,
        'id': value.id,
        'pageId': value.pageId,
        'containerId': value.containerId,
        'title': value.title,
        'titleUrl': value.titleUrl,
        'timeFrom': value.timeFrom === undefined ? undefined : (value.timeFrom.toISOString()),
        'timeUntil': value.timeUntil === undefined ? undefined : (value.timeUntil.toISOString()),
        'parameters': (0, _1.BoxParametersToJSON)(value.parameters),
        'containedBoxes': value.containedBoxes === undefined ? undefined : (value.containedBoxes.map(BoxToJSON)),
        'layoutId': value.layoutId,
        'column': value.column,
        'label': value.label,
    };
}
exports.BoxToJSONTyped = BoxToJSONTyped;
