
import { MinimalContentData } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import ContentDecoratorMixin from '../../mixins/ContentDecoratorMixin'
import VideoPlayer from '../VideoPlayer.vue'
import ContentCard from './ContentCard.vue'

@Options({
  name: 'RecommendedVideoList',
  components: {
    VideoPlayer,
    ContentCard
  }
})
export default class RecommendedVideoList extends mixins(ContentDecoratorMixin) {
  items: Array<MinimalContentData> = []
  autoplay = false

  @Prop({ type: Number, default: 4 })
  readonly length!: number

  @Prop({ type: Array<MinimalContentData>, default: [] })
  readonly contents!: Array<MinimalContentData>

  get bigContent (): MinimalContentData | null {
    return this.items[0] || null
  }

  get secondColContents (): Array<MinimalContentData> {
    return this.items.slice(1, this.length) || []
  }

  swapVideo (id: string) {
    this.autoplay = true
    const i = this.items.findIndex(item => item.id === id)
    if (i > 0) {
      const target = this.items[i]
      this.items[i] = this.items[0]
      this.items[0] = target
    }
  }

  mounted () {
    this.items = [...this.contents]
  }
}
