
import { Options, Vue } from 'vue-property-decorator'

import ArrowRight from '../icons/ArrowRight.vue'
import Done from '../icons/Done.vue'

@Options({
  name: 'ConfirmScreen',
  components: {
    ArrowRight,
    Done
  }
})
export default class ConfirmScreen extends Vue {
}
