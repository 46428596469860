

import { ContentData, GalleryImage } from '@rtl/api'
import { Options, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import { CopyHelper, getPublicUrl, stripTags } from '../../../utils'
import BannerWidget from '../../widgets/BannerWidget.vue'
import RelatedContentWidget from '../../widgets/RelatedContentWidget.vue'
import Close from '../icons/Close.vue'
import Facebook from '../icons/Facebook.vue'
import Link from '../icons/Link.vue'
import Reload from '../icons/Reload.vue'
import SliderLeft from '../icons/SliderLeft.vue'
import SliderRight from '../icons/SliderRight.vue'
import Twitter from '../icons/Twitter.vue'
import Viber from '../icons/Viber.vue'

type Img = {
  id: number
  src: string | undefined
  class: string
}

enum Direction {
  LEFT, RIGHT
}

class ImageQueue<T> {
  private store: T[] = [];
  items () {
    return this.store
  }

  getItem (i:number): T | undefined {
    return this.store.length > i ? this.store[i] : undefined
  }

  push (val: T) {
    this.store.push(val)
  }

  pop (n = 1) {
    this.store.splice(0, n)
  }

  size ():number {
    return this.store.length
  }
}

@Options({
  name: 'Gallery',
  components: {
    RelatedContentWidget,
    SliderRight,
    SliderLeft,
    Close,
    BannerWidget,
    Facebook,
    Twitter,
    Viber,
    Link,
    Reload
  }
})

export default class Gallery extends Vue {
  @Prop({ type: Object, default: null })
  readonly content!: ContentData

  @Ref('imgWrapper')
  imgWrapper: HTMLElement | undefined

  selectedImageIndex = 0
  fullScreen = false
  selectedImage:GalleryImage | null = null
  imageQueue:ImageQueue<Img> = new ImageQueue<Img>()
  imageId = 0
  touchStartX = 0
  touchEndX = 0
  initRoute = ''
  routeReferer = null
  copyHelper = CopyHelper.create()
  relatedContentCol = 2

  mounted () {
    document.body.classList.add('overflow-hidden')
    this.selectedImageIndex = this.getRouteIndex()
    this.setRelatedContentCol()
    this.setArrowKeyListener()
    this.selectedImage = this.gallery?.length ? this.gallery[this.selectedImageIndex] : null
    this.imageQueue.push({
      id: this.selectedImageIndex,
      src: this.selectedImage?.imageUrls?.imageUrl,
      class: 'actual'
    })
    this.initRoute = `${this.$route.path}?index=1`
    this.routeReferer = (this as any).$routeReferer
    this.initSwipeEvent()
    window.addEventListener('resize', this.onResize)
  }

  unmounted () {
    document.body.classList.remove('overflow-hidden')
    document.removeEventListener('keydown', this.onKeydown)
    document.removeEventListener('resize', this.onResize)
    this.removeSwipeEvent()
  }

  onSwipeStart (event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].screenX
  }

  onSwipeEnd (event: TouchEvent) {
    this.touchEndX = event.changedTouches[0].screenX

    if (this.touchEndX < this.touchStartX) {
      this.slideRight()
    } else if (this.touchEndX > this.touchStartX) {
      this.slideLeft()
    }
  }

  initSwipeEvent () {
    this.imgWrapper?.addEventListener('touchstart', this.onSwipeStart, false)
    this.imgWrapper?.addEventListener('touchend', this.onSwipeEnd, false)
  }

  removeSwipeEvent () {
    this.imgWrapper?.removeEventListener('touchstart', this.onSwipeStart)
    this.imgWrapper?.removeEventListener('touchend', this.onSwipeEnd)
  }

  setArrowKeyListener () {
    document.addEventListener('keydown', this.onKeydown)
  }

  setRelatedContentCol () {
    this.relatedContentCol = window.innerWidth < 768 ? 1 : 2
  }

  onKeydown (e:KeyboardEvent) {
    if (e.code === 'ArrowLeft') {
      this.slideLeft()
    } else if (e.code === 'ArrowRight') {
      this.slideRight()
    }
  }

  onResize () {
    this.setRelatedContentCol()
  }

  get image () {
    return this.selectedImage || (this.gallery?.length ? this.gallery[0] : null)
  }

  get gallery () {
    return this.content?.params?.galleryImages
  }

  selectImageByIndex (i: number) {
    const selectedBefore = this.selectedImageIndex
    this.selectedImageIndex = i
    this.swipe(i < selectedBefore ? Direction.LEFT : Direction.RIGHT)
  }

  get leftSwipeDisabled () {
    return this.selectedImageIndex <= 0
  }

  get rightSwipeDisabled () {
    return this.gallery ? (this.gallery.length + 1 <= this.selectedImageIndex + 1) : false
  }

  slideLeft () {
    if (!this.leftSwipeDisabled) {
      this.goToIndexRoute(this.selectedImageIndex - 1)
    }
  }

  slideRight () {
    if (!this.rightSwipeDisabled) {
      this.goToIndexRoute(this.selectedImageIndex + 1)
    }
  }

  swipe (direction: Direction) {
    let classSlideOut, classSlideIn
    if (direction === Direction.RIGHT) {
      classSlideOut = 'leave-to-right'
      classSlideIn = 'enter-from-left'
    } else {
      classSlideOut = 'leave-to-left'
      classSlideIn = 'enter-from-right'
    }

    const selectedImage = this.gallery ? this.gallery[this.selectedImageIndex] : null
    if (!selectedImage) {
      return
    }
    this.selectedImage = selectedImage

    this.setImageStyleClass(0, classSlideOut)

    this.imageQueue.push({
      id: this.imageId++,
      src: selectedImage?.imageUrls?.imageUrl,
      class: classSlideIn
    })

    setTimeout(() => {
      if (this.imageQueue.size() > 1) { this.imageQueue.pop() }
    }, 400)
  }

  setImageStyleClass (index: number, clazz: string) {
    const item = this.imageQueue.getItem(index)
    if (item) {
      item.class = clazz
      item.id = this.imageId++
    }
  }

  goToIndexRoute (index: number) {
    this.$router.replace({ query: { index: index + 1 } })
  }

  getRouteIndex () {
    return this.$route.query?.index ? (Number(this.$route.query.index) - 1) : 0
  }

  get isGalleryEnd (): boolean {
    const galleryLength = (this.gallery?.length ?? 0) + 1
    return (this.selectedImageIndex + 1) === galleryLength
  }

  @Watch('$route', { immediate: false })
  updateIndexByRoute () {
    this.selectImageByIndex(this.getRouteIndex())
  }

  stripTags (text: string | undefined): string | undefined {
    return stripTags(text)
  }

  copyLink () {
    this.copyHelper.copy(this.publicUrl)
  }

  get publicUrl (): string {
    return getPublicUrl()
  }

  close () {
    this.routeReferer ? this.$router.back() : this.$router.push('/')
  }
}
