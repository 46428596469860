import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "32",
  viewBox: "0 0 24 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M18.497 25.9048H5.501L5.499 22.4396C2.086 20.2037 0 16.3556 0 12.1905C0 5.46844 5.383 0 12 0C18.617 0 24 5.46844 24 12.1905C24 16.3556 21.915 20.2027 18.503 22.4376L18.497 25.9048ZM8.499 22.8571H15.503L15.507 20.6618L16.289 20.2301C19.195 18.6281 21 15.5469 21 12.1905C21 7.14971 16.963 3.04762 12 3.04762C7.037 3.04762 3 7.14971 3 12.1905C3 15.5479 4.807 18.6291 7.715 20.2331L8.499 20.6659V22.8571Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M18.5 28.9524H5.5V32H18.5V28.9524Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_3)
  ]))
}