import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "27",
  height: "31",
  viewBox: "0 0 27 31",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M21 31H0V6H21V31ZM3 28H18V9H3V28Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M15 14H6V17H15V14Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M15 20H6V23H15V20Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_4),
    _createElementVNode("path", {
      d: "M27 25H24.0811V2.93878H8V0H27V25Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_5)
  ]))
}