import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_card_list = _resolveComponent("content-card-list")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_ctx.contentList?.items.length)
    ? (_openBlock(), _createBlock(_component_widget_block, {
        key: 0,
        title: _ctx.title,
        "show-divider": !!_ctx.title,
        "btn-label": _ctx.btnLabel,
        "btn-url": _ctx.btnUrl,
        showSlider: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_content_card_list, {
            "show-lead": _ctx.showLead,
            "show-border": _ctx.showBorder,
            contents: _ctx.contentList?.items,
            cols: _ctx.cols,
            showSlider: _ctx.showSlider,
            size: 'shrink'
          }, null, 8, ["show-lead", "show-border", "contents", "cols", "showSlider"])
        ]),
        _: 1
      }, 8, ["title", "show-divider", "btn-label", "btn-url"]))
    : _createCommentVNode("", true)
}