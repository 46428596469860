
import { Options, Vue } from "vue-property-decorator";
import { SocialProcessPage as OauthPage } from "@rtl/ui";

@Options({
  components: {
    OauthPage,
  },
})
export default class VoteOathProcessPage extends Vue {}
