"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminChildFoldersDataPayloadToJSONTyped = exports.AdminChildFoldersDataPayloadToJSON = exports.AdminChildFoldersDataPayloadFromJSONTyped = exports.AdminChildFoldersDataPayloadFromJSON = void 0;
const runtime_1 = require("../runtime");
function AdminChildFoldersDataPayloadFromJSON(json) {
    return AdminChildFoldersDataPayloadFromJSONTyped(json, false);
}
exports.AdminChildFoldersDataPayloadFromJSON = AdminChildFoldersDataPayloadFromJSON;
function AdminChildFoldersDataPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'parentFolderId': !(0, runtime_1.exists)(json, 'parentFolderId') ? undefined : json['parentFolderId'],
    };
}
exports.AdminChildFoldersDataPayloadFromJSONTyped = AdminChildFoldersDataPayloadFromJSONTyped;
function AdminChildFoldersDataPayloadToJSON(value) {
    return AdminChildFoldersDataPayloadToJSONTyped(value, false);
}
exports.AdminChildFoldersDataPayloadToJSON = AdminChildFoldersDataPayloadToJSON;
function AdminChildFoldersDataPayloadToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'parentFolderId': value.parentFolderId,
    };
}
exports.AdminChildFoldersDataPayloadToJSONTyped = AdminChildFoldersDataPayloadToJSONTyped;
