import { AccountInfoResponse, AccountRequest, NotifyLoginRequest, SocialRequest } from '../../gigya'
import { Options, Prop, mixins } from 'vue-property-decorator'
import { USER_ACTION, USER_NAMESPACE } from '../../store'

import { Action } from 'vuex-class'
import AuthDataEditMixin from './AuthDataEditMixin'
import { Capacitor } from '@capacitor/core'
import GetUserMixin from './GetUserMixin'
import { ROUTES } from '../../router'
import { RouteLocationRaw } from 'vue-router'
import SocialLinkAccount from '../components/SocialLinkAccount.vue'

@Options({
  name: 'AuthPageMixin',
  components: {
    SocialLinkAccount
  }
})
export default class AuthPageMixin extends mixins(GetUserMixin, AuthDataEditMixin) {
  @Action(USER_ACTION.SOCIAL, { namespace: USER_NAMESPACE })
  socialUserAction!: (params: SocialRequest) => Promise<URL>

  @Action(USER_ACTION.ACCOUNT_INFO, { namespace: USER_NAMESPACE })
  accountInfoAction!: (params: AccountRequest) => Promise<AccountInfoResponse>

  @Action(USER_ACTION.NOTIFY_LOGIN, { namespace: USER_NAMESPACE })
  notifyLoginAction!: (params: NotifyLoginRequest) => Promise<URL>

  @Prop({
    type: [String, Object],
    default: () => ({ name: ROUTES.INDEX })
  })
  readonly successUrl!: RouteLocationRaw

  loginID = ''
  regToken = ''

  showLinkModal = false
  showRegFinishModal = false

  showRegModal() {
    return !!(!this.user?.profile?.birthYear && this.user?.UID);
  }

  async processAccessToken(token: string) {
    const response = await this.accountInfoAction({ login_token: token! })
    await this.notifyLoginAction({ siteUID: response.UID, UIDSig: response.UIDSignature, UIDTimestamp: response.signatureTimestamp })
    if (this.showRegModal()) {
      this.showRegFinishModal = true
    } else {
      this.$router.push(this.successUrl)
    }
  }

  mounted () {
    this.processSocialQueryData(window.location.search)
  }

  processSocialQueryData (searchData: string) {
    const data = new URLSearchParams(searchData)

    if (data instanceof URLSearchParams && data.get('access_token') != null) {
      this.processAccessToken(data.get('access_token')!)
    } else if (data instanceof URLSearchParams && data.get('existingLoginID') != null) {
      this.loginID = data.get('existingLoginID') as string
      this.regToken = data.get('x_regToken') as string;
      this.showLinkModal = true
    }
  }

  onPostMessage(response: MessageEvent) {
    if (response.origin !== window.location.origin && response.data.data !== undefined) {
      return
    }

    const _response = JSON.parse(response.data)
    this.processSocialQueryData(_response?.query)
  }

  socialLogin(e: any): boolean {
    const locationOrigin = this.$store.getters.getEnv('AUTH_REDIRECT') ?? window.location.origin;
    const redURI = Capacitor.getPlatform() === 'web' ? window.location.origin + '/auth/social-process' : locationOrigin + '/auth/social-redirect'
    this.socialUserAction({
      x_provider: e.target.getAttribute('data-social'),
      redirect_uri: redURI,
      response_type: 'server_token'
    })
      .then(response => {
        const left = window.screen.width / 2 - 250;
        const top = window.screen.height / 2 - 350;
        window.open(response, '_blank', 'width=500,height=700,left=' + left + ',top=' + top)
      })
    return false;
  }
}
