"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadFormResultToJSONTyped = exports.UploadFormResultToJSON = exports.UploadFormResultFromJSONTyped = exports.UploadFormResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UploadFormResultFromJSON(json) {
    return UploadFormResultFromJSONTyped(json, false);
}
exports.UploadFormResultFromJSON = UploadFormResultFromJSON;
function UploadFormResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': (0, _1.HTTPStatusCodeFromJSON)(json['status']),
        'data': (json['data'].map(_1.UploadFormResultDataFromJSON)),
        'result': (0, _1.ResultTypeFromJSON)(json['result']),
        'error': !(0, runtime_1.exists)(json, 'error') ? undefined : (0, _1.ErrorDataFromJSON)(json['error']),
    };
}
exports.UploadFormResultFromJSONTyped = UploadFormResultFromJSONTyped;
function UploadFormResultToJSON(value) {
    return UploadFormResultToJSONTyped(value, false);
}
exports.UploadFormResultToJSON = UploadFormResultToJSON;
function UploadFormResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': (0, _1.HTTPStatusCodeToJSON)(value.status),
        'data': (value.data.map(_1.UploadFormResultDataToJSON)),
        'result': (0, _1.ResultTypeToJSON)(value.result),
        'error': (0, _1.ErrorDataToJSON)(value.error),
    };
}
exports.UploadFormResultToJSONTyped = UploadFormResultToJSONTyped;
