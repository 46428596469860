"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TvProgramDataToJSONTyped = exports.TvProgramDataToJSON = exports.TvProgramDataFromJSONTyped = exports.TvProgramDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TvProgramDataFromJSON(json) {
    return TvProgramDataFromJSONTyped(json, false);
}
exports.TvProgramDataFromJSON = TvProgramDataFromJSON;
function TvProgramDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'rtlklub': !(0, runtime_1.exists)(json, 'rtlklub') ? undefined : (0, _1.TvProgramByProgDaysFromJSON)(json['rtlklub']),
    };
}
exports.TvProgramDataFromJSONTyped = TvProgramDataFromJSONTyped;
function TvProgramDataToJSON(value) {
    return TvProgramDataToJSONTyped(value, false);
}
exports.TvProgramDataToJSON = TvProgramDataToJSON;
function TvProgramDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'rtlklub': (0, _1.TvProgramByProgDaysToJSON)(value.rtlklub),
    };
}
exports.TvProgramDataToJSONTyped = TvProgramDataToJSONTyped;
