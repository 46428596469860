"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CelebTabsToJSONTyped = exports.CelebTabsToJSON = exports.CelebTabsFromJSONTyped = exports.CelebTabsFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CelebTabsFromJSON(json) {
    return CelebTabsFromJSONTyped(json, false);
}
exports.CelebTabsFromJSON = CelebTabsFromJSON;
function CelebTabsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'tabLabel': !(0, runtime_1.exists)(json, 'tabLabel') ? undefined : json['tabLabel'],
        'tabContent': !(0, runtime_1.exists)(json, 'tabContent') ? undefined : json['tabContent'],
        'siteId': !(0, runtime_1.exists)(json, 'siteId') ? undefined : json['siteId'],
        'sequenceNumber': !(0, runtime_1.exists)(json, 'sequenceNumber') ? undefined : json['sequenceNumber'],
        'characters': !(0, runtime_1.exists)(json, 'characters') ? undefined : (json['characters'].map(_1.CharacterFromJSON)),
    };
}
exports.CelebTabsFromJSONTyped = CelebTabsFromJSONTyped;
function CelebTabsToJSON(value) {
    return CelebTabsToJSONTyped(value, false);
}
exports.CelebTabsToJSON = CelebTabsToJSON;
function CelebTabsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'tabLabel': value.tabLabel,
        'tabContent': value.tabContent,
        'siteId': value.siteId,
        'sequenceNumber': value.sequenceNumber,
        'characters': value.characters === undefined ? undefined : (value.characters.map(_1.CharacterToJSON)),
    };
}
exports.CelebTabsToJSONTyped = CelebTabsToJSONTyped;
