import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "article-page__authors"
}
const _hoisted_2 = { class: "article-page__author-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag = _resolveComponent("tag")!
  const _component_Image = _resolveComponent("Image")!
  const _component_recommended_video_widget = _resolveComponent("recommended-video-widget")!
  const _component_RelatedContentWidget = _resolveComponent("RelatedContentWidget")!
  const _component_article_index_widget = _resolveComponent("article-index-widget")!
  const _component_widget_block = _resolveComponent("widget-block")!
  const _component_static_page = _resolveComponent("static-page")!
  const _directive_visibility = _resolveDirective("visibility")!

  return (_openBlock(), _createBlock(_component_static_page, { class: "article-page" }, _createSlots({
    topheader: _withCtx(({ content }) => [
      (_ctx.showHeadingTag && content.headingTag)
        ? (_openBlock(), _createBlock(_component_tag, {
            key: 0,
            class: "article-page__tag",
            tag: content.headingTag,
            "is-live": _ctx.isLive(content)
          }, null, 8, ["tag", "is-live"]))
        : _createCommentVNode("", true)
    ]),
    subheader: _withCtx(({ content }) => [
      (_ctx.showAuthors && content.authors)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(content.authors, (author) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "article-page__author",
                key: author.id
              }, [
                _createVNode(_component_Image, {
                  "image-url": author.imageUrls?.imageUrl,
                  alt: author.name,
                  class: "article-page__author-image"
                }, null, 8, ["image-url", "alt"]),
                _createElementVNode("span", _hoisted_2, _toDisplayString(author.name), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    afterContent: _withCtx(() => [
      (_ctx.showRecommendedVideos)
        ? (_openBlock(), _createBlock(_component_recommended_video_widget, {
            key: 0,
            lazy: true
          }))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.showRelatedContent)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_RelatedContentWidget, {
              class: "article-page__related",
              title: "Még több tartalom"
            })
          ])
        }
      : undefined,
    (_ctx.showIndexPage)
      ? {
          name: "after",
          fn: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_widget_block, {
              class: "article-page__recommendation",
              title: "Címlapról ajánljuk"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_article_index_widget, {
                  ref: "recommendation",
                  class: "article-page__recommendation-body"
                }, null, 512)
              ]),
              _: 1
            })), [
              [_directive_visibility, {
          onChange: _ctx.onChangeRecommendationVisibility,
          observerOptions: { rootMargin: '400px', threshold: 1.0 }
        }]
            ])
          ])
        }
      : undefined
  ]), 1024))
}