
import { ContentType } from '@rtl/api'
import { mixins, Options, Watch } from 'vue-property-decorator'

import { CopyHelper, fixLinkTarget, generateMetaInfo, getPublicUrl, parseHtmlString, stripTags } from '../../utils'
import AdultConfirm from '../components/AdultConfirm.vue'
import MinuteByMinute from '../components/content/MinuteByMinute.vue'
import FacebookFollow from '../components/FacebookFollow.vue'
import Close from '../components/icons/Close.vue'
import Facebook from '../components/icons/Facebook.vue'
import GalleryOutline from '../components/icons/GalleryOutline.vue'
import Link from '../components/icons/Link.vue'
import Twitter from '../components/icons/Twitter.vue'
import Viber from '../components/icons/Viber.vue'
import Image from '../components/Image.vue'
import Tag from '../components/Tag.vue'
import TimeAgo from '../components/TimeAgo.vue'
import VideoPlayer from '../components/VideoPlayer.vue'
import PageLayout from '../layouts/PageLayout.vue'
import { FetchContentItemMixin } from '../mixins'
import BannerWidget from '../widgets/BannerWidget.vue'
import ContentListWidget from '../widgets/ContentListWidget.vue'
import ContentWidgetRenderer from '../widgets/ContentWidgetRenderer.vue'

@Options({
  name: 'StaticPage',
  components: {
    ContentWidgetRenderer,
    PageLayout,
    AdultConfirm,
    BannerWidget,
    ContentListWidget,
    Facebook,
    Twitter,
    Viber,
    Image,
    Tag,
    Link,
    TimeAgo,
    VideoPlayer,
    Close,
    MinuteByMinute,
    GalleryOutline,
    FacebookFollow
  },
  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, this.content, this.$store)
  }
})
export default class StaticPage extends mixins(FetchContentItemMixin) {
  isVideoSticky = true
  isShowClose = false
  copyHelper = CopyHelper.create()

  get isMinuteByMinuteContent (): boolean {
    return this.content?.contentType === ContentType.MinuteByMinute
  }

  get isGallery (): boolean {
    return (this.content?.contentType === ContentType.Gallery || !!this.content?.params?.embedGalleryUrl)
  }

  get contentBody () {
    if (this.content?.body) {
      const regex = /<script\b[^>]*>[\s\S]*?<\/script\b[^>]*>/g
      const scripts = this.content.body.match(regex) || []
      let html = this.content.body
      const contentScripts: Array<{[key: string]: unknown}> = []

      scripts.forEach((script: string) => {
        html = html.replace(script, '')

        const parsed = parseHtmlString(script)
        if (parsed && parsed.length) {
          const attrs = Array.from((parsed[0] as HTMLScriptElement).attributes).reduce((acc: {[key: string]: unknown}, attr) => {
            acc[attr.nodeName] = attr.nodeValue
            return acc
          }, {})
          contentScripts.push(attrs)
        }
      })

      return {
        html: html,
        scripts: contentScripts
      }
    }
    return null
  }

  videoWrapperCheck () {
    const wrapper = document.querySelector('.static-page__featured-video-wrapper')
    const top = wrapper?.getBoundingClientRect().top || null
    const minTop = 55
    if (top !== null) {
      this.isShowClose = Math.round(top) <= minTop
    }
  }

  @Watch('content.params.embedVideoUrl', { immediate: true })
  initStickyScroll () {
    if (typeof window !== 'undefined' && this.content?.params?.embedVideoUrl) {
      document.addEventListener('scroll', this.videoWrapperCheck)
    }
  }

  unmounted () {
    if (this.content?.params?.embedVideoUrl) {
      document.removeEventListener('scroll', this.videoWrapperCheck)
    }
  }

  copyLink () {
    this.copyHelper.copy(this.publicUrl)
  }

  get publicUrl (): string {
    return getPublicUrl()
  }

  fixLinkTarget (src: string) {
    // Removing target="_blank" from links, because it can break mobile app navigation.
    return fixLinkTarget(src)
  }

  stripTags (text: string | undefined): string | undefined {
    return stripTags(text)
  }

  galleryClick (): void {
    this.$router.push(this.content?.params?.embedGalleryUrl)
  }
}
