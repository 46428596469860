
import { Options, Prop, Vue } from 'vue-property-decorator'

import { FaqQA } from './../../../store'

@Options({
  name: 'FaqAccordion'
})
export default class FaqAccordion extends Vue {
  @Prop({
    type: Object as () => FaqQA,
    default: {}
  })
  readonly qa!: FaqQA

  @Prop({
    type: String,
    default: ''
  })
  readonly subTopic!: string

  @Prop({
    type: String,
    default: ''
  })
  readonly mainTopic!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly selected!: boolean

  get searchId () {
    return `${this.mainTopic}:::${this.subTopic}:::${this.qa.tag}`
  }

  mounted () {
    (this.$refs.accordion as InstanceType<typeof HTMLDetailsElement>).open = this.selected
  }
}
