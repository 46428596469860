
import { Options, Prop, Vue } from 'vue-property-decorator'

import AccountBilling from './../icons/AccountBilling.vue'
import Bulb from './../icons/Bulb.vue'
import Settings from './../icons/Settings.vue'
import Show from './../icons/Show.vue'
import User from './../icons/User.vue'

@Options({
  name: 'FaqIcon',
  components: {
    User,
    AccountBilling,
    Bulb,
    Settings,
    Show
  }
})
export default class FaqIcon extends Vue {
  @Prop({
    type: String,
    default: 'User'
  })
  readonly componentName!: string;
}
