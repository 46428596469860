
import { ContentType, MinimalContentData } from '@rtl/api'
import { formatDistance } from 'date-fns'
import { hu } from 'date-fns/locale'
import { io, Socket } from 'socket.io-client'
import { Options, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { CONTENT_ACTION, CONTENT_NAMESPACE, ContentFeedPayload, ContentListResult } from '../../../store'
import { isExternalUrl, runScripts } from '../../../utils'
import ArrowRight from '../icons/ArrowRight.vue'
import Close from '../icons/Close.vue'
import NavLink from '../NavLink.vue'
import Pagination from '../Pagination.vue'

/* interface FeedPayload {
  feedContentId: string
  refreshUrl: string
  item: MinimalContentData
} */

@Options({
  name: 'MinuteByMinute',
  components: {
    NavLink,
    Close,
    ArrowRight,
    Pagination
  }
})
export default class MinuteByMinute extends Vue {
  @Action(CONTENT_ACTION.FEED, { namespace: CONTENT_NAMESPACE })
  storeFetchContentFeed!: (payload: ContentFeedPayload) => Promise<ContentListResult>

  @Prop({
    type: String,
    required: true
  })
  readonly contentId!: string

  @Prop({
    type: Number,
    default: 15
  })
  readonly length!: number

  @Ref('minuteByMinuteBlock')
  minuteByMinuteBlock: HTMLElement | null = null

  paginationPage = 1
  webSocket: Socket | null = null
  feedNotificationOn = false

  get feedContents (): Array<MinimalContentData> {
    return this.contentFeed?.items || []
  }

  get contentFeed (): ContentListResult['data'] | null {
    if (this.contentFeedPayload) {
      return this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.FEED], this.contentFeedPayload)?.data || null
    }
    return null
  }

  get contentFeedPayload (): ContentFeedPayload | null {
    return {
      contentId: this.contentId,
      limit: this.length,
      offset: (this.paginationPage - 1) * this.length,
      totalCount: 1
    }
  }

  isLinkable (content: MinimalContentData): boolean {
    return content.contentType !== ContentType.Post
  }

  getContentTitle (content: MinimalContentData): string | null {
    return (content.alternativeTitle || content.title || '').trim() || null
  }

  getContentBody (content: MinimalContentData): string {
    const html = `${content.lead || ''}${content.body || ''}`
    if (this.isLinkable(content)) {
      const endTag = '</p>'
      const part = html.split(endTag, 2).join(endTag)
      return part.length === html.length ? html : part + endTag
    }
    return html
  }

  getContentUrl (content: MinimalContentData): string | null {
    const url = content.outerUrl || content.url || ''
    return !url || isExternalUrl(url) || url.startsWith('/') ? url : `/${url}`
  }

  getContentReleaseDate (content: MinimalContentData): Date | null {
    return content.releaseDate || null
  }

  getContentTimeAgo (content: MinimalContentData): string | null {
    return content.releaseDate ? formatDistance(content.releaseDate, new Date(), { locale: hu, addSuffix: true }) : null
  }

  @Watch('contentFeedPayload', { deep: true })
  async fetchContentFeed () {
    if (this.contentFeedPayload) {
      return this.storeFetchContentFeed(this.contentFeedPayload)
    }
  }

  @Watch('$route.query.oldal', { immediate: true })
  updatePageFromRoute () {
    const page = 'oldal' in this.$route.query
      ? Math.max(1, parseInt(this.$route.query.oldal as string))
      : null
    this.paginationPage = page || 1

    if (page) {
      this.scrollToPP()
    }
  }

  closeFeedNotification (): void {
    this.feedNotificationOn = false
  }

  refreshPage (): void {
    const url = this.$router.resolve({ path: this.$route.path, query: { oldal: 1 } }).href
    window.location.href = url
  }

  initWebSocket (): void {
    const wsUrl = this.$store.getters.getEnv('WS_URL')
    if (wsUrl) {
      this.webSocket = io(wsUrl)
      this.webSocket.on(`public/feed/${this.contentId}`, this.onWebSocketFeed)
      this.webSocket.on('disconnect', this.onWebSocketDisconnect)
    }
  }

  onWebSocketFeed (/* msg: FeedPayload */): void {
    this.feedNotificationOn = true
  }

  onWebSocketDisconnect () {
    if (this.webSocket) {
      this.webSocket.removeAllListeners()
      this.webSocket = null
    }
  }

  @Watch('contentFeed', { deep: true })
  runScripts () {
    this.$nextTick(() => {
      const el = this.$refs.minuteByMinuteBlock as HTMLDivElement | null
      if (el) {
        runScripts(el)
        if (typeof window !== 'undefined') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).instgrm?.Embeds?.process()
        }
      }
    })
  }

  scrollToPP () {
    this.$nextTick(() => {
      if (typeof window !== 'undefined' && this.minuteByMinuteBlock) {
        window.scrollTo({
          top: this.minuteByMinuteBlock.offsetTop - 250
        })
      }
    })
  }

  async serverPrefetch () {
    return this.fetchContentFeed()
  }

  mounted () {
    this.initWebSocket()
    return this.fetchContentFeed()
  }

  unmounted () {
    if (this.webSocket && this.webSocket.connected) {
      this.webSocket.disconnect()
    }
  }
}
