
import { Options, Vue } from "vue-property-decorator";
import { SocialRedirectPage as OauthPage } from "@rtl/ui";

@Options({
  components: {
    OauthPage,
  },
})
export default class VoteOathPage extends Vue {}
