import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login_page = _resolveComponent("login-page")!
  const _component_container = _resolveComponent("container")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, { is: "section" }, {
    default: _withCtx(() => [
      _createVNode(_component_container, { type: "section" }, {
        default: _withCtx(() => [
          _createVNode(_component_login_page, {
            "success-url": { name: 'vote-pending' },
            "signup-url": { name: 'vote-signup' },
            "password-url": { name: 'vote-password' },
            "show-image": false
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}