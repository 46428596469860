
import { ListDefaultContentsContentTypesEnum } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import { ContentListDecoratedPayload, ContentListPayload } from '../../store'
import LongVideoWidgetBlock from '../components/widget/LongVideoWidgetBlock.vue'
import { FetchContentListMixin, GetPageMixin } from '../mixins'
import WidgetMixin, { WidgetMeta, widgetMetaFields } from './WidgetMixin'
import WidgetStyleMixin, { widgetStyleFields } from './WidgetStyleMixin'

export const meta: WidgetMeta = {
  label: 'Longform lista',
  groups: {
    style: 'Beállítás',
    data: 'Adatok'
  },
  defaultGroup: 'style',
  fields: {
    ...widgetMetaFields,
    ...widgetStyleFields,
    length: {
      type: Number,
      default: 12,
      props: {
        placeholder: 'Darabszám',
        min: 1
      }
    },
    payload: {
      group: 'data',
      type: 'payload',
      default: {
        contentTypes: [ListDefaultContentsContentTypesEnum.Longvideo]
      }
    }
  }
}

@Options({
  name: 'LongVideoListWidget',
  components: {
    LongVideoWidgetBlock
  }
})
export default class LongVideoListWidget extends mixins(GetPageMixin, WidgetMixin, FetchContentListMixin, WidgetStyleMixin) {
  @Prop({
    type: Number,
    default: 12
  })
  readonly length!: number

  @Prop({
    type: Object,
    default: () => ({})
  })
  readonly payload!: Partial<ContentListDecoratedPayload>

  get contentListPayload (): ContentListPayload {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { decorators, ...payload } = this.payload

    return {
      folderIds: !payload?.tags && this.page?.site?.folderId ? [this.page?.site?.folderId] : undefined,
      contentTypes: [ListDefaultContentsContentTypesEnum.Longvideo],
      limit: this.length,
      ...payload
    }
  }
}
