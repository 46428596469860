"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayLoadSortOrderToJSONTyped = exports.PayLoadSortOrderToJSON = exports.PayLoadSortOrderFromJSONTyped = exports.PayLoadSortOrderFromJSON = exports.PayLoadSortOrder = void 0;
/**
 * SortOrder for PayLoad
 * @export
 * @enum {string}
 */
var PayLoadSortOrder;
(function (PayLoadSortOrder) {
    PayLoadSortOrder["Asc"] = "asc";
    PayLoadSortOrder["Desc"] = "desc";
})(PayLoadSortOrder = exports.PayLoadSortOrder || (exports.PayLoadSortOrder = {}));
function PayLoadSortOrderFromJSON(json) {
    return PayLoadSortOrderFromJSONTyped(json, false);
}
exports.PayLoadSortOrderFromJSON = PayLoadSortOrderFromJSON;
function PayLoadSortOrderFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PayLoadSortOrderFromJSONTyped = PayLoadSortOrderFromJSONTyped;
function PayLoadSortOrderToJSON(value) {
    return PayLoadSortOrderToJSONTyped(value, false);
}
exports.PayLoadSortOrderToJSON = PayLoadSortOrderToJSON;
function PayLoadSortOrderToJSONTyped(value, ignoreDiscriminator = false) {
    return value;
}
exports.PayLoadSortOrderToJSONTyped = PayLoadSortOrderToJSONTyped;
