
import {
  SiteData,
  SiteListQueryOrderDirectionEnum,
  SiteListQueryOrderFieldEnum,
  SiteTypes,
  TvProgramData,
  TvProgramItem
} from '@rtl/api'
import { addDays, differenceInHours, differenceInMinutes, format } from 'date-fns'
import { cloneDeep } from 'lodash'
import { mixins, Options, Ref, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import {
  SITE_ACTION, SITE_NAMESPACE, SiteListPayload, SiteListResult,
  TV_PROGRAM_ACTION,
  TV_PROGRAM_NAMESPACE,
  TvProgramPayload,
  TvProgramResult
} from '../../store'
import { generateMetaInfo } from '../../utils'
import AgeLimit from '../components/AgeLimit.vue'
import InfoTooltip from '../components/icons/InfoTooltip.vue'
import SliderLeft from '../components/icons/SliderLeft.vue'
import SliderRight from '../components/icons/SliderRight.vue'
import Image from '../components/Image.vue'
import Modal from '../components/Modal.vue'
import NavLink from '../components/NavLink.vue'
import WidgetBlock from '../components/widget/WidgetBlock.vue'
import PageLayout from '../layouts/PageLayout.vue'
import { GetPageMixin } from '../mixins'

type ChannelProgramData = Array<{ name: string, programs: Array<TvProgramItem>}>
type ProgramData = {
  timeRange:{ start: Date | null, end: Date | null }
  channels: ChannelProgramData
}

enum DIRECTION {LEFT, RIGHT}

type ListFilter = {
  title: string,
  selected: boolean,
  range: {
    startDate?: Date;
    endDate?: Date;
  }
}

@Options({
  name: 'ProgramListPage',
  components: {
    PageLayout,
    InfoTooltip,
    WidgetBlock,
    Image,
    NavLink,
    SliderLeft,
    SliderRight,
    AgeLimit,
    Modal
  },
  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})
export default class ProgramListPage extends mixins(GetPageMixin) {
  DIRECTION = DIRECTION

  @Ref('timelineSheet')
  timelineSheet!: HTMLElement

  @Ref('lineNow')
  lineNow!: HTMLElement

  @Action(TV_PROGRAM_ACTION.LIST, { namespace: TV_PROGRAM_NAMESPACE })
  storeFetchTvProgramList!: (payload?: TvProgramPayload) => Promise<TvProgramResult>

  get programData (): ProgramData | null {
    const data = this.$store.getters['$cache/result']([TV_PROGRAM_NAMESPACE, TV_PROGRAM_ACTION.LIST], this.programDataPayload)?.data || null
    return data ? this.transformProgramData(data) : null
  }

  @Action(SITE_ACTION.LIST, { namespace: SITE_NAMESPACE })
  storeFetchSiteList!: (payload: SiteListPayload) => Promise<SiteListResult>

  get siteListData (): Array<SiteData> | null {
    return this.$store.getters['$cache/result']([SITE_NAMESPACE, SITE_ACTION.LIST], this.siteListPayload)?.data?.items || null
  }

  currentDay = this.getCurrentDay()
  programListFilters:Array<ListFilter> = []
  programInfo:TvProgramItem | null = null
  grabbing = false
  startX = 0
  scrollLeft = 0
  timeWidth = 380
  swipeArrowState = {
    left: { disabled: true },
    right: { disabled: false }
  }

  programDataPayload = {}

  @Watch('programDataPayload', { deep: true })
  fetchProgramData () {
    return this.storeFetchTvProgramList(this.programDataPayload)
  }

  created () {
    this.setDateFilters()
  }

  async fetchAll () {
    return Promise.all([
      this.storeFetchSiteList(this.siteListPayload),
      this.fetchProgramData().then(() => this.goToNow())
    ])
  }

  serverPrefetch () {
    return this.fetchAll()
  }

  mounted () {
    this.timelineSheet.addEventListener('scroll', this.setSwipeArrowState)

    return this.fetchAll()
  }

  unmounted () {
    this.timelineSheet.removeEventListener('scroll', this.setSwipeArrowState)
  }

  get showPositionNow (): boolean {
    return (this.programListFilters?.find((item) => { return item.selected })?.range.startDate?.getDate() === this.getCurrentDay().getDate()) || false
  }

  @Watch('positionNow')
  @Watch('timeLineHours', { deep: true })
  @Watch('programData', { deep: true })
  goToNow () {
    if (this.showPositionNow && this.timelineSheet) {
      this.timelineSheet.scrollTo({
        left: parseFloat(this.positionNow) - this.timelineSheet.offsetWidth / 2
      })
      this.scrollLeft = this.timelineSheet.scrollLeft
    }
  }

  setSwipeArrowState () {
    this.swipeArrowState.left.disabled = this.timelineSheet?.scrollLeft <= 0
    this.swipeArrowState.right.disabled = (this.timelineSheet?.scrollLeft + this.timelineSheet.offsetWidth) >= this.timelineSheet?.scrollWidth
  }

  getChannelImageUrl (site:SiteData) {
    return site?.parameters?.design.channelImageUrls
  }

  filterByDate (filter: ListFilter) {
    this.programListFilters.forEach(f => { f.selected = false })
    filter.selected = true
    this.programDataPayload = filter.range
  }

  setDateFilters () {
    this.programListFilters.push(...[
      { selected: false, title: 'Tegnap', range: this.getFilterRange(-1) },
      { selected: true, title: 'Ma', range: this.getFilterRange(0) },
      { selected: false, title: 'Holnap', range: this.getFilterRange(1) },
      ...Array.from({ length: 6 },
        (v, k) => {
          const day = k + 2
          return {
            selected: false,
            title: format(addDays(this.currentDay, day), 'MM.dd.'),
            range: this.getFilterRange(day)
          }
        })
    ])
  }

  get siteListPayload (): SiteListPayload {
    return {
      type: SiteTypes.Channel,
      order: [{ field: SiteListQueryOrderFieldEnum.Sequence, direction: SiteListQueryOrderDirectionEnum.Asc }]
    }
  }

  swipePage (direction: DIRECTION) {
    const left = this.timelineSheet.scrollLeft + (direction === DIRECTION.RIGHT ? 1 : -1) * this.timelineSheet.offsetWidth
    this.timelineSheet.scrollTo({
      left: left,
      behavior: 'smooth'
    })
  }

  get positionNow () {
    const d = new Date()
    const now = (d.getHours() * 60 + d.getMinutes()) * (this.timeWidth / 60)

    return (now - (((this.timeLineHours[0] || 0) * 60) * (this.timeWidth / 60))) + 'px'
  }

  getFilterRange (distanceFromCurrent: number) {
    return {
      startDate: addDays(this.currentDay, distanceFromCurrent),
      endDate: addDays(this.currentDay, distanceFromCurrent + 1)
    }
  }

  getCurrentDay () {
    const now = new Date()
    now.setHours(0, now.getTimezoneOffset() * -1, 0)
    return now
  }

  onGrabStart (e: MouseEvent) {
    this.grabbing = true
    this.startX = e.pageX - this.timelineSheet.offsetLeft
    this.scrollLeft = this.timelineSheet.scrollLeft
  }

  onGrabMove (e: MouseEvent) {
    if (!this.grabbing) return
    const x = e.pageX - this.timelineSheet.offsetLeft
    const walk = x - this.startX
    this.timelineSheet.scrollLeft = this.scrollLeft - walk
  }

  onGrabStop () {
    this.grabbing = false
  }

  itemLeft (program: TvProgramItem) {
    if (program?.progDate && this.programData?.timeRange.start) {
      const fromStart = this.programData?.timeRange.start
      const minutes = Math.abs(differenceInMinutes(fromStart, program.progDate))
      return minutes * (this.timeWidth / 60) + 'px'
    }
  }

  get timeLineHours () {
    const timeHours = []
    if (this.programData) {
      const { start, end } = this.programData.timeRange
      const distance = (start && end) ? Math.abs(differenceInHours(start, end)) : 0
      const firstHour = start?.getHours() || 0
      for (let i = 0; i < distance + 1; i++) {
        timeHours.push((firstHour + i) % 24)
      }
    }
    return timeHours
  }

  getTimeRange (channels: ChannelProgramData) {
    let dateStart: Date | undefined, dateEnd : Date | undefined
    channels?.forEach(pd => {
      pd.programs.forEach(p => {
        dateStart = dateStart ? ((p.progDate && (p.progDate < dateStart)) ? p.progDate : dateStart) : p.progDate
        dateEnd = dateEnd ? ((p.progDate && (p.progDate > dateEnd)) ? p.progDate : dateEnd) : p.progDate
      })
    })

    const range = {
      start: dateStart ? new Date(dateStart) : null,
      end: dateEnd ? new Date(dateEnd) : null
    }

    range?.start?.setMinutes(0)
    return range
  }

  transformProgramData (programData:TvProgramData): ProgramData {
    const channels = Object.entries(cloneDeep(programData)).map((e) => {
      const [key, val] = e
      const programs = val.progDays[0]?.programs
      this.castProgramsDate(programs)
      const sortedPrograms = this.sortPrograms(programs)
      this.setProgramListLength(sortedPrograms, val.progDays[0]?.progDayEnd)
      return { name: key, logoUrl: val.logoUrl, programs: sortedPrograms }
    })
    return { channels: channels, timeRange: this.getTimeRange(channels) }
  }

  calculateItemWidth (program: TvProgramItem) {
    return (program?.progLength ? Number(program?.progLength) * (this.timeWidth / 60) : 0) + 'px'
  }

  castProgramsDate (programs: Array<TvProgramItem>): void {
    programs.forEach((element) => { element.progDate = element.progDate ? new Date(element.progDate) : element.progDate })
  }

  setProgramListLength (sortedPrograms: Array<TvProgramItem>, end?: Date) {
    sortedPrograms.forEach((element, index, array) => {
      if (element.progDate) {
        const hasNext = array.length > index + 1
        if (hasNext || !element.progLength || element.progLength === '0') {
          const progDateNext = hasNext ? array[index + 1].progDate : end
          element.progLength = String(progDateNext ? Math.abs(differenceInMinutes(element.progDate, new Date(progDateNext))) : 0)
        }
      }
    })
  }

  sortPrograms (programs: Array<TvProgramItem>) {
    return programs.sort((a, b) => {
      if (a?.progDate && b?.progDate) {
        const t1 = a.progDate.getTime()
        const t2 = b.progDate.getTime()
        if (t1 < t2) {
          return -1
        }
        if (t1 > t2) {
          return 1
        }
      }
      return 0
    })
  }

  showInfo (programInfo: TvProgramItem) {
    this.programInfo = programInfo
  }
}
