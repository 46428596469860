import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "news-card-grid news-card-grid--4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tv_show_card_img = _resolveComponent("tv-show-card-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tvShows, (show, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "news-card-grid__item"
      }, [
        _createVNode(_component_tv_show_card_img, { "tv-show": show }, null, 8, ["tv-show"])
      ]))
    }), 128))
  ]))
}