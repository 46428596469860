
import { mixins, Options } from 'vue-property-decorator'

import { findElement, findElements, getInnerHtml, parseFromString, runWidgetScripts } from '../../utils'
import { FetchHtmlContentMixin } from '../mixins'
import WidgetMixin, { WidgetMeta } from './WidgetMixin'
import WidgetStyleMixin from './WidgetStyleMixin'

export const meta: WidgetMeta = {
  label: 'HTML content widget',
  groups: {
    style: 'Beállítás'
  },
  defaultGroup: 'style',
  fields: {
    htmlTemplate: {
      type: String,
      default: 'https://storage-live.rtl.hu/asset/bank360/index.html',
      props: {
        label: 'HTML template URL',
        placeholder: 'Html template URL'
      }
    }
  }
}

@Options({
  name: 'HtmlContentWidget'
})
export default class HtmlContentWidget extends mixins(WidgetMixin, WidgetStyleMixin, FetchHtmlContentMixin) {
  get nodes () {
    return (this.htmlContentData && this.htmlContentData.textResponse) ? parseFromString(this.htmlContentData.textResponse) : null
  }

  get content () {
    if (this.nodes) {
      const body = findElement(this.nodes, 'body')
      const head = findElement(this.nodes, 'head')

      const result: {template: string | null, styles: Array<string>} = {
        template: getInnerHtml(body),
        styles: (head ? findElements(head.childNodes, 'link') : []).filter((n) => (n as HTMLLinkElement).getAttribute('rel') === 'stylesheet').map(link => (link as HTMLLinkElement).href)
      }
      return result
    }
    return null
  }

  runScripts () {
    const el = this.$refs.content as HTMLDivElement | null
    if (el) {
      runWidgetScripts(el)
    }
  }

  serverPrefetch () {
    return this.fetchHtmlContent().then(() => {
      this.runScripts()
    })
  }

  mounted () {
    this.fetchHtmlContent().then(() => {
      this.runScripts()
    })
  }
}
