
import { Menus } from '@rtl/api'
import { mixins, Options } from 'vue-property-decorator'

import AppStore from '../components/icons/AppStore.vue'
import ArrowUp from '../components/icons/ArrowUp.vue'
import FacebookFull from '../components/icons/FacebookFull.vue'
import FooterLogo from '../components/icons/FooterLogo.vue'
import GooglePlay from '../components/icons/GooglePlay.vue'
import Instagram from '../components/icons/Instagram.vue'
import Twitter from '../components/icons/Twitter.vue'
import NavLink from '../components/NavLink.vue'
import SocialMediaLinks from '../components/SocialMediaLinks.vue'
import { GetPageMixin } from '../mixins'

@Options({
  name: 'MainFooter',
  components: {
    NavLink,
    SocialMediaLinks,
    FooterLogo,
    FacebookFull,
    Instagram,
    Twitter,
    ArrowUp,
    GooglePlay,
    AppStore
  }
})
export default class MainFooter extends mixins(GetPageMixin) {
  date = new Date().getFullYear()

  get menu (): Menus {
    return this.page?.menu || {}
  }

  get androidAppUrl () {
    return false
  }

  get iosAppUrl () {
    return false
  }

  openCookieBox () {
    if (typeof window.__tcfapi === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.__tcfapi('displayConsentUi', 2, function () {})
    }
  }

  goToTop () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const root = this.$root as any
    if (root?.scrollToTop) {
      root.scrollToTop()
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
