
import { Options, Prop, Vue } from 'vue-property-decorator'

import SliderLeft from './icons/SliderLeft.vue'
import SliderRight from './icons/SliderRight.vue'
import NavLink from './NavLink.vue'

interface PaginationItem {
  label: string
  page: number | null
  active: boolean
  disabled: boolean
  hidden: boolean
}

@Options({
  name: 'Pagination',
  components: {
    NavLink,
    SliderLeft,
    SliderRight
  }
})
export default class Pagination extends Vue {
  @Prop({ type: Number, default: 1 })
  readonly page!: number

  @Prop({ type: Number, default: 0 })
  readonly totalCount!: number

  @Prop({ type: Number, default: 50 })
  readonly limit!: number

  @Prop({ type: Number, default: 5 })
  readonly visiblePages!: number

  @Prop({ type: String, default: 'oldal' })
  readonly pageParamName!: string

  get currentPage (): number {
    return Math.max(1, Math.min(this.page, this.maxPage))
  }

  get maxPage (): number {
    return Math.ceil(this.totalCount / this.limit)
  }

  get prevPage (): number {
    return Math.max(this.currentPage - 1, 1)
  }

  get nextPage (): number {
    return Math.min(this.currentPage + 1, this.maxPage)
  }

  get prevItem (): PaginationItem {
    return {
      label: '',
      page: this.prevPage,
      active: false,
      disabled: this.currentPage === 1,
      hidden: false
    }
  }

  get nextItem (): PaginationItem {
    return {
      label: '',
      page: this.nextPage,
      active: false,
      disabled: this.currentPage === this.maxPage,
      hidden: false
    }
  }

  get dotsItem (): PaginationItem {
    return {
      label: '...',
      page: null,
      active: false,
      disabled: false,
      hidden: true
    }
  }

  get firstItem (): PaginationItem {
    return {
      label: '1',
      page: 1,
      active: this.currentPage === 1,
      disabled: false,
      hidden: true
    }
  }

  get lastItem (): PaginationItem {
    return {
      label: `${this.maxPage}`,
      page: this.maxPage,
      active: this.currentPage === this.maxPage,
      disabled: false,
      hidden: true
    }
  }

  get pageItems (): PaginationItem[] {
    const items: PaginationItem[] = []
    const halfOfPages = this.maxPage / 2
    const visiblePages = this.visiblePages < this.maxPage ? this.visiblePages - 2 : this.visiblePages
    let maxVisiblePage = 0
    let minVisiblePage = 0

    if (this.currentPage <= halfOfPages) {
      maxVisiblePage = Math.min(Math.max(visiblePages, this.currentPage + 1), this.maxPage)
      minVisiblePage = Math.max(1, maxVisiblePage - visiblePages + 1)
    } else {
      minVisiblePage = Math.max(1, Math.min(this.currentPage - 1, this.maxPage - visiblePages + 1))
      maxVisiblePage = Math.min(this.maxPage, minVisiblePage + visiblePages - 1)
    }

    for (let i = minVisiblePage; i <= maxVisiblePage; i++) {
      items.push({
        label: `${i}`,
        page: i,
        active: i === this.currentPage,
        disabled: false,
        hidden: false
      })
    }

    if (this.maxPage > this.visiblePages) {
      if (this.currentPage < halfOfPages) {
        items.push(this.dotsItem)
        items.push(this.lastItem)
      } else {
        items.unshift(this.dotsItem)
        items.unshift(this.firstItem)
      }
    }

    return items
  }

  getQueryParams (page: number) {
    return {
      ...this.$route.query,
      [this.pageParamName]: page.toString()
    }
  }
}
