
import { mixins, Options } from 'vue-property-decorator'

import { generateMetaInfo } from '../../utils'
import { GetPageMixin } from '../mixins'
import CastingFormWidget from '../widgets/CastingFormWidget.vue'

@Options({
  name: 'CastingFormPage',
  components: {
    CastingFormWidget
  },
  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})
export default class CastingFormPage extends mixins(GetPageMixin) {}
