
import { Model, Options, Prop, Vue } from 'vue-property-decorator'

import { FormCategory } from './../../../store'

@Options({
  name: 'ContactFormSelect'
})

export default class ContactFormSelect extends Vue {
  @Prop({
    type: Object as () => FormCategory,
    default: {}
  })
  readonly categoryData!: FormCategory

  @Prop({
    type: Boolean,
    default: false
  })
  readonly disabled?: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly hidden?: boolean

  @Model('modelValue', {
    type: String,
    default: null
  })
  readonly value!: string | null
}
