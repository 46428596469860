
import { Options, Vue } from 'vue-property-decorator'

@Options({
  name: 'FaqFooter'
})
export default class FaqFooter extends Vue {
  get year () : number {
    return new Date().getFullYear()
  }

  openCookieBox () {
    if (typeof window.__tcfapi === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.__tcfapi('displayConsentUi', 2, function () { })
    }
  }
}
