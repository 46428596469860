import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vote_header = _resolveComponent("vote-header")!
  const _component_transition_style = _resolveComponent("transition-style")!
  const _component_transition_page = _resolveComponent("transition-page")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_rtl_nav = _resolveComponent("rtl-nav")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_transition_style, {
        name: "slide-down",
        appear: ""
      }, {
        default: _withCtx(() => [
          (_ctx.vote)
            ? (_openBlock(), _createBlock(_component_vote_header, {
                key: 0,
                logo: _ctx.vote.style.logo,
                background: _ctx.vote.style.bg_image,
                color: _ctx.vote.style.bg_color
              }, null, 8, ["logo", "background", "color"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_component_transition_page, null, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }),
      _createVNode(_component_transition_style, {
        name: "slide-up",
        appear: ""
      }, {
        default: _withCtx(() => [
          (_ctx.$route.meta.rtlNav !== false)
            ? (_openBlock(), _createBlock(_component_rtl_nav, {
                key: 0,
                position: "fix-bottom"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}