
import { Options, Prop, Vue } from 'vue-property-decorator'

// import { FAQ_GETTERS, FAQ_NAMESPACE, FaqTopic, FaqSubTopic, FaqQA } from './../../../store'
import TextInput from './../auth/TextInput.vue'

@Options({
  name: 'FaqSearch',
  components: {
    TextInput
  }
})
export default class FaqSearch extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  readonly showTags!: boolean

  @Prop({
    type: Array<string>,
    default: []
  })
  readonly tags!: Array<string>

  searchValue = ''

  selectTag (tag: string) {
    this.searchValue = tag
  }
}
