
import { mixins, Options, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { TAG_ACTION, TAG_NAMESPACE, TagPayload, TagResult } from '../../store'
import { generateMetaInfo } from '../../utils'
import { GetPageMixin } from '../mixins'
import ContentPageWidget from '../widgets/ContentPageWidget.vue'

@Options({
  name: 'TagPage',
  components: {
    ContentPageWidget
  },
  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})
export default class TagPage extends mixins(GetPageMixin) {
  @Action(TAG_ACTION.ITEM, { namespace: TAG_NAMESPACE })
  storeFetchTag!: (payload: TagPayload) => Promise<TagResult>

  get tag (): TagResult['data'] | null {
    return this.$store.getters['$cache/result']([TAG_NAMESPACE, TAG_ACTION.ITEM], this.tagPayload)?.data || null
  }

  get tagSlug () {
    return this.$route.params.tag as string
  }

  get title (): string {
    const title = this.tag?.tag
    return title ? title.toUpperCase() : ''
  }

  get contentPayload () {
    const slug = this.tagSlug
    return slug ? { tags: [slug] } : null
  }

  get tagPayload (): TagPayload | null {
    const slug = this.tagSlug
    return slug ? { slug } : null
  }

  @Watch('tagPayload', { deep: true })
  async fetchTag () {
    return this.tagPayload ? this.storeFetchTag(this.tagPayload) : null
  }

  serverPrefetch () {
    return this.fetchTag()
  }

  mounted () {
    return this.fetchTag()
  }
}
