"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinimalContentDataToJSONTyped = exports.MinimalContentDataToJSON = exports.MinimalContentDataFromJSONTyped = exports.MinimalContentDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MinimalContentDataFromJSON(json) {
    return MinimalContentDataFromJSONTyped(json, false);
}
exports.MinimalContentDataFromJSON = MinimalContentDataFromJSON;
function MinimalContentDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'lead': !(0, runtime_1.exists)(json, 'lead') ? undefined : json['lead'],
        'body': !(0, runtime_1.exists)(json, 'body') ? undefined : json['body'],
        'releaseDate': !(0, runtime_1.exists)(json, 'releaseDate') ? undefined : (new Date(json['releaseDate'])),
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'outerUrl': !(0, runtime_1.exists)(json, 'outerUrl') ? undefined : json['outerUrl'],
        'ageRating': !(0, runtime_1.exists)(json, 'ageRating') ? undefined : json['ageRating'],
        'nsfwFlag': !(0, runtime_1.exists)(json, 'nsfwFlag') ? undefined : json['nsfwFlag'],
        'seoKeywords': !(0, runtime_1.exists)(json, 'seoKeywords') ? undefined : json['seoKeywords'],
        'useTagsAsSeoKeywords': !(0, runtime_1.exists)(json, 'useTagsAsSeoKeywords') ? undefined : json['useTagsAsSeoKeywords'],
        'leadImageUrls': !(0, runtime_1.exists)(json, 'leadImageUrls') ? undefined : (0, _1.LeadImageUrlsFromJSON)(json['leadImageUrls']),
        'site': !(0, runtime_1.exists)(json, 'site') ? undefined : (0, _1.MinimalSiteDataFromJSON)(json['site']),
        'params': !(0, runtime_1.exists)(json, 'params') ? undefined : (0, _1.MinimalContentParamsFromJSON)(json['params']),
        'contentType': !(0, runtime_1.exists)(json, 'contentType') ? undefined : (0, _1.ContentTypeFromJSON)(json['contentType']),
        'timeFrom': !(0, runtime_1.exists)(json, 'timeFrom') ? undefined : (new Date(json['timeFrom'])),
        'timeUntil': !(0, runtime_1.exists)(json, 'timeUntil') ? undefined : (new Date(json['timeUntil'])),
        'createdTs': !(0, runtime_1.exists)(json, 'createdTs') ? undefined : (new Date(json['createdTs'])),
        'modifiedTs': !(0, runtime_1.exists)(json, 'modifiedTs') ? undefined : (new Date(json['modifiedTs'])),
        'intId': !(0, runtime_1.exists)(json, 'intId') ? undefined : json['intId'],
        'totemImageUrl': !(0, runtime_1.exists)(json, 'totemImageUrl') ? undefined : json['totemImageUrl'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'alternativeLead': !(0, runtime_1.exists)(json, 'alternativeLead') ? undefined : json['alternativeLead'],
        'headingTag': !(0, runtime_1.exists)(json, 'headingTag') ? undefined : (0, _1.HeadingTagFromJSON)(json['headingTag']),
        'alternativeTitle': !(0, runtime_1.exists)(json, 'alternativeTitle') ? undefined : json['alternativeTitle'],
        'authors': !(0, runtime_1.exists)(json, 'authors') ? undefined : (json['authors'].map(_1.AuthorFromJSON)),
    };
}
exports.MinimalContentDataFromJSONTyped = MinimalContentDataFromJSONTyped;
function MinimalContentDataToJSON(value) {
    return MinimalContentDataToJSONTyped(value, false);
}
exports.MinimalContentDataToJSON = MinimalContentDataToJSON;
function MinimalContentDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'lead': value.lead,
        'body': value.body,
        'releaseDate': value.releaseDate === undefined ? undefined : (value.releaseDate.toISOString()),
        'url': value.url,
        'outerUrl': value.outerUrl,
        'ageRating': value.ageRating,
        'nsfwFlag': value.nsfwFlag,
        'seoKeywords': value.seoKeywords,
        'useTagsAsSeoKeywords': value.useTagsAsSeoKeywords,
        'leadImageUrls': (0, _1.LeadImageUrlsToJSON)(value.leadImageUrls),
        'site': (0, _1.MinimalSiteDataToJSON)(value.site),
        'params': (0, _1.MinimalContentParamsToJSON)(value.params),
        'contentType': (0, _1.ContentTypeToJSON)(value.contentType),
        'timeFrom': value.timeFrom === undefined ? undefined : (value.timeFrom.toISOString()),
        'timeUntil': value.timeUntil === undefined ? undefined : (value.timeUntil.toISOString()),
        'createdTs': value.createdTs === undefined ? undefined : (value.createdTs.toISOString()),
        'modifiedTs': value.modifiedTs === undefined ? undefined : (value.modifiedTs.toISOString()),
        'intId': value.intId,
        'totemImageUrl': value.totemImageUrl,
        'label': value.label,
        'alternativeLead': value.alternativeLead,
        'headingTag': (0, _1.HeadingTagToJSON)(value.headingTag),
        'alternativeTitle': value.alternativeTitle,
        'authors': value.authors === undefined ? undefined : (value.authors.map(_1.AuthorToJSON)),
    };
}
exports.MinimalContentDataToJSONTyped = MinimalContentDataToJSONTyped;
