import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Gallery = _resolveComponent("Gallery")!
  const _component_AdultConfirm = _resolveComponent("AdultConfirm")!

  return (_ctx.content)
    ? (_openBlock(), _createBlock(_component_AdultConfirm, {
        key: 0,
        "age-rating": _ctx.content.ageRating
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Gallery, { content: _ctx.content }, null, 8, ["content"])
        ]),
        _: 1
      }, 8, ["age-rating"]))
    : _createCommentVNode("", true)
}