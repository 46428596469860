
import { Options, Inject, Vue } from "vue-property-decorator";
import { RegisterPage as SignupPage } from "@rtl/ui";
import { Container, Page } from "@/views/components";

@Options({
  components: {
    Container,
    Page,
    SignupPage,
  },
})
export default class VoteSignupPage extends Vue {
  @Inject()
  platform!: string;
}
