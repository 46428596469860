
import { ContentData, MinimalContentData } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import BulkContentListCard from '../components/search/BulkContentListCard.vue'
import WidgetBlock from '../components/widget/WidgetBlock.vue'

@Options({
  name: 'BulkContentListWidget',
  components: {
    WidgetBlock,
    BulkContentListCard
  }
})
export default class BulkContentListWidget extends Vue {
  @Prop({ type: Object, default: [] })
  readonly contentDataList!: Array<ContentData|MinimalContentData>
}
