
import {
  Configuration,
  ContentData,
  ContentListResult,
  ListDefaultContentsContentTypesEnum,
  MinimalContentData,
  SearchContentTypesEnum,
  SearchData,
  SearchMode,
  SearchResult,
  SiteData,
  SiteListQueryOrderDirectionEnum,
  SiteListQueryOrderFieldEnum,
  SiteListResult,
  SiteTypes
} from '@rtl/api'
import { mixins, Options, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import {
  API_GETTER,
  CONTENT_ACTION, CONTENT_NAMESPACE, ContentListPayload, SEARCH_ACTION,
  SEARCH_NAMESPACE,
  SearchPayload, SITE_ACTION, SITE_NAMESPACE, SiteListPayload
} from '../../store'
import { generateMetaInfo } from '../../utils'
import CelebrityCardList from '../components/celebrity/CelebrityCardList.vue'
import ContentCardList from '../components/content/ContentCardList.vue'
import Search from '../components/icons/Search.vue'
import BulkContentListCard from '../components/search/BulkContentListCard.vue'
import SearchFilterComponent from '../components/search/SearchFilterComponent.vue'
import TvShowCardList from '../components/tv-show/TvShowCardList.vue'
import WidgetBlock from '../components/widget/WidgetBlock.vue'
import PageLayout from '../layouts/PageLayout.vue'
import { GetPageMixin } from '../mixins'
import BulkContentListWidget from '../widgets/BulkContentListWidget.vue'
import CelebrityListWidget from '../widgets/CelebrityListWidget.vue'
import ContentListWidget from '../widgets/ContentListWidget.vue'

type SearchResultMeta = {
  text: string | null,
  count: number
}

@Options({
  name: 'SearchPage',
  components: {
    TvShowCardList,
    PageLayout,
    WidgetBlock,
    ContentCardList,
    BulkContentListCard,
    CelebrityCardList,
    CelebrityListWidget,
    ContentListWidget,
    SearchFilterComponent,
    BulkContentListWidget,
    Search
  },
  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})
export default class SearchPage extends mixins(GetPageMixin) {
  @Action(SEARCH_ACTION.SEARCH, { namespace: SEARCH_NAMESPACE })
  searchAction!: (payload: SearchPayload) => Promise<SearchResult>

  @Action(CONTENT_ACTION.LIST, { namespace: CONTENT_NAMESPACE })
  storeFetchContentList!: (payload: ContentListPayload) => Promise<ContentListResult>

  @Action(SITE_ACTION.LIST, { namespace: SITE_NAMESPACE })
  storeFetchSiteList!: (payload: SiteListPayload) => Promise<SiteListResult>

  @Getter(API_GETTER.CONFIG)
  apiConfig!: Configuration

  @Getter(API_GETTER.PARAMS)
  apiParams!: Configuration

  @Watch('contentTypes', { deep: true })
  contentTypesFilterChange () {
    this.search()
  }

  searchText = ''
  initialSearch = false
  searchResultData: SearchData | null = null
  searchResultMeta: SearchResultMeta | null = null
  contentTypes?: Array<SearchContentTypesEnum> = [];
  contentDataList: Array<ContentData|MinimalContentData> = []
  limit = 5
  contentOffset = 0
  showLoaderAnim = false

  get siteListPayload (): SiteListPayload {
    return {
      type: SiteTypes.Show,
      order: [{ field: SiteListQueryOrderFieldEnum.Sequence, direction: SiteListQueryOrderDirectionEnum.Asc }]
    }
  }

  mounted () {
    this.autofocus()
    const searchParam = this.getSearchParam()
    if (searchParam) {
      this.searchText = searchParam
      this.initialSearch = true
      this.search()
    } else {
      this.storeFetchSiteList(this.siteListPayload)
      this.loadBulkContentList()
    }
  }

  get siteList (): SiteListResult['data'] | null {
    return this.$store.getters['$cache/result']([SITE_NAMESPACE, SITE_ACTION.LIST], this.siteListPayload)?.data || null
  }

  get show (): SiteData[] {
    return this.searchResultData ? this.sites : this.siteList?.items?.slice(0, 12) || []
  }

  get celeb () {
    return this.searchResultData?.celeb?.items || []
  }

  get sites () {
    return this.searchResultData?.sites?.items || []
  }

  autofocus () {
    if (this.$refs.searchInput) {
      (this.$refs.searchInput as HTMLInputElement).focus()
    }
  }

  getSearchParam () {
    const searchParam = this.$route.query.q
    if (searchParam) {
      return (Array.isArray(searchParam) ? searchParam[0] : searchParam) as string
    }
  }

  searchByInput () {
    this.$router.push({ query: { q: this.searchText } })
    this.search()
  }

  search () {
    this.contentOffset = 0
    this.searchWithOffset(this.contentOffset)
    this.showLoader(true)
  }

  loadMoreBulkContent () {
    this.contentOffset += this.limit
    if (this.searchResultMeta?.text) {
      this.searchWithOffset(this.contentOffset)
    } else {
      this.loadBulkContentList()
    }
  }

  loadBulkContentList () {
    this.storeFetchContentList({
      contentTypes: [
        ListDefaultContentsContentTypesEnum.News,
        ListDefaultContentsContentTypesEnum.Celeb,
        ListDefaultContentsContentTypesEnum.Quiz,
        ListDefaultContentsContentTypesEnum.Vote,
        ListDefaultContentsContentTypesEnum.Promo,
        ListDefaultContentsContentTypesEnum.Character
      ],
      limit: this.limit,
      offset: this.contentOffset
    }).then(r => {
      if (r.data.items) {
        this.contentDataList?.push(...r.data.items)
      }
    })
  }

  searchWithOffset (offset = 0) {
    if (this.searchText) {
      this.searchAction({
        searchText: this.searchText,
        contentTypes: this.contentTypes,
        searchMode: SearchMode.Global,
        offset: offset,
        limit: this.limit
      }).then(r => {
        if (offset) {
          this.contentDataList.push(...(r.data?.other?.items || []))
        } else {
          this.contentDataList = r.data?.other?.items || []
          this.searchResultData = r.data
          this.setSearchResultMeta(r.data)
          this.showLoader(false)
        }
      })
    }
  }

  setSearchResultMeta (searchResultData: SearchData) {
    let resultCount = 0
    if (searchResultData) {
      for (const property in searchResultData) {
        if (Object.hasOwnProperty.call(searchResultData, property)) {
          resultCount += searchResultData[property]?.totalCount
        }
      }

      this.searchResultMeta = {
        text: this.searchText,
        count: resultCount
      }
    }
  }

  showLoader (val?: boolean) {
    this.showLoaderAnim = val !== undefined ? val : !this.showLoaderAnim
  }
}
