
import { SearchContentTypesEnum } from '@rtl/api'
import { Model, Options, Vue } from 'vue-property-decorator'

type FilterType = {text:string, type:SearchContentTypesEnum, selected:boolean}

@Options({
  name: 'SearchFilterComponent',
  components: {
  }
})
export default class SearchFilterComponent extends Vue {
  isMobileSearchOpen = false

  @Model('modelValue', { type: Array }) filterModel!: Array<SearchContentTypesEnum>;

  filters:Array<FilterType> = [
    { text: 'Cikkek, rövid videók', type: SearchContentTypesEnum.News, selected: false },
    { text: 'Teljes adások', type: SearchContentTypesEnum.Longvideo, selected: false }
  ]

  selectFilter (filter:FilterType) {
    filter.selected = !filter.selected
    this.filterModel = this.filters.filter(f => f.selected).map(f => f.type)
  }

  unsetFilters () {
    this.filters.forEach(f => { f.selected = false })
    this.filterModel = []
  }

  filtered () {
    return this.filters.findIndex(f => f.selected) < 0
  }
}
