"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TvProgramProgDaysToJSONTyped = exports.TvProgramProgDaysToJSON = exports.TvProgramProgDaysFromJSONTyped = exports.TvProgramProgDaysFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TvProgramProgDaysFromJSON(json) {
    return TvProgramProgDaysFromJSONTyped(json, false);
}
exports.TvProgramProgDaysFromJSON = TvProgramProgDaysFromJSON;
function TvProgramProgDaysFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'progDay': !(0, runtime_1.exists)(json, 'progDay') ? undefined : (new Date(json['progDay'])),
        'programs': !(0, runtime_1.exists)(json, 'programs') ? undefined : (json['programs'].map(_1.TvProgramItemFromJSON)),
        'progDayEnd': !(0, runtime_1.exists)(json, 'progDayEnd') ? undefined : (new Date(json['progDayEnd'])),
    };
}
exports.TvProgramProgDaysFromJSONTyped = TvProgramProgDaysFromJSONTyped;
function TvProgramProgDaysToJSON(value) {
    return TvProgramProgDaysToJSONTyped(value, false);
}
exports.TvProgramProgDaysToJSON = TvProgramProgDaysToJSON;
function TvProgramProgDaysToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'progDay': value.progDay === undefined ? undefined : (value.progDay.toISOString().substr(0, 10)),
        'programs': value.programs === undefined ? undefined : (value.programs.map(_1.TvProgramItemToJSON)),
        'progDayEnd': value.progDayEnd === undefined ? undefined : (value.progDayEnd.toISOString()),
    };
}
exports.TvProgramProgDaysToJSONTyped = TvProgramProgDaysToJSONTyped;
