
import { ListDefaultContentsContentTypesEnum } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import { ContentListDecoratedPayload, ContentListPayload } from '../../store'
import ContentCardGrid from '../components/content/ContentCardGrid.vue'
import { ContentCardParamsMixin, FetchContentListMixin, GetPageMixin } from '../mixins'
import WidgetMixin, { WidgetMeta, widgetMetaFields } from './WidgetMixin'
import WidgetStyleMixin, { widgetStyleFields } from './WidgetStyleMixin'

export const meta: WidgetMeta = {
  label: 'Content mozaik',
  groups: {
    style: 'Beállítás',
    data: 'Adatok'
  },
  defaultGroup: 'style',
  fields: {
    ...widgetMetaFields,
    ...widgetStyleFields,
    length: {
      type: Number,
      default: 3,
      props: {
        placeholder: 'Darabszám',
        min: 1,
        max: 6
      }
    },
    showTitle: {
      type: Boolean,
      default: true,
      props: {
        label: 'Cím'
      }
    },
    showLead: {
      type: Boolean,
      default: true,
      props: {
        label: 'Leírás'
      }
    },
    showTag: {
      type: Boolean,
      default: true,
      props: {
        label: 'Címke'
      }
    },
    showDuration: {
      type: Boolean,
      default: true,
      props: {
        label: 'Video jel'
      }
    },
    showGalleryBadge: {
      type: Boolean,
      default: true,
      props: {
        label: 'Galéria jel'
      }
    },
    showReleaseDate: {
      type: Boolean,
      default: false,
      props: {
        label: 'Dátum'
      }
    },
    payload: {
      group: 'data',
      type: 'payload',
      default: {
        contentTypes: [ListDefaultContentsContentTypesEnum.News]
      }
    }
  }
}

@Options({
  name: 'ContentGridWidget',
  components: {
    ContentCardGrid
  }
})
export default class ContentGridWidget extends mixins(GetPageMixin, WidgetMixin, FetchContentListMixin, ContentCardParamsMixin, WidgetStyleMixin) {
  @Prop({
    type: Number,
    default: 3
  })
  readonly length!: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly compact!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly compactInverse!: boolean

  @Prop({
    type: Object,
    default: () => ({})
  })
  readonly payload!: Partial<ContentListDecoratedPayload>

  get contentListPayload (): ContentListPayload {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { decorators, ...payload } = this.payload

    return {
      folderIds: !payload?.tags && this.page?.site?.folderId ? [this.page?.site?.folderId] : undefined,
      contentTypes: [ListDefaultContentsContentTypesEnum.News],
      limit: this.length,
      ...payload
    }
  }
}
