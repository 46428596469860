"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageUrlsToJSONTyped = exports.ImageUrlsToJSON = exports.ImageUrlsFromJSONTyped = exports.ImageUrlsFromJSON = void 0;
const runtime_1 = require("../runtime");
function ImageUrlsFromJSON(json) {
    return ImageUrlsFromJSONTyped(json, false);
}
exports.ImageUrlsFromJSON = ImageUrlsFromJSON;
function ImageUrlsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'imageUrl': !(0, runtime_1.exists)(json, 'imageUrl') ? undefined : json['imageUrl'],
        'imageUrl169': !(0, runtime_1.exists)(json, 'imageUrl_16-9') ? undefined : json['imageUrl_16-9'],
        'imageUrl166': !(0, runtime_1.exists)(json, 'imageUrl_16-6') ? undefined : json['imageUrl_16-6'],
        'imageUrl72': !(0, runtime_1.exists)(json, 'imageUrl_7-2') ? undefined : json['imageUrl_7-2'],
        'imageUrl34': !(0, runtime_1.exists)(json, 'imageUrl_3-4') ? undefined : json['imageUrl_3-4'],
        'imageUrl43': !(0, runtime_1.exists)(json, 'imageUrl_4-3') ? undefined : json['imageUrl_4-3'],
        'imageUrl11': !(0, runtime_1.exists)(json, 'imageUrl_1-1') ? undefined : json['imageUrl_1-1'],
    };
}
exports.ImageUrlsFromJSONTyped = ImageUrlsFromJSONTyped;
function ImageUrlsToJSON(value) {
    return ImageUrlsToJSONTyped(value, false);
}
exports.ImageUrlsToJSON = ImageUrlsToJSON;
function ImageUrlsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'imageUrl': value.imageUrl,
        'imageUrl_16-9': value.imageUrl169,
        'imageUrl_16-6': value.imageUrl166,
        'imageUrl_7-2': value.imageUrl72,
        'imageUrl_3-4': value.imageUrl34,
        'imageUrl_4-3': value.imageUrl43,
        'imageUrl_1-1': value.imageUrl11,
    };
}
exports.ImageUrlsToJSONTyped = ImageUrlsToJSONTyped;
