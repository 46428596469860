"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachedGalleryDataToJSONTyped = exports.AttachedGalleryDataToJSON = exports.AttachedGalleryDataFromJSONTyped = exports.AttachedGalleryDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AttachedGalleryDataFromJSON(json) {
    return AttachedGalleryDataFromJSONTyped(json, false);
}
exports.AttachedGalleryDataFromJSON = AttachedGalleryDataFromJSON;
function AttachedGalleryDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'attachedGalleryImageUrls': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrls') ? undefined : (0, _1.AttachedGalleryImageUrlsFromJSON)(json['attachedGalleryImageUrls']),
        'attachedGalleryUrl': !(0, runtime_1.exists)(json, 'attachedGalleryUrl') ? undefined : json['attachedGalleryUrl'],
    };
}
exports.AttachedGalleryDataFromJSONTyped = AttachedGalleryDataFromJSONTyped;
function AttachedGalleryDataToJSON(value) {
    return AttachedGalleryDataToJSONTyped(value, false);
}
exports.AttachedGalleryDataToJSON = AttachedGalleryDataToJSON;
function AttachedGalleryDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'attachedGalleryImageUrls': (0, _1.AttachedGalleryImageUrlsToJSON)(value.attachedGalleryImageUrls),
        'attachedGalleryUrl': value.attachedGalleryUrl,
    };
}
exports.AttachedGalleryDataToJSONTyped = AttachedGalleryDataToJSONTyped;
