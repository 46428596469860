"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteListQueryOrderToJSONTyped = exports.SiteListQueryOrderToJSON = exports.SiteListQueryOrderFromJSONTyped = exports.SiteListQueryOrderFromJSON = exports.SiteListQueryOrderDirectionEnum = exports.SiteListQueryOrderFieldEnum = void 0;
/**
* @export
* @enum {string}
*/
var SiteListQueryOrderFieldEnum;
(function (SiteListQueryOrderFieldEnum) {
    SiteListQueryOrderFieldEnum["Name"] = "name";
    SiteListQueryOrderFieldEnum["Views"] = "views";
    SiteListQueryOrderFieldEnum["Topn"] = "topn";
    SiteListQueryOrderFieldEnum["Sequence"] = "sequence";
    SiteListQueryOrderFieldEnum["Manual"] = "manual";
})(SiteListQueryOrderFieldEnum = exports.SiteListQueryOrderFieldEnum || (exports.SiteListQueryOrderFieldEnum = {})); /**
* @export
* @enum {string}
*/
var SiteListQueryOrderDirectionEnum;
(function (SiteListQueryOrderDirectionEnum) {
    SiteListQueryOrderDirectionEnum["Asc"] = "asc";
    SiteListQueryOrderDirectionEnum["Desc"] = "desc";
    SiteListQueryOrderDirectionEnum["Random"] = "random";
})(SiteListQueryOrderDirectionEnum = exports.SiteListQueryOrderDirectionEnum || (exports.SiteListQueryOrderDirectionEnum = {}));
function SiteListQueryOrderFromJSON(json) {
    return SiteListQueryOrderFromJSONTyped(json, false);
}
exports.SiteListQueryOrderFromJSON = SiteListQueryOrderFromJSON;
function SiteListQueryOrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'field': json['field'],
        'direction': json['direction'],
    };
}
exports.SiteListQueryOrderFromJSONTyped = SiteListQueryOrderFromJSONTyped;
function SiteListQueryOrderToJSON(value) {
    return SiteListQueryOrderToJSONTyped(value, false);
}
exports.SiteListQueryOrderToJSON = SiteListQueryOrderToJSON;
function SiteListQueryOrderToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'field': value.field,
        'direction': value.direction,
    };
}
exports.SiteListQueryOrderToJSONTyped = SiteListQueryOrderToJSONTyped;
