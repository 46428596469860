
import { Options, Provide, mixins } from "vue-property-decorator";
import { platform, Platform } from "@rtl/ui";
import { AppOAuthMixin } from "@/mixins";

@Options({
  metaInfo() {
    return {
      title: "RTL.hu Szavazás",
      description:
        "Itt tudsz részt venni az RTL élő műsoraihoz kapcsolódó szavazásokon.",
    };
  },
})
export default class App extends mixins(AppOAuthMixin) {
  @Provide()
  get platform() {
    return platform();
  }

  @Provide()
  get rtlUrl() {
    const appHostname = "app.rtl.hu";

    switch (this.platform) {
      case Platform.IOS:
        return `capacitor://${appHostname}`;
      case Platform.ANDROID:
        return `http://${appHostname}`;
      default:
        return "https://rtl.hu";
    }
  }

  @Provide()
  get contentUrlPrefix() {
    return this.rtlUrl;
  }
}
