
import { ListRelatedContentsContentTypesEnum } from '@rtl/api'
import { mixins, Options, Prop, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { CONTENT_ACTION, CONTENT_NAMESPACE, ContentListResult, ContentRelatedListPayload } from '../../store'
import ContentCardList from '../components/content/ContentCardList.vue'
import { GetPageMixin } from '../mixins'
import WidgetMixin from './WidgetMixin'

@Options({
  name: 'RelatedContentWidget',
  components: {
    ContentCardList
  }
})
export default class RelatedContentWidget extends mixins(GetPageMixin, WidgetMixin) {
  @Prop({ type: Number, default: 8 })
  readonly length!: number

  @Prop({ type: Number, default: 4 })
  readonly cols!: number

  @Prop({ type: Boolean })
  readonly showLead!: boolean

  @Prop({ type: Boolean })
  readonly showBorder!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showSlider!: boolean

  @Action(CONTENT_ACTION.RELATED, { namespace: CONTENT_NAMESPACE })
  storeFetchContentRelatedList!: (payload: ContentRelatedListPayload) => Promise<ContentListResult>

  get contentList (): ContentListResult['data'] | null {
    if (this.contentRelatedListPayload) {
      return this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.RELATED], this.contentRelatedListPayload)?.data || null
    }
    return null
  }

  get contentRelatedListPayload (): ContentRelatedListPayload | null {
    const contentId = this.page?.contentId
    return contentId
      ? {
          contentTypes: [ListRelatedContentsContentTypesEnum.News],
          contentId,
          limit: this.length
        }
      : null
  }

  @Watch('contentRelatedListPayload', { deep: true })
  async fetchContentRelatedList () {
    if (this.contentRelatedListPayload) {
      return this.storeFetchContentRelatedList(this.contentRelatedListPayload)
    }
  }

  serverPrefetch () {
    return this.fetchContentRelatedList()
  }

  mounted () {
    return this.fetchContentRelatedList()
  }
}
