
import { Menus } from '@rtl/api'
import Headroom from 'headroom.js'
import { mixins, Options, Ref, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { Response } from '../../gigya'
import { ROUTES } from '../../router'
import { USER_ACTION, USER_NAMESPACE } from '../../store'
import DarkMode from '../components/icons/DarkMode.vue'
import FacebookFull from '../components/icons/FacebookFull.vue'
import Home from '../components/icons/Home.vue'
import Instagram from '../components/icons/Instagram.vue'
import MainLogo from '../components/icons/MainLogo.vue'
import Programs from '../components/icons/Programs.vue'
import RtlNow from '../components/icons/RtlNow.vue'
import Search from '../components/icons/Search.vue'
import TagX from '../components/icons/TagX.vue'
import Twitter from '../components/icons/Twitter.vue'
import User from '../components/icons/User.vue'
import Weather from '../components/icons/Weather.vue'
import NavLink from '../components/NavLink.vue'
import SocialMediaLinks from '../components/SocialMediaLinks.vue'
import VoteBanner from '../components/VoteBanner.vue'
import { GetPageMixin, GetUserMixin } from '../mixins'

@Options({
  name: 'MainHeader',
  components: {
    MainLogo,
    NavLink,
    SocialMediaLinks,
    Weather,
    Home,
    Programs,
    DarkMode,
    Search,
    RtlNow,
    FacebookFull,
    Instagram,
    Twitter,
    User,
    TagX,
    VoteBanner
  }
})
export default class MainHeader extends mixins(GetPageMixin, GetUserMixin) {
  @Action(USER_ACTION.LOGOUT, { namespace: USER_NAMESPACE })
  logoutUserAction!: () => Promise<Response>

  private isNavOpen = false
  private scrollPosition = 0
  private lastScrollPos = 0
  private searchText = null

  isElectionMenu (menu: string) {
    return menu === '/cimke/valasztas2022' || menu === '/cimke/valasztas-2022'
  }

  @Ref('header')
  readonly header!: HTMLElement

  private headerOptions = {
    offset: 100,
    tolerance: {
      up: 55,
      down: 0
    }
  }

  logout () {
    this.logoutUserAction().then(() => {
      this.$router.push({ name: 'login' })
    })
  }

  mounted () {
    const headroom = new Headroom(this.header, this.headerOptions)
    headroom.init()
  }

  get menus (): Menus {
    return this.page?.menu || {}
  }

  toggleMenu (val?: boolean) {
    this.isNavOpen = val !== undefined ? val : !this.isNavOpen
  }

  toggleMobileMenu (val?: boolean) {
    this.isNavOpen = val !== undefined ? val : !this.isNavOpen
    this.scrollPosition = window.pageYOffset
    const html = document.querySelector('html')
    const htmlElement = html as HTMLHtmlElement
    const body = document.querySelector('body')
    const bodyElement = body as HTMLBodyElement

    if (this.isNavOpen) {
      bodyElement.classList.add('menu-open')
      this.lastScrollPos = this.scrollPosition
      htmlElement.style.overflow = 'hidden'
      htmlElement.style.position = 'fixed'
      htmlElement.style.top = `-${this.scrollPosition}px`
      htmlElement.style.width = '100%'
    } else {
      bodyElement.classList.remove('menu-open')
      htmlElement.style.removeProperty('overflow')
      htmlElement.style.removeProperty('position')
      htmlElement.style.removeProperty('top')
      htmlElement.style.removeProperty('width')
      window.scrollTo(0, this.lastScrollPos)
    }
  }

  @Watch('$route.fullPath')
  onRouteChange () {
    this.toggleMobileMenu(false)
    this.toggleMenu(false)
    window.scrollTo(0, 0)
  }

  get switchActive (): boolean {
    const routeName = this.$route?.name
    return routeName !== ROUTES.INDEX && routeName !== ROUTES.SHOW_INDEX
  }

  search () {
    if (this.searchText) {
      this.$router.push({ name: ROUTES.SEARCH, query: { q: this.searchText } })
    }
  }
}
