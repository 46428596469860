
import { mixins, Options } from 'vue-property-decorator'

import { generateMetaInfo } from '../../utils'
import CelebrityHero from '../components/celebrity/CelebrityHero.vue'
import Tag from '../components/Tag.vue'
import PageLayout from '../layouts/PageLayout.vue'
import { FetchContentItemMixin } from '../mixins'
import ContentGridWidget from '../widgets/ContentGridWidget.vue'
import ContentListWidget from '../widgets/ContentListWidget.vue'
import LongVideoListWidget from '../widgets/LongVideoListWidget.vue'

@Options({
  name: 'CelebrityPage',
  components: {
    PageLayout,
    CelebrityHero,
    ContentGridWidget,
    ContentListWidget,
    LongVideoListWidget,
    Tag
  },
  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})
export default class CelebrityPage extends mixins(FetchContentItemMixin) {}
