import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "vote-banner__container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "vote-banner__text" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.link,
    class: "vote-banner"
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.logo)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "vote-banner__logo",
            src: _ctx.logo
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", {
      class: "vote-banner__bg-wrapper",
      style: _normalizeStyle({ backgroundColor: _ctx.color })
    }, [
      (_ctx.background)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "vote-banner__background",
            src: _ctx.background
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true)
    ], 4)
  ], 8, _hoisted_1))
}