
import { mixins, Options } from 'vue-property-decorator'

import TvShowCardMixin from '../../mixins/TvShowCardMixin'
import Image from '../Image.vue'
import NavLink from '../NavLink.vue'

@Options({
  name: 'TvShowCardImg',
  components: {
    Image,
    NavLink
  }
})
export default class TvShowCardImg extends mixins(TvShowCardMixin) {}
