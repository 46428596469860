"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CastingFormDataToJSONTyped = exports.CastingFormDataToJSON = exports.CastingFormDataFromJSONTyped = exports.CastingFormDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CastingFormDataFromJSON(json) {
    return CastingFormDataFromJSONTyped(json, false);
}
exports.CastingFormDataFromJSON = CastingFormDataFromJSON;
function CastingFormDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'formCode': !(0, runtime_1.exists)(json, 'formCode') ? undefined : json['formCode'],
        'season': !(0, runtime_1.exists)(json, 'season') ? undefined : json['season'],
        'fields': !(0, runtime_1.exists)(json, 'fields') ? undefined : (json['fields'].map(_1.CastingFormFieldFromJSON)),
        'privacyStatementUrl': !(0, runtime_1.exists)(json, 'privacyStatementUrl') ? undefined : json['privacyStatementUrl'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'lead': !(0, runtime_1.exists)(json, 'lead') ? undefined : json['lead'],
        'imageUrls': !(0, runtime_1.exists)(json, 'imageUrls') ? undefined : (0, _1.ImageUrlsFromJSON)(json['imageUrls']),
        'group': !(0, runtime_1.exists)(json, 'group') ? undefined : json['group'],
        'appTitle': !(0, runtime_1.exists)(json, 'appTitle') ? undefined : json['appTitle'],
    };
}
exports.CastingFormDataFromJSONTyped = CastingFormDataFromJSONTyped;
function CastingFormDataToJSON(value) {
    return CastingFormDataToJSONTyped(value, false);
}
exports.CastingFormDataToJSON = CastingFormDataToJSON;
function CastingFormDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'formCode': value.formCode,
        'season': value.season,
        'fields': value.fields === undefined ? undefined : (value.fields.map(_1.CastingFormFieldToJSON)),
        'privacyStatementUrl': value.privacyStatementUrl,
        'title': value.title,
        'lead': value.lead,
        'imageUrls': (0, _1.ImageUrlsToJSON)(value.imageUrls),
        'group': value.group,
        'appTitle': value.appTitle,
    };
}
exports.CastingFormDataToJSONTyped = CastingFormDataToJSONTyped;
