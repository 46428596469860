
import { CelebTabs, ContentData, MinimalContentData } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import ContentCardImg from '../content/ContentCardImg.vue'
import ContentCardList from '../content/ContentCardList.vue'
import Facebook from '../icons/Facebook.vue'
import Instagram from '../icons/Instagram.vue'
import Twitter from '../icons/Twitter.vue'
import Youtube from '../icons/Youtube.vue'
import Image from '../Image.vue'
import NavLink from '../NavLink.vue'
import VideoPlayer from '../VideoPlayer.vue'

@Options({
  name: 'CelebrityHero',
  components: {
    ContentCardImg,
    ContentCardList,
    VideoPlayer,
    Image,
    NavLink,
    Facebook,
    Youtube,
    Instagram,
    Twitter
  }
})
export default class CelebrityHero extends Vue {
  @Prop({ type: Object, required: true })
  readonly celebrity!: ContentData

  get isFirstContentEmbed (): boolean {
    return this.celebrity.params?.embeddableFeaturedContent || false
  }

  get firstFeaturedContent (): ContentData | MinimalContentData | null {
    return this.celebrity.params?.featuredContents?.slice(0, 1)[0] || null
  }

  get featuredContents (): ContentData[] | MinimalContentData[] {
    return this.celebrity.params?.featuredContents?.slice(1) || []
  }

  get tabs (): CelebTabs[] {
    return this.celebrity.params?.tabs || []
  }

  get facebookUrl (): string | undefined {
    return this.celebrity.params?.additionalJson?.celebFacebook
  }

  get youtubeUrl (): string | undefined {
    return this.celebrity.params?.additionalJson?.celebYoutube
  }

  get instagramUrl (): string | undefined {
    return this.celebrity.params?.additionalJson?.celebInstagram
  }

  get twitterUrl (): string | undefined {
    return this.celebrity.params?.additionalJson?.celebTwitter
  }

  getTabContent (tab: CelebTabs): string | undefined {
    if (tab.characters && tab.characters.length) {
      let result = '<ul class="celebrity-hero__character-list">'

      for (const character of tab.characters) {
        result += `<li><a href="${character.url}"><strong>${character.name}</strong> | ${character.siteName}</a></li>`
      }

      return result + '</ul>'
    }

    return tab.tabContent
  }

  switchTab (e: Event, tabId: number) {
    const selectedLink = e.target as HTMLElement
    const currentActiveLink = document.querySelector('.celebrity-hero__nav-link--active')
    const currentActiveContent = document.querySelector('.celebrity-hero__nav-content--active')
    const selectedContent = document.querySelector(`.celebrity-hero__nav-content[data-nav-content-id="${tabId}"]`)

    currentActiveLink?.classList.remove('celebrity-hero__nav-link--active')
    currentActiveContent?.classList.remove('celebrity-hero__nav-content--active')

    selectedLink?.classList.add('celebrity-hero__nav-link--active')
    selectedContent?.classList.add('celebrity-hero__nav-content--active')
  }
}
