import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_page_widget = _resolveComponent("content-page-widget")!

  return (_openBlock(), _createBlock(_component_content_page_widget, {
    title: _ctx.title,
    payload: _ctx.contentPayload,
    "show-highlight": true,
    "show-filters": false
  }, null, 8, ["title", "payload"]))
}