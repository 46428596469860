import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "contact-input" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "char-limit" }
const _hoisted_5 = { class: "char-limit__message" }
const _hoisted_6 = {
  key: 0,
  class: "contact-input__error"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "char-limit" }
const _hoisted_9 = { class: "char-limit__message" }
const _hoisted_10 = {
  key: 0,
  class: "contact-input__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "contact-input__label",
      for: _ctx.attrs.id
    }, _toDisplayString(_ctx.title), 9, _hoisted_2),
    (_ctx.type === 'input')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", _mergeProps({ minLength: "1" }, _ctx.attrs, {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            class: ["contact-input__input", { 'contact-input__input--error': _ctx.errorMsg && _ctx.attrs.required }]
          }), null, 16), [
            [_vModelDynamic, _ctx.value]
          ]),
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("span", _hoisted_5, " A karakterlimitet elérted. ", 512), [
              [_vShow, _ctx.currentCount >= _ctx.limit]
            ]),
            (_ctx.errorMsg && _ctx.attrs.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.errorMsg), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _withDirectives(_createElementVNode("textarea", _mergeProps({
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event))
          }, _ctx.attrs, {
            minLength: "1",
            class: ["contact-input__input contact-input__textarea", { 'contact-input__input--error': _ctx.errorMsg }]
          }), null, 16), [
            [_vModelText, _ctx.value]
          ]),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("span", _hoisted_9, " A karakterlimitet elérted. ", 512), [
              [_vShow, _ctx.currentCount >= _ctx.limit]
            ]),
            (_ctx.errorMsg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.errorMsg), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
  ]))
}