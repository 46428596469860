"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeasurementApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class MeasurementApi extends runtime.BaseAPI {
    /**
     * Post content measurement data
     */
    async sendContentMeasurementDataRaw(requestParameters, initOverrides) {
        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId', 'Required parameter requestParameters.contentId was null or undefined when calling sendContentMeasurementData.');
        }
        if (requestParameters.eventName === null || requestParameters.eventName === undefined) {
            throw new runtime.RequiredError('eventName', 'Required parameter requestParameters.eventName was null or undefined when calling sendContentMeasurementData.');
        }
        if (requestParameters.value === null || requestParameters.value === undefined) {
            throw new runtime.RequiredError('value', 'Required parameter requestParameters.value was null or undefined when calling sendContentMeasurementData.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.eventName !== undefined) {
            formParams.append('eventName', requestParameters.eventName);
        }
        if (requestParameters.value !== undefined) {
            formParams.append('value', requestParameters.value);
        }
        const response = await this.request({
            path: `/measurement/{contentId}`.replace(`{${"contentId"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.MeasurementResultFromJSON)(jsonValue));
    }
    /**
     * Post content measurement data
     */
    async sendContentMeasurementData(requestParameters, initOverrides) {
        const response = await this.sendContentMeasurementDataRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Post page measurement data
     */
    async sendMeasurementDataRaw(requestParameters, initOverrides) {
        if (requestParameters.eventName === null || requestParameters.eventName === undefined) {
            throw new runtime.RequiredError('eventName', 'Required parameter requestParameters.eventName was null or undefined when calling sendMeasurementData.');
        }
        if (requestParameters.value === null || requestParameters.value === undefined) {
            throw new runtime.RequiredError('value', 'Required parameter requestParameters.value was null or undefined when calling sendMeasurementData.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.eventName !== undefined) {
            formParams.append('eventName', requestParameters.eventName);
        }
        if (requestParameters.value !== undefined) {
            formParams.append('value', requestParameters.value);
        }
        const response = await this.request({
            path: `/measurement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.MeasurementResultFromJSON)(jsonValue));
    }
    /**
     * Post page measurement data
     */
    async sendMeasurementData(requestParameters, initOverrides) {
        const response = await this.sendMeasurementDataRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.MeasurementApi = MeasurementApi;
