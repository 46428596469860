
import { SiteData, SiteListQueryOrderDirectionEnum, SiteListQueryOrderFieldEnum, SiteTypes } from '@rtl/api'
import { mixins, Options } from 'vue-property-decorator'

import { SiteListPayload } from '../../store'
import { generateMetaInfo } from '../../utils'
import TvShowCardGrid from '../components/tv-show/TvShowCardGrid.vue'
import TvShowCardList from '../components/tv-show/TvShowCardList.vue'
import PageLayout from '../layouts/PageLayout.vue'
import { FetchSiteListMixin, GetPageMixin } from '../mixins'

@Options({
  name: 'TvShowListPage',
  components: {
    PageLayout,
    TvShowCardGrid,
    TvShowCardList
  },
  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})
export default class TvShowListPage extends mixins(GetPageMixin, FetchSiteListMixin) {
  numHighlighted = 4

  get siteListPayload (): SiteListPayload {
    return {
      type: SiteTypes.Show,
      order: [{ field: SiteListQueryOrderFieldEnum.Sequence, direction: SiteListQueryOrderDirectionEnum.Asc }],
      limit: 100
    }
  }

  get topShows (): SiteData[] {
    return this.siteList?.items?.slice(0, this.numHighlighted) || []
  }

  get allShows (): SiteData[] {
    return this.siteList?.items?.slice(this.numHighlighted) || []
  }
}
