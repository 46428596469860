import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "faq" }
const _hoisted_2 = {
  key: 0,
  class: "faq__card-cont"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faq_nav = _resolveComponent("faq-nav")!
  const _component_faq_serach = _resolveComponent("faq-serach")!
  const _component_faq_card = _resolveComponent("faq-card")!
  const _component_faq_contact = _resolveComponent("faq-contact")!
  const _component_faq_footer = _resolveComponent("faq-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_faq_nav),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_faq_serach, {
        showTags: true,
        tags: ['RTL Most+ szolgáltatás aktiválása', 'Fiók kezelése', 'Tévéműsorok és filmek']
      }),
      (_ctx.faqData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqData, (topic, i) => {
              return (_openBlock(), _createBlock(_component_faq_card, {
                key: i,
                limit: 3,
                topic: topic
              }, null, 8, ["topic"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_faq_contact)
    ]),
    _createVNode(_component_faq_footer)
  ], 64))
}