import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tv_show_card_grid = _resolveComponent("tv-show-card-grid")!
  const _component_tv_show_card_list = _resolveComponent("tv-show-card-list")!
  const _component_page_layout = _resolveComponent("page-layout")!

  return (_openBlock(), _createBlock(_component_page_layout, {
    class: "tv-show-list-page",
    title: "Összes műsor"
  }, {
    default: _withCtx(() => [
      (_ctx.topShows.length)
        ? (_openBlock(), _createBlock(_component_tv_show_card_grid, {
            key: 0,
            "tv-shows": _ctx.topShows
          }, null, 8, ["tv-shows"]))
        : _createCommentVNode("", true),
      (_ctx.allShows.length)
        ? (_openBlock(), _createBlock(_component_tv_show_card_list, {
            key: 1,
            "tv-shows": _ctx.allShows
          }, null, 8, ["tv-shows"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}