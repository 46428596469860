"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AdminApi extends runtime.BaseAPI {
    /**
     * Search for contents
     */
    async adminSearchContentsRaw(requestParameters, initOverrides) {
        if (requestParameters.searchText === null || requestParameters.searchText === undefined) {
            throw new runtime.RequiredError('searchText', 'Required parameter requestParameters.searchText was null or undefined when calling adminSearchContents.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/search/content/{searchText}`.replace(`{${"searchText"}}`, encodeURIComponent(String(requestParameters.searchText))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AdminContentListResultFromJSON)(jsonValue));
    }
    /**
     * Search for contents
     */
    async adminSearchContents(requestParameters, initOverrides) {
        const response = await this.adminSearchContentsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Search for tags
     */
    async adminSearchTagsRaw(requestParameters, initOverrides) {
        if (requestParameters.searchText === null || requestParameters.searchText === undefined) {
            throw new runtime.RequiredError('searchText', 'Required parameter requestParameters.searchText was null or undefined when calling adminSearchTags.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/search/tag/{searchText}`.replace(`{${"searchText"}}`, encodeURIComponent(String(requestParameters.searchText))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AdminTagListResultFromJSON)(jsonValue));
    }
    /**
     * Search for tags
     */
    async adminSearchTags(requestParameters, initOverrides) {
        const response = await this.adminSearchTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Generate new token
     */
    async generateTokenRaw(requestParameters, initOverrides) {
        if (requestParameters.authorizationCode === null || requestParameters.authorizationCode === undefined) {
            throw new runtime.RequiredError('authorizationCode', 'Required parameter requestParameters.authorizationCode was null or undefined when calling generateToken.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.authorizationCode !== undefined) {
            formParams.append('authorizationCode', requestParameters.authorizationCode);
        }
        const response = await this.request({
            path: `/admin/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Generate new token
     */
    async generateToken(requestParameters, initOverrides) {
        await this.generateTokenRaw(requestParameters, initOverrides);
    }
    /**
     * Get admin user
     */
    async getAdminUserRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AdminUserResultFromJSON)(jsonValue));
    }
    /**
     * Get admin user
     */
    async getAdminUser(initOverrides) {
        const response = await this.getAdminUserRaw(initOverrides);
        return await response.value();
    }
    /**
     * Get child folders
     */
    async getChildFoldersRaw(requestParameters, initOverrides) {
        if (requestParameters.parentFolderId === null || requestParameters.parentFolderId === undefined) {
            throw new runtime.RequiredError('parentFolderId', 'Required parameter requestParameters.parentFolderId was null or undefined when calling getChildFolders.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/folder/{parentFolderId}/children`.replace(`{${"parentFolderId"}}`, encodeURIComponent(String(requestParameters.parentFolderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AdminChildFoldersResultFromJSON)(jsonValue));
    }
    /**
     * Get child folders
     */
    async getChildFolders(requestParameters, initOverrides) {
        const response = await this.getChildFoldersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get folder by id
     */
    async getFolderByIdRaw(requestParameters, initOverrides) {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId', 'Required parameter requestParameters.folderId was null or undefined when calling getFolderById.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/folder/{folderId}`.replace(`{${"folderId"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AdminFolderResultFromJSON)(jsonValue));
    }
    /**
     * Get folder by id
     */
    async getFolderById(requestParameters, initOverrides) {
        const response = await this.getFolderByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get layout
     */
    async getLayoutRaw(requestParameters, initOverrides) {
        if (requestParameters.layoutId === null || requestParameters.layoutId === undefined) {
            throw new runtime.RequiredError('layoutId', 'Required parameter requestParameters.layoutId was null or undefined when calling getLayout.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/layout/{layoutId}`.replace(`{${"layoutId"}}`, encodeURIComponent(String(requestParameters.layoutId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.LayoutResultFromJSON)(jsonValue));
    }
    /**
     * Get layout
     */
    async getLayout(requestParameters, initOverrides) {
        const response = await this.getLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get page
     */
    async getPageRaw(requestParameters, initOverrides) {
        if (requestParameters.pageId === null || requestParameters.pageId === undefined) {
            throw new runtime.RequiredError('pageId', 'Required parameter requestParameters.pageId was null or undefined when calling getPage.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/page/{pageId}`.replace(`{${"pageId"}}`, encodeURIComponent(String(requestParameters.pageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AdminPageResultFromJSON)(jsonValue));
    }
    /**
     * Get page
     */
    async getPage(requestParameters, initOverrides) {
        const response = await this.getPageRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get pages by layout
     */
    async getPagesByLayoutRaw(requestParameters, initOverrides) {
        if (requestParameters.layoutId === null || requestParameters.layoutId === undefined) {
            throw new runtime.RequiredError('layoutId', 'Required parameter requestParameters.layoutId was null or undefined when calling getPagesByLayout.');
        }
        const queryParameters = {};
        if (requestParameters.layoutId !== undefined) {
            queryParameters['layoutId'] = requestParameters.layoutId;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.disconnectedPages !== undefined) {
            queryParameters['disconnectedPages'] = requestParameters.disconnectedPages;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AdminRelatedPagesResultFromJSON)(jsonValue));
    }
    /**
     * Get pages by layout
     */
    async getPagesByLayout(requestParameters, initOverrides) {
        const response = await this.getPagesByLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get children of the root folder
     */
    async getRootChildrenRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/folder`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AdminChildFoldersResultFromJSON)(jsonValue));
    }
    /**
     * Get children of the root folder
     */
    async getRootChildren(initOverrides) {
        const response = await this.getRootChildrenRaw(initOverrides);
        return await response.value();
    }
    /**
     * Reset page layout
     */
    async resetPageLayoutRaw(requestParameters, initOverrides) {
        if (requestParameters.pageId === null || requestParameters.pageId === undefined) {
            throw new runtime.RequiredError('pageId', 'Required parameter requestParameters.pageId was null or undefined when calling resetPageLayout.');
        }
        if (requestParameters.layoutId === null || requestParameters.layoutId === undefined) {
            throw new runtime.RequiredError('layoutId', 'Required parameter requestParameters.layoutId was null or undefined when calling resetPageLayout.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.pageId !== undefined) {
            formParams.append('pageId', requestParameters.pageId);
        }
        if (requestParameters.layoutId !== undefined) {
            formParams.append('layoutId', requestParameters.layoutId);
        }
        const response = await this.request({
            path: `/admin/page/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AdminPageResetResultFromJSON)(jsonValue));
    }
    /**
     * Reset page layout
     */
    async resetPageLayout(requestParameters, initOverrides) {
        const response = await this.resetPageLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Update layout
     */
    async updateLayoutRaw(requestParameters, initOverrides) {
        if (requestParameters.layoutId === null || requestParameters.layoutId === undefined) {
            throw new runtime.RequiredError('layoutId', 'Required parameter requestParameters.layoutId was null or undefined when calling updateLayout.');
        }
        if (requestParameters.adminLayoutPutData === null || requestParameters.adminLayoutPutData === undefined) {
            throw new runtime.RequiredError('adminLayoutPutData', 'Required parameter requestParameters.adminLayoutPutData was null or undefined when calling updateLayout.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/layout/{layoutId}`.replace(`{${"layoutId"}}`, encodeURIComponent(String(requestParameters.layoutId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.AdminLayoutPutDataToJSON)(requestParameters.adminLayoutPutData),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.LayoutResultFromJSON)(jsonValue));
    }
    /**
     * Update layout
     */
    async updateLayout(requestParameters, initOverrides) {
        const response = await this.updateLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Update page
     */
    async updatePageRaw(requestParameters, initOverrides) {
        if (requestParameters.pageId === null || requestParameters.pageId === undefined) {
            throw new runtime.RequiredError('pageId', 'Required parameter requestParameters.pageId was null or undefined when calling updatePage.');
        }
        if (requestParameters.adminPagePutBody === null || requestParameters.adminPagePutBody === undefined) {
            throw new runtime.RequiredError('adminPagePutBody', 'Required parameter requestParameters.adminPagePutBody was null or undefined when calling updatePage.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/page/{pageId}`.replace(`{${"pageId"}}`, encodeURIComponent(String(requestParameters.pageId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.AdminPagePutBodyToJSON)(requestParameters.adminPagePutBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AdminPageResultFromJSON)(jsonValue));
    }
    /**
     * Update page
     */
    async updatePage(requestParameters, initOverrides) {
        const response = await this.updatePageRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.AdminApi = AdminApi;
