"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoxParametersToJSONTyped = exports.BoxParametersToJSON = exports.BoxParametersFromJSONTyped = exports.BoxParametersFromJSON = void 0;
const runtime_1 = require("../runtime");
function BoxParametersFromJSON(json) {
    return BoxParametersFromJSONTyped(json, false);
}
exports.BoxParametersFromJSON = BoxParametersFromJSON;
function BoxParametersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'boxTitle': !(0, runtime_1.exists)(json, 'boxTitle') ? undefined : json['boxTitle'],
        'boxTitleUrl': !(0, runtime_1.exists)(json, 'boxTitleUrl') ? undefined : json['boxTitleUrl'],
    };
}
exports.BoxParametersFromJSONTyped = BoxParametersFromJSONTyped;
function BoxParametersToJSON(value) {
    return BoxParametersToJSONTyped(value, false);
}
exports.BoxParametersToJSON = BoxParametersToJSON;
function BoxParametersToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'boxTitle': value.boxTitle,
        'boxTitleUrl': value.boxTitleUrl,
    };
}
exports.BoxParametersToJSONTyped = BoxParametersToJSONTyped;
