
import { CastingFormData } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import CastingForm from '../../components/casting/CastingForm.vue'
import Image from '../../components/Image.vue'

@Options({
  name: 'CastingBlock',
  components: {
    CastingForm,
    Image
  }
})
export default class CastingBlock extends Vue {
  @Prop({ type: Object })
  readonly form!: CastingFormData
}
