import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag = _resolveComponent("tag")!
  const _component_celebrity_hero = _resolveComponent("celebrity-hero")!
  const _component_content_grid_widget = _resolveComponent("content-grid-widget")!
  const _component_content_list_widget = _resolveComponent("content-list-widget")!
  const _component_long_video_list_widget = _resolveComponent("long-video-list-widget")!
  const _component_page_layout = _resolveComponent("page-layout")!

  return (_ctx.page && _ctx.content)
    ? (_openBlock(), _createBlock(_component_page_layout, {
        key: 0,
        class: "celebrity-page",
        title: _ctx.content.title
      }, _createSlots({
        after: _withCtx(() => [
          _createVNode(_component_content_grid_widget, {
            title: 'Ajánlott videók',
            length: 5,
            compact: true,
            "show-lead": false,
            payload: { cast: _ctx.page.castTag }
          }, null, 8, ["payload"]),
          _createVNode(_component_content_list_widget, {
            title: 'Legnépszerűbb videók',
            length: 16,
            "show-lead": false,
            "show-slider": true,
            payload: { cast: _ctx.page.castTag }
          }, null, 8, ["payload"]),
          _createVNode(_component_long_video_list_widget, {
            title: 'RTL+ teljes adások',
            length: 16,
            payload: { cast: _ctx.page.castTag }
          }, null, 8, ["payload"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_celebrity_hero, { celebrity: _ctx.content }, null, 8, ["celebrity"])
        ]),
        _: 2
      }, [
        (_ctx.content.headingTag)
          ? {
              name: "header",
              fn: _withCtx(() => [
                _createVNode(_component_tag, {
                  class: "celebrity-page__tag",
                  tag: _ctx.content.headingTag
                }, null, 8, ["tag"])
              ])
            }
          : undefined
      ]), 1032, ["title"]))
    : _createCommentVNode("", true)
}