
import { Options, Vue } from 'vue-property-decorator'

import ContentPageWidget from '../widgets/ContentPageWidget.vue'

@Options({
  name: 'RssPage',
  components: {
    ContentPageWidget
  }
})
export default class RssPage extends Vue {}
