

import { formatDistance } from 'date-fns'
import { hu } from 'date-fns/locale'
import { Options, Prop, Vue } from 'vue-property-decorator'

@Options({
  name: 'TimeAgo',
  components: {}
})
export default class TimeAgo extends Vue {
  @Prop({ type: Date, required: true })
  private datetime!: Date

  private getTimeAgo (): string|undefined {
    return formatDistance(
      new Date(),
      this.datetime,
      { locale: hu }
    )
  }
}
