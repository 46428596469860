
import { Options, Prop, Vue, Watch } from 'vue-property-decorator'

import Exclamation from '../icons/Exclamation.vue'
import { InputAttrs } from './../../../store'

@Options({
  name: 'ContactInput',
  components: {
    Exclamation
  }
})
export default class ContactInput extends Vue {
  @Prop({ type: Object as () => InputAttrs, default: {} })
  readonly attrs!: InputAttrs;

  @Prop({ type: String, default: '' })
  readonly title!: string;

  @Prop({ type: String, default: 'input' })
  readonly type!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  errorMsg: string | null = null;
  currentCount = 0

  get limit () : number {
    return parseInt(this.attrs.maxLength)
  }

  value = ''

  @Watch('value')
  valueChanged (val: string) {
    this.errorMsg = val.length === 0 ? 'Mező nem lehet üres' : null
    this.currentCount = val.length

    this.$emit('update:value', { id: this.attrs.id, value: val })
  }
}
