import { Module } from 'vuex'
import { stringify } from 'qs'
import { Configuration } from '@rtl/api'
import { API_GETTER, API_MUTATION, ApiState } from './types'
import { ENV_GETTER } from '../env'

export function createApiModule<RootState> (namespaced = false): Module<ApiState, RootState> {
  return {
    namespaced,
    state () {
      return {
        accessToken: null,
        path: null
      }
    },
    getters: {
      [API_GETTER.CONFIG] (state, getters, rootState, rootGetters) {
        const apiUrl = rootGetters[ENV_GETTER.ENV]?.('API_URL') || 'https://api.rtl.hu'

        return new Configuration({
          basePath: apiUrl,
          queryParamsStringify: query => stringify(query, { encode: false }),
          accessToken: state.accessToken || undefined
        })
      },
      [API_GETTER.PARAMS] (state) {
        return {
          requestReferer: state.path
        }
      }
    },
    mutations: {
      [API_MUTATION.TOKEN] (state, payload) {
        state.accessToken = payload
      },
      [API_MUTATION.PATH] (state, payload) {
        state.path = payload
      }
    }
  }
}
