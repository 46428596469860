
import { Options, Prop, Vue } from 'vue-property-decorator'

@Options({
  name: 'Settings'
})
export default class Settings extends Vue {
  @Prop({
    type: String,
    default: 'white'
  })
  readonly fill!: string;
}
