"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdoKeysMediaToJSONTyped = exports.AdoKeysMediaToJSON = exports.AdoKeysMediaFromJSONTyped = exports.AdoKeysMediaFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdoKeysMediaFromJSON(json) {
    return AdoKeysMediaFromJSONTyped(json, false);
}
exports.AdoKeysMediaFromJSON = AdoKeysMediaFromJSON;
function AdoKeysMediaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'zones': !(0, runtime_1.exists)(json, 'zones') ? undefined : (0, _1.AdoKeysMediaZonesFromJSON)(json['zones']),
        'masterId': !(0, runtime_1.exists)(json, 'masterId') ? undefined : json['masterId'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'keys': !(0, runtime_1.exists)(json, 'keys') ? undefined : json['keys'],
        'vars': !(0, runtime_1.exists)(json, 'vars') ? undefined : json['vars'],
    };
}
exports.AdoKeysMediaFromJSONTyped = AdoKeysMediaFromJSONTyped;
function AdoKeysMediaToJSON(value) {
    return AdoKeysMediaToJSONTyped(value, false);
}
exports.AdoKeysMediaToJSON = AdoKeysMediaToJSON;
function AdoKeysMediaToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'zones': (0, _1.AdoKeysMediaZonesToJSON)(value.zones),
        'masterId': value.masterId,
        'id': value.id,
        'keys': value.keys,
        'vars': value.vars,
    };
}
exports.AdoKeysMediaToJSONTyped = AdoKeysMediaToJSONTyped;
