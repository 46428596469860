
import { CastingFormListDataFields } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { CastingFormListResult, FORM_ACTION, FORM_NAMESPACE, FormListPayload } from '../../../store'
import Image from '../Image.vue'
import NavLink from '../NavLink.vue'

@Options({
  name: 'CastingGroup',
  components: {
    NavLink,
    Image
  }
})
export default class CastingGroup extends Vue {
  @Action(FORM_ACTION.LIST, { namespace: FORM_NAMESPACE })
  storeFetchFormList!: (payload: FormListPayload) => Promise<CastingFormListResult>

  @Prop({ type: String })
  readonly title!: string | undefined

  @Prop({ type: Number })
  readonly groupId!: number | undefined

  @Prop({ type: Array })
  readonly forms!: CastingFormListDataFields[] | undefined

  get items (): CastingFormListDataFields[] | null {
    if (!this.groupId) {
      return this.forms || null
    }

    return this.formList?.items || null
  }

  get formListPayload (): FormListPayload {
    return {
      limit: 50,
      offset: 0,
      groupId: this.groupId || undefined
    }
  }

  get formList (): CastingFormListResult['data'] | null {
    if (this.formListPayload) {
      return this.$store.getters['$cache/result']([FORM_NAMESPACE, FORM_ACTION.LIST], this.formListPayload)?.data || null
    }

    return null
  }

  async fetchFormList () {
    if (this.formListPayload) {
      return this.storeFetchFormList(this.formListPayload)
    }
  }

  mounted () {
    if (this.groupId) {
      return this.fetchFormList()
    }
  }
}
