export const Storage = {
  set (name: string, value: unknown) {
    localStorage.setItem(name, JSON.stringify(value))
  },
  get (name: string) {
    const value = localStorage.getItem(name)
    return value ? JSON.parse(value) : null
  },
  delete (name: string) {
    localStorage.removeItem(name)
  }
}

export const Cookie = {
  set: (name: string, value: unknown, expireHours: number | null = null, path = '/') => {
    let expires = ''
    if (expireHours !== null) {
      const d = new Date()
      d.setTime(d.getTime() + (expireHours * 60 * 60 * 1000))
      expires = 'expires=' + d.toUTCString()
    }

    document.cookie = `${name}=${JSON.stringify(value)};${expires};path=${path}; SameSite=None; Secure`
  },
  get: (name: string) => {
    const query = name + '='
    const ca = document.cookie.split(';')
    console.log(ca)

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(query) === 0) {
        return JSON.parse(c.substring(query.length, c.length))
      }
    }

    return null
  },
  delete: (name: string, path = '/') => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`
  }
}
