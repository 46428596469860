
import { Options, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import Checkbox from '../auth/Checkbox.vue'
import { CategoryData, CONTACT_FORM_GETTERS, CONTACT_FORM_MUTATION, CONTACT_FORM_NAMESPACE, FormFilters, FormInputs, FormOption } from './../../../store'
import ArrowRight from './../icons/ArrowRight.vue'
import ConfirmScreen from './ConfirmScreen.vue'
import ContactFormBox from './ContactFormBox.vue'
import ContactFormSelect from './ContactFormSelect.vue'
import ContactInput from './ContactInput.vue'

@Options({
  name: 'ContactForm',
  components: {
    ContactFormBox,
    ContactFormSelect,
    ContactInput,
    ArrowRight,
    ConfirmScreen,
    Checkbox
  }
})

export default class ContactForm extends Vue {
  @Getter(CONTACT_FORM_GETTERS.GET_DATA, { namespace: CONTACT_FORM_NAMESPACE })
  categoryData!: CategoryData

  @Getter(CONTACT_FORM_GETTERS.GET_INPUTS, { namespace: CONTACT_FORM_NAMESPACE })
  formInputs!: FormInputs

  @Getter(CONTACT_FORM_GETTERS.GET_STEP, { namespace: CONTACT_FORM_NAMESPACE })
  step!: number

  @Getter(CONTACT_FORM_GETTERS.GET_FORM_URL, { namespace: CONTACT_FORM_NAMESPACE })
  formUrl!: string

  @Getter(CONTACT_FORM_GETTERS.GET_FORM_ID, { namespace: CONTACT_FORM_NAMESPACE })
  formId!: string

  @Mutation(CONTACT_FORM_MUTATION.SET_STEP, { namespace: CONTACT_FORM_NAMESPACE })
  setStep!: (payload: number) => void

  consentAccepted = false
  sent = false
  loading = false

  selectedCategories: {[key: string]: string | null} = {
    service: null,
    mainCategory: null,
    subCategory: null,
    moreCategory: null
  }

  descriptionFileds: Array<{id: string, value: string | null, required: boolean}> = []

  get selectedService () : string | null {
    return this.selectedCategories.service
  }

  get selectedMainCat () : string | null {
    return this.selectedCategories.mainCategory
  }

  get selectedSubCat () : string | null {
    return this.selectedCategories.subCategory
  }

  get mappedSubcategory () : Array<FormOption> {
    return this.categoryData.subCategory.options.map((sub: FormOption) => {
      const filteredOptions = this.categoryData.moreCategory.options.filter((more: FormOption) => {
        return more.parent === sub.value
      })
      return { ...sub, filtered: filteredOptions[0].value !== sub.value ? filteredOptions : undefined }
    })
  }

  get filteredInputs () : FormInputs {
    this.descriptionFileds = []
    const category = this.selectedCategories.moreCategory

    return this.formInputs.reduce<FormInputs>((acc, input) => {
      if (category && (input.mandatory.includes(category) || input.mandatory.includes('*'))) {
        acc.push({ ...input, attrs: { ...input.attrs, required: true } })
        this.descriptionFileds.push({ id: input.attrs.id, value: '', required: true })
      }

      if (input.optional && category && input.optional.includes(category)) {
        acc.push(input)
        this.descriptionFileds.push({ id: input.attrs.id, value: '', required: false })
      }

      return acc
    }, [])
  }

  get hideMoreCategory () : boolean {
    if (this.selectedCategories.mainCategory) {
      const duplicated = this.mappedSubcategory.filter((item) => { return item.value === this.selectedCategories.mainCategory })
      return duplicated.length !== 0
    } else {
      return true
    }
  }

  get isCategorySelectorValid () : boolean {
    return Object.keys(this.selectedCategories).some((key: string) => {
      return this.selectedCategories[key] === null
    })
  }

  get isDescriptionStepValid () : boolean {
    return this.descriptionFileds.some((field) => {
      return field.required && field.value === ''
    })
  }

  updateInput (payload: {id: string, value: string}) {
    const item = this.descriptionFileds.find((field) => field.id === payload.id)
    if (item) {
      item.value = payload.value
    }
  }

  serviceChanged (value: string) {
    this.selectedCategories = { ...this.selectedCategories, service: value, mainCategory: null, subCategory: null, moreCategory: null }
  }

  mainCatChanged (value: string) {
    this.selectedCategories = { ...this.selectedCategories, mainCategory: value, subCategory: null, moreCategory: null }
    if (this.hideMoreCategory) {
      this.selectedCategories = { ...this.selectedCategories, subCategory: value, moreCategory: value }
    }
  }

  moreCatChanged (value: string) {
    const selected = this.categoryData.moreCategory.options.find((item) => { return item.value === value })
    if (selected) {
      this.selectedCategories = { ...this.selectedCategories, subCategory: selected.parent || '', moreCategory: value }
    }
  }

  hidden (parentCategory: string | null, services: Array<string>, parent: string | null) : boolean {
    const conformsService = !!(services.find(service => (FormFilters[service] === this.selectedService)))
    const selectedParent: string | null = parentCategory ? this.selectedCategories[parentCategory] : null
    const conformsParent = (parent === selectedParent)

    return !(conformsService && conformsParent)
  }

  mounted () {
    this.setStep(1)
  }

  send () {
    const form = this.$refs.form as HTMLFormElement
    if (form.checkValidity()) {
      const submitBtn = this.$refs.submitButton as HTMLInputElement
      this.loading = true
      submitBtn.click()
    }
  }

  sendForm () {
    setTimeout(() => {
      this.sent = true
      this.loading = false
      this.$emit('formSent', this.sent)
    }, 1000)
  }
}
