import { defaultConfig, MetaResolver } from 'vue-meta'
import { RouteLocation, Router } from 'vue-router'
import { ContentData, PageData } from '@rtl/api'
import { toIsoString } from './time.util'
import { Store } from 'vuex'
import { AppState, MEASUREMENT_ACTION, MEASUREMENT_NAMESPACE, MeasurementAdoScriptLoadedPayload } from '../store'

type ComponentMetaInfo = { // TODO: ComponentMetaInfo is only internal for vue-meta
  [key: string]: string | ComponentMetaInfo
}

export const metaConfig = {
  ...defaultConfig,
  style: {
    tag: 'style'
  },
  keywords: {
    tag: 'meta'
  },
  article: {
    group: true,
    namespacedAttribute: true,
    tag: 'meta',
    keyAttribute: 'property'
  },
  video: {
    group: true,
    namespacedAttribute: true,
    tag: 'meta',
    keyAttribute: 'property'
  },
  robots: {
    tag: 'meta'
  }
}

export const metaResolver: MetaResolver = {
  resolve <T>(sources: T[]) {
    const hasArrayOption = sources.some(source => Array.isArray(source))
    if (hasArrayOption) {
      const groupedOptions: Record<string, object> = {}
      for (const source of sources) {
        if (!Array.isArray(source)) {
          continue
        }

        for (const value of source) {
          if (Object.prototype.toString.call(value) === '[object Object]' && 'vmid' in value) {
            // @ts-ignore
            groupedOptions[value.vmid] = value
          }
        }
      }

      const values = []
      for (const source of sources) {
        if (!Array.isArray(source)) {
          continue
        }

        for (const value of source) {
          if (Object.prototype.toString.call(value) !== '[object Object]' || !('vmid' in value)) {
            values.push(value)
          // @ts-ignore
          } else if (groupedOptions[value.vmid]) {
            // @ts-ignore
            values.push(groupedOptions[value.vmid])
            // @ts-ignore
            delete groupedOptions[value.vmid]
          }
        }
      }

      return values
    }

    return sources[sources.length - 1]
  }
}

export function getMetaFromRaw (metaData: Array<{ key?: string, value?: string }> = []) {
  return metaData.reduce<ComponentMetaInfo>((res, meta) => {
    const { key: name, value: content } = meta

    if (name && content) {
      const parts = name.split(':')
      const key = parts.shift() as string
      if (parts.length) {
        if (!res[key] || typeof res[key] !== 'object') {
          res[key] = {}
        }
        (res[key] as ComponentMetaInfo)[parts.join(':')] = content
      } else {
        res[key] = content
      }
    }

    return res
  }, {})
}

export function generateMetaInfo (route: RouteLocation, router: Router, page?: PageData, content?: ContentData, store?: Store<AppState>) {
  const domain = 'https://rtl.hu'
  const link: Array<unknown> = []
  const script: { [key: string]: unknown }[] = []

  // links
  link.push({
    rel: 'canonical',
    href: `${domain}${router.resolve({ query: { oldal: route.query['oldal'] }}).href}`
  })

  // scripts
  script.push({
    vmid: 'logo-schema',
    type: 'application/ld+json',
    json: {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: domain,
      logo: 'https://cdn.rtl.hu/9f/59/image_818396c83981bbb75bd43e5f8cb2'
    }
  })

  if (typeof window === 'undefined' || !window?.location.hostname.startsWith('app.')) {
    const dispatchAdoScriptLoaded = () => {
      store?.dispatch(`${MEASUREMENT_NAMESPACE}/${MEASUREMENT_ACTION.ADO_SCRIPT_LOADED}`)
    }
    script.push({
      vmid: 'adocean',
      src: '//hu.adocean.pl/files/js/ado.js',
      async: true,
      defer: true,
      onload: () => dispatchAdoScriptLoaded(),
      onerror: () => dispatchAdoScriptLoaded()
    })
  }

  const airship = store?.getters.getEnv('AIRSHIP')
  if (airship) {
    script.push({
      vmid: 'airship',
      type: 'text/javascript',
      innerHTML: `
        // 86acbd31cd7c09cf30acb66d2fbedc91daa48b86:1659520575.5442133
        !function(n,r,e,t,c){var i,o="Promise"in n,u={then:function(){return u},catch:function(n){
        return n(new Error("Airship SDK Error: Unsupported browser")),u}},s=o?new Promise((function(n,r){i=function(e,t){e?r(e):n(t)}})):u
        ;s._async_setup=function(n){if(o)try{i(null,n(c))}catch(n){i(n)}},n[t]=s;var a=r.createElement("script");a.src=e,a.async=!0,a.id="_uasdk",
        a.rel=t,r.head.appendChild(a)}(window,document,'https://aswpsdkeu.com/notify/v1/ua-sdk.min.js',
          'UA', JSON.parse('${airship}'));
      `,
    })
  }

  if (content) {
    const contentTitle = content.alternativeTitle || content.title
    const contentDescription = content.alternativeLead || content.lead || ''
    const contentReleaseDate = content.releaseDate && toIsoString(content.releaseDate)

    const images = []
    if (content.leadImageUrls?.leadImageUrl) {
      images.push(content.leadImageUrls.leadImageUrl)
    }

    const authors = (content.authors || []).map(author => {
      return {
        '@type': 'Person',
        name: author.name
      }
    })

    script.push({
      vmid: 'article-schema',
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        headline: contentTitle,
        image: images,
        datePublished: contentReleaseDate,
        dateModified: content.modifiedTs && toIsoString(content.modifiedTs),
        author: authors,
        video: content.params?.withVideo ? {
          '@type': 'VideoObject',
          name: contentTitle,
          description: contentDescription.replace(/<[^>]*>?/gm, '').trim(),
          thumbnailUrl: images,
          uploadDate: contentReleaseDate,
          duration: `PT${content.params?.duration || 0}S`,
          embedUrl: content.params?.embedVideoUrl
        } : undefined
      }
    })
  }

  return {
    title: 'RTL.hu',
    ...getMetaFromRaw(page?.metaTags),
    link,
    script
  }
}
