import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bulk_content_list_card = _resolveComponent("bulk-content-list-card")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_ctx.contentDataList?.length)
    ? (_openBlock(), _createBlock(_component_widget_block, {
        key: 0,
        title: "Összes tartalom",
        "show-slider": false
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentDataList, (item, i) => {
            return (_openBlock(), _createBlock(_component_bulk_content_list_card, {
              key: 'bulk_content_id_'+i,
              "content-data": item
            }, null, 8, ["content-data"]))
          }), 128)),
          _createElementVNode("button", {
            class: "bulk-content-list__more-content-reveal button-show-all",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('loadMore')))
          }, " Még több tartalom ")
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}