import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_casting_block = _resolveComponent("casting-block")!

  return (_ctx.form)
    ? (_openBlock(), _createBlock(_component_casting_block, {
        key: 0,
        form: _ctx.form
      }, null, 8, ["form"]))
    : _createCommentVNode("", true)
}