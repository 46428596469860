
import { ContentData } from '@rtl/api'
import { Options, Prop, Vue, Watch } from 'vue-property-decorator'

import { renderContentWidgets } from '../../utils'
import Renderer from './Renderer.vue'

@Options({
  name: 'ContentWidgetRenderer',
  components: { Renderer }
})
export default class ContentWidgetRenderer extends Vue {
  @Prop({
    type: String,
    required: true
  })
  readonly html!: string

  @Prop({
    type: Object as () => ContentData['widgets'],
    default: () => ({})
  })
  readonly widgets!: ContentData['widgets']

  template = ''

  @Watch('widgets', { deep: true, immediate: true })
  onWidgetsChange () {
    this.template = renderContentWidgets(this.widgets, this.html)
  }
}
