import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "32",
  height: "27",
  viewBox: "0 0 32 27",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M32 21H0V0H32V21ZM3 18H29V3H3V18Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M26 24H6V27H26V24Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_3)
  ]))
}