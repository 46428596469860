
import { CastingFormResult } from '@rtl/api'
import { mixins, Options, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { FORM_ACTION, FORM_NAMESPACE, FormPayload } from '../../store'
import CastingBlock from '../components/casting/CastingBlock.vue'
import { GetPageMixin } from '../mixins'
import WidgetMixin from './WidgetMixin'

@Options({
  name: 'CastingFormWidget',
  components: {
    CastingBlock
  }
})
export default class CastingFormWidget extends mixins(GetPageMixin, WidgetMixin) {
  @Action(FORM_ACTION.ITEM, { namespace: FORM_NAMESPACE })
  storeFetchForm!: (payload: FormPayload) => Promise<CastingFormResult>

  get form (): CastingFormResult['data'] | null {
    if (this.formPayload) {
      return this.$store.getters['$cache/result']([FORM_NAMESPACE, FORM_ACTION.ITEM], this.formPayload)?.data || null
    }

    return null
  }

  get formPayload (): FormPayload | null {
    const formCode = this.page?.contentId
    return formCode ? { formCode } : null
  }

  @Watch('formPayload', { deep: true })
  async fetchForm () {
    if (this.formPayload) {
      return this.storeFetchForm(this.formPayload)
    }
  }

  serverPrefetch () {
    return this.fetchForm()
  }

  mounted () {
    return this.fetchForm()
  }
}
