
import { Options, Prop, Vue } from 'vue-property-decorator'

@Options({
  name: 'PageLayout'
})
export default class PageLayout extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  readonly title!: string

  hasSlot (key: string) {
    const slot = this.$slots[key]
    const vnodes = slot && slot()

    if (vnodes) {
      for (const i in vnodes) {
        if (typeof vnodes[i].type !== 'symbol' || vnodes[i].children?.length) {
          return true
        }
      }
    }
    return false
  }
}
