"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminRelatedPagesDataToJSONTyped = exports.AdminRelatedPagesDataToJSON = exports.AdminRelatedPagesDataFromJSONTyped = exports.AdminRelatedPagesDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdminRelatedPagesDataFromJSON(json) {
    return AdminRelatedPagesDataFromJSONTyped(json, false);
}
exports.AdminRelatedPagesDataFromJSON = AdminRelatedPagesDataFromJSON;
function AdminRelatedPagesDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'totalCount': !(0, runtime_1.exists)(json, 'totalCount') ? undefined : json['totalCount'],
        'payload': !(0, runtime_1.exists)(json, 'payload') ? undefined : (0, _1.AdminRelatedPagesDataPayloadFromJSON)(json['payload']),
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(_1.AdminRelatedPagesFromJSON)),
    };
}
exports.AdminRelatedPagesDataFromJSONTyped = AdminRelatedPagesDataFromJSONTyped;
function AdminRelatedPagesDataToJSON(value) {
    return AdminRelatedPagesDataToJSONTyped(value, false);
}
exports.AdminRelatedPagesDataToJSON = AdminRelatedPagesDataToJSON;
function AdminRelatedPagesDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'totalCount': value.totalCount,
        'payload': (0, _1.AdminRelatedPagesDataPayloadToJSON)(value.payload),
        'items': value.items === undefined ? undefined : (value.items.map(_1.AdminRelatedPagesToJSON)),
    };
}
exports.AdminRelatedPagesDataToJSONTyped = AdminRelatedPagesDataToJSONTyped;
