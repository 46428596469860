
import { Options, Prop, Vue } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'

import ArrowRight from '../icons/ArrowRight.vue'
import { FAQ_MUTATION, FAQ_NAMESPACE, FaqQA, FaqTopic } from './../../../store'
import FaqIcon from './FaqIcon.vue'

@Options({
  name: 'FaqCard',
  components: {
    FaqIcon,
    ArrowRight
  }
})
export default class FaqCard extends Vue {
  @Mutation(FAQ_MUTATION.SET_QUESTION, { namespace: FAQ_NAMESPACE })
  setQuestion!: (payload: string | null) => void;

  @Prop({
    type: Object as () => FaqTopic,
    default: {}
  })
  readonly topic!: FaqTopic

  @Prop({
    type: Number,
    default: 3
  })
  readonly limit!: number

  get topQuestions () : Array<{sub: string | null, question: string, q: string}> { // todo rename
    let questions: Array<{sub: string | null, question: string, q: string}> = []
    let index = 0

    while (questions.length < this.limit) {
      if (this.topic.subTopics[index]) {
        const selected = this.topic.subTopics[index].questions.slice(0, this.limit - questions.length).map((item: FaqQA) => {
          return { sub: this.topic.subTopics[index].tag, question: item.question, q: item.tag }
        })
        index += 1
        questions = questions.concat(selected)
      } else { break }
    }
    return questions
  }

  selectQuestion (question: string) {
    this.$route.meta.question = question
    this.setQuestion(question)
  }
}
