import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "video-player"
}
const _hoisted_2 = { class: "video-player__pip-overlay" }
const _hoisted_3 = { class: "video-player__pip-title" }
const _hoisted_4 = { class: "video-player__video-wrapper" }
const _hoisted_5 = ["width", "height", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _directive_visibility = _resolveDirective("visibility")!

  return (_ctx.fullVideoSrc)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["video-player__overlay", { 'video-player__overlay--pip': _ctx.isPip }])
        }, " video ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["video-player__container", { 'video-player__container--pip': _ctx.isPip }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createVNode(_component_Close, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isClosed = true))
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("iframe", {
              width: _ctx.width,
              height: _ctx.height,
              src: _ctx.fullVideoSrc,
              frameborder: "0",
              allowfullscreen: "",
              allow: "autoplay"
            }, null, 8, _hoisted_5)
          ])
        ], 2)
      ])), [
        [_directive_visibility, { onChange: _ctx.onChangeVisibility, observerOptions: { threshold: .4 } }]
      ])
    : _createCommentVNode("", true)
}