import { AbstractApi, FetchResponse } from "./abstract-api";

export type Image = string;

export type VoteSubject = {
  id: string;
  code: string;
  alias?: string;
  name: string;
  image?: Image;
};

export type VoteQuestion = {
  id: string;
  title: string;
  text?: string;
  type: "battle" | "yesno" | "balance";
  subjects: Array<VoteSubject>;
  min?: number;
  max?: number;
};

export type VoteAdvertising = {
  id: string;
  image: Image;
  code: string;
  link?: string;
};

export type VoteItem = {
  id: string;
  url: string;
  main_title: string;
  main_text?: string;
  image?: Image;
  style: {
    logo?: Image;
    bg_image?: Image;
    bg_color?: string;
  };
  pending_title: string;
  pending_text?: string;
  advertising1?: VoteAdvertising | null;
  advertising2?: VoteAdvertising | null;
  form_active: boolean;
  form_action: string;
  form_terms?: string;
  form_batchable?: boolean;
  form_modifiable?: boolean;
  sms?: boolean;
  questions: Array<VoteQuestion>;
  created_at: number;
  updated_at: number;
};

export type VoteItemResponse = FetchResponse<VoteItem>;

export type VoteItemRequest = {
  data: {
    group: string;
    slug: string;
  };
};

export type VoteList = Array<VoteItem>;

export type VoteListResponse = FetchResponse<VoteList>;

export type VoteListRequest = {
  data?: {
    offset?: number;
    limit?: number;
  };
};

export type VoteCreateResponse = FetchResponse<Record<string, never>>;

export type VoteCreateRequest = {
  url: string;
  token: string;
  data: {
    user: string;
    answers: Array<VoteAnswer>;
    platform: string;
    modify?: boolean;
  };
};

export type VoteAnswer = {
  question_id: string;
  subject_id: string;
  subject_code: string;
  value: boolean | null;
};

export class VoteApi extends AbstractApi {
  static basePath = process.env.VUE_APP_VOTE_API || null;

  async fetchItem(opts: VoteItemRequest): Promise<VoteItemResponse> {
    const response = await this.fetchList({});
    const data = response.data.find((item) => {
      const [voteGroup, voteSlug] = item.url.split("/").slice(-2);
      return voteGroup === opts.data.group && voteSlug === opts.data.slug;
    });
    if (!data) {
      throw new Error("404 (Not found)");
    }
    return { ...response, data };
  }

  async fetchList(opts: VoteListRequest): Promise<VoteListResponse> {
    const ttl = 2000;
    return await VoteApi.fetch(`votes.json`, {
      method: "GET",
      params: {
        ts: Math.floor(Date.now() / ttl) * ttl,
        ...opts.data,
      },
    });
  }

  async createVote(opts: VoteCreateRequest): Promise<VoteCreateResponse> {
    return await VoteApi.fetch(opts.url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${opts.token}`,
      },
      body: JSON.stringify(opts.data),
    });
  }
}
