"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OembedErrorToJSONTyped = exports.OembedErrorToJSON = exports.OembedErrorFromJSONTyped = exports.OembedErrorFromJSON = void 0;
function OembedErrorFromJSON(json) {
    return OembedErrorFromJSONTyped(json, false);
}
exports.OembedErrorFromJSON = OembedErrorFromJSON;
function OembedErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': json['status'],
        'message': json['message'],
    };
}
exports.OembedErrorFromJSONTyped = OembedErrorFromJSONTyped;
function OembedErrorToJSON(value) {
    return OembedErrorToJSONTyped(value, false);
}
exports.OembedErrorToJSON = OembedErrorToJSON;
function OembedErrorToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': value.status,
        'message': value.message,
    };
}
exports.OembedErrorToJSONTyped = OembedErrorToJSONTyped;
