import { Options, Prop, Vue } from 'vue-property-decorator'

import { WidgetMeta } from './WidgetMixin'

@Options({
  name: 'WidgetStyleMixin'
})
export default class WidgetStyleMixin extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  readonly whiteText!: boolean

  @Prop({
    type: String,
    default: null
  })
  readonly bgColor!: string

  @Prop({
    type: String,
    default: null
  })
  readonly logoUrl!: string

  get widgetStyleBinding () {
    return {
      whiteText: this.whiteText,
      bgColor: this.bgColor,
      logoUrl: this.logoUrl
    }
  }
}

export const widgetStyleFields: WidgetMeta['fields'] = {
  whiteText: {
    type: Boolean,
    default: false,
    props: {
      label: 'Fehér szövegszín'
    }
  },
  bgColor: {
    type: 'color',
    props: {
      label: 'Háttérszín'
    }
  },
  logoUrl: {
    type: String,
    props: {
      placeholder: 'Szponzorált kép (url)'
    }
  }
}
