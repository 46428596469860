
import { mixins, Options, Prop, Provide } from 'vue-property-decorator'

import { shuffle } from '../../utils'
import ContentCardList from '../components/content/ContentCardList.vue'
import { ContentCardParamsMixin, FetchRssFeedMixin } from '../mixins'
import WidgetMixin, { WidgetMeta, widgetMetaFields } from './WidgetMixin'
import WidgetStyleMixin, { widgetStyleFields } from './WidgetStyleMixin'

export const meta: WidgetMeta = {
  label: 'RSS Feed lista',
  groups: {
    style: 'Beállítás'
  },
  defaultGroup: 'style',
  fields: {
    ...widgetMetaFields,
    ...widgetStyleFields,
    length: {
      type: Number,
      default: 16,
      props: {
        placeholder: 'Darabszám',
        min: 2
      }
    },
    rssFeedUrl: {
      type: String,
      default: '',
      props: {
        label: 'RSS feed',
        placeholder: 'Url'
      }
    },
    showLead: {
      type: Boolean,
      default: true,
      props: {
        label: 'Leírás'
      }
    },
    showImage: {
      type: Boolean,
      default: true,
      props: {
        label: 'Kép'
      }
    },
    showTitle: {
      type: Boolean,
      default: true,
      props: {
        label: 'Cím'
      }
    },
    showSlider: {
      type: Boolean,
      default: true,
      props: {
        label: 'Lapozó'
      }
    }
  }
}

@Options({
  name: 'RssFeedListWidget',
  components: {
    ContentCardList
  }
})
export default class RssFeedListWidget extends mixins(FetchRssFeedMixin, WidgetMixin, ContentCardParamsMixin, WidgetStyleMixin) {
  @Prop({
    type: Number,
    default: 16
  })
  readonly length!: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly shuffle!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly isVertical!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showSlider!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showButton!: boolean

  @Provide() target = '_blank'

  get cols () {
    if (this.showSlider) {
      return 4
    }

    return this.isVertical
      ? 0
      : [4, 3, 2].sort((a, b) => {
          const aDiff = Math.ceil(this.length / a) * a - this.length
          const bDiff = Math.ceil(this.length / b) * b - this.length
          return aDiff - bDiff
        })[0]
  }

  get contents () {
    const items = this.rssFeedData || []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.shuffle && (this as any).$routeReferer ? shuffle(items) : items).slice(0, this.length)
  }
}
