"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchDataToJSONTyped = exports.SearchDataToJSON = exports.SearchDataFromJSONTyped = exports.SearchDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SearchDataFromJSON(json) {
    return SearchDataFromJSONTyped(json, false);
}
exports.SearchDataFromJSON = SearchDataFromJSON;
function SearchDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'sites': !(0, runtime_1.exists)(json, 'sites') ? undefined : (0, _1.SearchSitesFromJSON)(json['sites']),
        'celeb': !(0, runtime_1.exists)(json, 'celeb') ? undefined : (0, _1.SearchContentsFromJSON)(json['celeb']),
        'other': !(0, runtime_1.exists)(json, 'other') ? undefined : (0, _1.SearchContentsFromJSON)(json['other']),
    };
}
exports.SearchDataFromJSONTyped = SearchDataFromJSONTyped;
function SearchDataToJSON(value) {
    return SearchDataToJSONTyped(value, false);
}
exports.SearchDataToJSON = SearchDataToJSON;
function SearchDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'sites': (0, _1.SearchSitesToJSON)(value.sites),
        'celeb': (0, _1.SearchContentsToJSON)(value.celeb),
        'other': (0, _1.SearchContentsToJSON)(value.other),
    };
}
exports.SearchDataToJSONTyped = SearchDataToJSONTyped;
