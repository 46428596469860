import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner_widget = _resolveComponent("banner-widget")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_loader = _resolveComponent("loader")!
  const _component_extra_header = _resolveComponent("extra-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_facebook_modal = _resolveComponent("facebook-modal")!
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_banner_widget, {
      class: "layout-banner",
      zone: "leaderboard_1",
      "show-background": false,
      "label-position": "left"
    }),
    _createVNode(_component_main_header, {
      class: _normalizeClass({ 'main-header--loading': _ctx.logoLoading })
    }, null, 8, ["class"]),
    _createElementVNode("main", _hoisted_1, [
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_extra_header, { url: _ctx.extraHeaderUrl }, null, 8, ["url"]),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_router_view)
      ])
    ]),
    _createVNode(_component_facebook_modal),
    _createVNode(_component_banner_widget, {
      class: "layout-banner",
      zone: "footer",
      "show-background": false,
      "label-position": "left"
    }),
    _createVNode(_component_main_footer),
    _createVNode(_component_banner_widget, {
      class: "layout-banner",
      zone: "special",
      "label-position": "",
      "show-background": false,
      "show-placeholder": false
    }),
    _createVNode(_component_banner_widget, {
      class: "layout-banner",
      zone: "loading_page",
      "label-position": "",
      "show-background": false,
      "show-placeholder": false
    })
  ], 64))
}