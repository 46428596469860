import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "banner__container" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_ctx.zoneId || _ctx.showPlaceholder)
    ? (_openBlock(), _createBlock(_component_widget_block, {
        key: 0,
        class: _normalizeClass(["banner", {
      [`banner--${_ctx.labelPosition}`]: _ctx.labelPosition,
      'banner--background': _ctx.showBackground,
      'banner--placeholder': _ctx.showPlaceholder,
      'banner--fullfilled': _ctx.isFullfilled}]),
        "show-divider": false,
        "show-title": false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              id: _ctx.zoneId,
              class: "banner__content",
              "data-label": "hirdetés",
              ref: "banner"
            }, [
              (_ctx.showPlaceholder)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" [ Banner " + _toDisplayString(_ctx.zoneKey) + " ] ", 1)
                  ], 64))
                : _createCommentVNode("", true)
            ], 8, _hoisted_2)
          ])
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}