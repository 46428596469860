import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "page-layout" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "page-layout__header"
}
const _hoisted_4 = {
  key: 0,
  class: "page-layout__title"
}
const _hoisted_5 = { class: "page-layout__main" }
const _hoisted_6 = {
  key: 0,
  class: "page-layout__side"
}
const _hoisted_7 = {
  key: 1,
  class: "page-layout__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title || _ctx.hasSlot('topheader') || _ctx.hasSlot('subheader'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "topheader"),
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "subheader")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["page-layout__row", {'page-layout__row--grid': _ctx.hasSlot('side')}])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        (_ctx.hasSlot('side'))
          ? (_openBlock(), _createElementBlock("aside", _hoisted_6, [
              _renderSlot(_ctx.$slots, "side")
            ]))
          : _createCommentVNode("", true),
        (_ctx.hasSlot('footer'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "footer")
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _renderSlot(_ctx.$slots, "after")
  ]))
}