
import { Options, Prop, Vue, Watch } from 'vue-property-decorator'

import AgeLimit from './AgeLimit.vue'
import NavLink from './NavLink.vue'

@Options({
  name: 'AdultConfirm',
  components: {
    AgeLimit,
    NavLink
  },
  inheritAttrs: false
})
export default class AdultConfirm extends Vue {
  @Prop({
    type: Number,
    default: 0
  })
  readonly ageRating!: number

  @Prop({
    type: String,
    default: '/'
  })
  readonly cancelUrl!: string

  enabled = true

  approve () {
    this.enabled = true
    this.$nextTick(() => {
      this.$emit('approve')
    })
  }

  @Watch('ageRating', { immediate: true })
  update () {
    this.enabled = this.ageRating !== 18
  }
}
