
import { Options, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import { FAQ_GETTERS, FAQ_MUTATION, FAQ_NAMESPACE } from './../../../store'
import MainLogo from './../icons/MainLogo.vue'
import Search from './../icons/Search.vue'

@Options({
  name: 'FaqNav',
  components: {
    MainLogo,
    Search
  }
})
export default class FaqNav extends Vue {
  @Getter(FAQ_GETTERS.GET_TOPIC_NAMES, { namespace: FAQ_NAMESPACE })
  topicNames!: Array<{label: string, tag: string | undefined}>

  @Mutation(FAQ_MUTATION.SET_TOPIC, { namespace: FAQ_NAMESPACE })
  setTopic!: (payload: string | string[]) => void;

  private isNavOpen = false
  private scrollPosition = 0
  private lastScrollPos = 0
  // private searchText = null

  toggleMobileMenu (val?: boolean) {
    this.isNavOpen = val !== undefined ? val : !this.isNavOpen
    this.scrollPosition = window.pageYOffset
    const html = document.querySelector('html')
    const htmlElement = html as HTMLHtmlElement

    if (this.isNavOpen) {
      this.lastScrollPos = this.scrollPosition
      htmlElement.style.overflow = 'hidden'
      htmlElement.style.position = 'fixed'
      htmlElement.style.top = `-${this.scrollPosition}px`
      htmlElement.style.width = '100%'
    } else {
      htmlElement.style.removeProperty('overflow')
      htmlElement.style.removeProperty('position')
      htmlElement.style.removeProperty('top')
      htmlElement.style.removeProperty('width')
      window.scrollTo(0, this.lastScrollPos)
    }
  }

  @Watch('$route.fullPath')
  onRouteChange () {
    this.toggleMobileMenu(false)
  }

  beforeUnmount () {
    this.toggleMobileMenu(false)
  }
}
