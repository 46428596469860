import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_renderer = _resolveComponent("renderer")!

  return (_ctx.specialPage)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.specialPage), { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_renderer, _normalizeProps(_guardReactiveProps(_ctx.content)), null, 16)
      ]))
}