"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminContentListDataToJSONTyped = exports.AdminContentListDataToJSON = exports.AdminContentListDataFromJSONTyped = exports.AdminContentListDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdminContentListDataFromJSON(json) {
    return AdminContentListDataFromJSONTyped(json, false);
}
exports.AdminContentListDataFromJSON = AdminContentListDataFromJSON;
function AdminContentListDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(_1.MinimalContentDataFromJSON)),
        'payload': !(0, runtime_1.exists)(json, 'payload') ? undefined : (0, _1.AdminContentListDataPayloadFromJSON)(json['payload']),
        'totalCount': !(0, runtime_1.exists)(json, 'totalCount') ? undefined : json['totalCount'],
    };
}
exports.AdminContentListDataFromJSONTyped = AdminContentListDataFromJSONTyped;
function AdminContentListDataToJSON(value) {
    return AdminContentListDataToJSONTyped(value, false);
}
exports.AdminContentListDataToJSON = AdminContentListDataToJSON;
function AdminContentListDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'items': value.items === undefined ? undefined : (value.items.map(_1.MinimalContentDataToJSON)),
        'payload': (0, _1.AdminContentListDataPayloadToJSON)(value.payload),
        'totalCount': value.totalCount,
    };
}
exports.AdminContentListDataToJSONTyped = AdminContentListDataToJSONTyped;
