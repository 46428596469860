import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GalleryPage = _resolveComponent("GalleryPage")!
  const _component_ArticlePage = _resolveComponent("ArticlePage")!

  return (_ctx.isGallery)
    ? (_openBlock(), _createBlock(_component_GalleryPage, { key: 0 }))
    : (_openBlock(), _createBlock(_component_ArticlePage, {
        key: 1,
        showRecommendedVideos: _ctx.recommendedVideosEnable
      }, null, 8, ["showRecommendedVideos"]))
}