import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementBlock as _createElementBlock, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "contact-form-box__header" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "contact-form-box__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirm_edit_modal = _resolveComponent("confirm-edit-modal")!
  const _component_check = _resolveComponent("check")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_confirm_edit_modal, {
          key: 0,
          onConfirmEdit: _ctx.confirmEdit
        }, null, 8, ["onConfirmEdit"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["contact-form-box", { 'contact-form-box--selected': _ctx.step === _ctx.selectedStep }]),
      id: `contact-form-box-${_ctx.step}`
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", {
            class: _normalizeClass(["contact-form-box__header__icon", { 'contact-form-box__header__icon--disabled': _ctx.step !== _ctx.selectedStep, 'contact-form-box__header__icon--finished': _ctx.step < _ctx.selectedStep }])
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.step), 1),
            _createVNode(_component_check)
          ], 2),
          _createElementVNode("h6", null, _toDisplayString(_ctx.title), 1)
        ]),
        (_ctx.step < _ctx.selectedStep)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              disabled: _ctx.disabled,
              class: "button-show-all contact-form-box__button contact-form-box__button--edit",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)), ["prevent"]))
            }, " Módosítom ", 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "formContent"),
        _renderSlot(_ctx.$slots, "actionButton")
      ])
    ], 10, _hoisted_1)
  ], 64))
}