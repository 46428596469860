
import { ContentType } from '@rtl/api'
import { defineAsyncComponent } from 'vue'
import { mixins, Options } from 'vue-property-decorator'

import { FetchContentItemMixin } from '../mixins'

@Options({
  name: 'ContentPage',
  components: {
    ArticlePage: defineAsyncComponent(() => import(/* webpackChunkName: "article-page" */ './ArticlePage.vue')),
    GalleryPage: defineAsyncComponent(() => import(/* webpackChunkName: "gallery-page" */ './GalleryPage.vue'))
  }
})
export default class ContentPage extends mixins(FetchContentItemMixin) {
  get isGallery () {
    return this.content?.contentType ? this.content?.contentType.toString() === ContentType.Gallery : false
  }

  get recommendedVideosEnable () {
    return this.content?.recommendedVideosEnable
  }
}
