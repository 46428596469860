
import { Options, Prop, Vue } from 'vue-property-decorator'

import { fixProtocoll } from '../../utils'

@Options({
  name: 'Image'
})
export default class Image extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  readonly imageUrl!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly mindTheHeight!: boolean

  imgElement: HTMLImageElement | null = null

  srcWidthSizes = [320, 640, 960, 1440]
  srcHeightSizes = [180, 360, 540, 600]

  get fullImgSrc () {
    if (this.imageUrl) {
      let url = fixProtocoll(this.imageUrl) as string
      const queryIndex = url.indexOf('?')
      if (queryIndex >= 0) {
        const params = new URLSearchParams(url.slice(queryIndex + 1))
        if (params.has('size')) {
          params.delete('size')
        }
        url = url.slice(0, queryIndex + 1) + params.toString()
      }
      return url.replace(/\?$/, '')
    }
  }

  get src1x () {
    if (this.fullImgSrc) {
      return this.generateFinalImgSrc(1)
    }
  }

  get src2x () {
    if (this.fullImgSrc) {
      return this.generateFinalImgSrc(2)
    }
  }

  get srcset () {
    if (this.fullImgSrc) {
      return `${this.src1x} 1x, ${this.src2x} 2x`
    }
  }

  get placeholder () {
    return require('../../assets/img/rtl_placeholder_image.svg')
  }

  generateFinalImgSrc (density: number) {
    if (this.fullImgSrc) {
      let param = ''

      const width = this.getWidth(density) || this.srcWidthSizes[0]
      param += width ? `w${width}` : ''

      if (this.mindTheHeight) {
        const height = this.getHeight(density) || this.srcHeightSizes[0]
        param += height ? (width ? `xh${height}` : `h${height}`) : ''
      }

      return this.fullImgSrc + (param.length ? `${this.fullImgSrc.includes('?') ? '&' : '?'}size=${param}` : '')
    }
  }

  getWidth (density = 1) {
    return this.getSize(density)
  }

  getHeight (density = 1) {
    return this.getSize(density, true)
  }

  getSize (density = 1, isHeight = false) {
    const target = this.imgElement?.parentElement

    if (target) {
      const { width, height } = target.getBoundingClientRect()
      return isHeight
        ? this.findNearestSize(this.srcHeightSizes, height * density)
        : this.findNearestSize(this.srcWidthSizes, width * density)
    }
  }

  findNearestSize (sizes: Array<number>, value: number) {
    return sizes.find(size => value <= size) || [...sizes].pop()
  }

  updated () {
    this.imgElement = this.$el
  }

  mounted () {
    this.imgElement = this.$el
  }
}
