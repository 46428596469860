import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "casting-block"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "casting-block__hero" }
const _hoisted_4 = { class: "casting-block__hero-image-wrapper" }
const _hoisted_5 = { class: "casting-block__hero-title" }
const _hoisted_6 = { class: "casting-block__form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_casting_form = _resolveComponent("casting-form")!

  return (_ctx.form)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Image, {
                class: "casting-block__hero-image",
                "image-url": _ctx.form.imageUrls.imageUrl,
                alt: _ctx.form.title
              }, null, 8, ["image-url", "alt"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(_ctx.form.title), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_casting_form, { form: _ctx.form }, null, 8, ["form"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}