

import { SiteData } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import TvShowCard from './TvShowCard.vue'

@Options({
  name: 'TvShowCardList',
  components: {
    TvShowCard
  }
})
export default class TvShowCardList extends Vue {
  @Prop({ type: Array, default: [] })
  readonly tvShows!: SiteData[]

  @Prop({ type: Number, default: 4 })
  readonly cols!: number

  @Prop({ type: Boolean, default: false })
  readonly showSlider!: boolean
}
