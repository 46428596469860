
import { SiteData } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import TvShowCardImg from './TvShowCardImg.vue'

@Options({
  name: 'TvShowCardGrid',
  components: {
    TvShowCardImg
  }
})
export default class TvShowCardGrid extends Vue {
  @Prop({ type: Array, default: [] })
  readonly tvShows!: SiteData[]
}
