import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "celebrity-hero" }
const _hoisted_2 = { class: "celebrity-hero__row" }
const _hoisted_3 = { class: "celebrity-hero__col celebrity-hero__col--left" }
const _hoisted_4 = { class: "celebrity-hero__grid" }
const _hoisted_5 = { class: "celebrity-hero__grid-image" }
const _hoisted_6 = { class: "celebrity-hero__social" }
const _hoisted_7 = { class: "celebrity-hero__grid-media" }
const _hoisted_8 = { class: "celebrity-hero__grid-info" }
const _hoisted_9 = {
  key: 0,
  class: "celebrity-hero__nav"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "celebrity-hero__nav-contents" }
const _hoisted_12 = ["data-nav-content-id", "innerHTML"]
const _hoisted_13 = { class: "celebrity-hero__col celebrity-hero__col--right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_facebook = _resolveComponent("facebook")!
  const _component_NavLink = _resolveComponent("NavLink")!
  const _component_youtube = _resolveComponent("youtube")!
  const _component_instagram = _resolveComponent("instagram")!
  const _component_twitter = _resolveComponent("twitter")!
  const _component_video_player = _resolveComponent("video-player")!
  const _component_content_card_img = _resolveComponent("content-card-img")!
  const _component_content_card_list = _resolveComponent("content-card-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Image, {
              "image-url": _ctx.celebrity.leadImageUrls?.leadImageUrl,
              alt: _ctx.celebrity.title,
              "mind-the-height": true
            }, null, 8, ["image-url", "alt"]),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.facebookUrl)
                ? (_openBlock(), _createBlock(_component_NavLink, {
                    key: 0,
                    to: _ctx.facebookUrl,
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_facebook)
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true),
              (_ctx.youtubeUrl)
                ? (_openBlock(), _createBlock(_component_NavLink, {
                    key: 1,
                    to: _ctx.youtubeUrl,
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_youtube)
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true),
              (_ctx.instagramUrl)
                ? (_openBlock(), _createBlock(_component_NavLink, {
                    key: 2,
                    to: _ctx.instagramUrl,
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_instagram)
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true),
              (_ctx.twitterUrl)
                ? (_openBlock(), _createBlock(_component_NavLink, {
                    key: 3,
                    to: _ctx.twitterUrl,
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_twitter)
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.isFirstContentEmbed && _ctx.firstFeaturedContent.params.embedVideoUrl)
              ? (_openBlock(), _createBlock(_component_video_player, {
                  key: 0,
                  videoSrc: _ctx.firstFeaturedContent.params.embedVideoUrl,
                  title: _ctx.firstFeaturedContent.title
                }, null, 8, ["videoSrc", "title"]))
              : (_openBlock(), _createBlock(_component_content_card_img, {
                  key: 1,
                  content: _ctx.firstFeaturedContent,
                  "show-lead": false
                }, null, 8, ["content"]))
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.tabs.length > 1)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: i,
                      class: "celebrity-hero__nav-item"
                    }, [
                      _createElementVNode("a", {
                        class: _normalizeClass(["celebrity-hero__nav-link", { 'celebrity-hero__nav-link--active': i === 0 }]),
                        href: "",
                        onClick: _withModifiers(($event: any) => (_ctx.switchTab($event, tab.id)), ["prevent"])
                      }, _toDisplayString(tab.tabLabel), 11, _hoisted_10)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: i,
                  class: _normalizeClass(["celebrity-hero__nav-content", { 'celebrity-hero__nav-content--active': i === 0 }]),
                  "data-nav-content-id": tab.id,
                  innerHTML: _ctx.getTabContent(tab)
                }, null, 10, _hoisted_12))
              }), 128))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_content_card_list, {
          contents: _ctx.featuredContents,
          "show-lead": false,
          "show-tag": true,
          size: 'shrink'
        }, null, 8, ["contents"])
      ])
    ])
  ]))
}