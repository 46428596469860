import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-search-faq-id"]
const _hoisted_2 = { ref: "accordion" }
const _hoisted_3 = { "data-search-faq-question": "" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "faq-accordion",
    "data-search-faq-id": _ctx.searchId
  }, [
    _createElementVNode("details", _hoisted_2, [
      _createElementVNode("summary", _hoisted_3, _toDisplayString(_ctx.qa.question), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.qa.answer, (value, key) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "faq-accordion__answer",
          key: key
        }, [
          value
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: value,
                "data-search-faq-answer": ""
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 512)
  ], 8, _hoisted_1))
}