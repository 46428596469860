"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchModeToJSONTyped = exports.SearchModeToJSON = exports.SearchModeFromJSONTyped = exports.SearchModeFromJSON = exports.SearchMode = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var SearchMode;
(function (SearchMode) {
    SearchMode["Global"] = "global";
    SearchMode["SiteOnly"] = "site-only";
})(SearchMode = exports.SearchMode || (exports.SearchMode = {}));
function SearchModeFromJSON(json) {
    return SearchModeFromJSONTyped(json, false);
}
exports.SearchModeFromJSON = SearchModeFromJSON;
function SearchModeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SearchModeFromJSONTyped = SearchModeFromJSONTyped;
function SearchModeToJSON(value) {
    return SearchModeToJSONTyped(value, false);
}
exports.SearchModeToJSON = SearchModeToJSON;
function SearchModeToJSONTyped(value, ignoreDiscriminator = false) {
    return value;
}
exports.SearchModeToJSONTyped = SearchModeToJSONTyped;
