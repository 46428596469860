
import {
  CastingFormData,
  CastingFormResult,
  PostCastingFormRequest
} from '@rtl/api'
import { mixins, Options, Prop, Ref } from 'vue-property-decorator'

import { FORM_GETTER, FORM_NAMESPACE } from '../../../store'
import { GetPageMixin } from '../../mixins'

@Options({
  name: 'CastingForm',
  components: {}
})
export default class CastingForm extends mixins(GetPageMixin) {
  @Prop({ type: Object, required: true })
  readonly form!: CastingFormData

  @Ref('sendButton')
  sendButton!: HTMLButtonElement

  @Ref('formElement')
  formElement!: HTMLFormElement

  formData: { [key: string]: string | number | [] | object } = {}
  formErrors: Array<string> = []
  formSent = false
  selectedFile = null

  mounted () {
    if (this.form.fields) {
      for (const field of this.form.fields) {
        const params = (field.attributes?.parameters
          ? ((field.attributes.parameters as never) as { [key: string]: string | number | [] })
          : undefined) || undefined

        if (field.type === 'checkbox' && this.isMultiCheckbox(params)) {
          this.formData[field.name] = []
        } else if (field.type === 'select' && params && 'start' in params) {
          this.formData[field.name] = params.start
        } else if (field.type === 'textarea' && params && 'value' in params) {
          this.formData[field.name] = params.value
        } else {
          this.formData[field.name] = field.defaultValue
        }
      }
    }
  }

  fileInputOnChange (e: any, fieldName: string) { // eslint-disable-line @typescript-eslint/no-explicit-any
    const files = e.target?.files || e.dataTransfer?.files
    this.selectedFile = files[0].name
    this.formData[fieldName] = files && files.length ? files : undefined
  }

  // TODO: Refact TS and file inputs
  postForm () {
    const api = this.$store.getters[`${FORM_NAMESPACE}/${FORM_GETTER.API}`]
    const data = Object.assign({}, this.formData)
    const request: PostCastingFormRequest & {[key: string]: unknown} = {
      formCode: this.form.formCode as string,
      data: ''
    }

    // Fix the upload binary fields for api (max 6 file)
    for (let i = 0; i < 6; i++) {
      const key = `upload${i > 0 ? i : ''}`
      if (data[key]) {
        const fileList = (data[key] as FileList)

        if (fileList.length) {
          request[key] = fileList.item(0)
          data[key] = fileList.item(0)?.name || 1
        }
      }
    }

    request.data = JSON.stringify(data)

    this.sendButton.disabled = true
    this.formErrors = []

    api.postCastingForm(request)
      .then((result: CastingFormResult) => {
        this.formSent = true

        if (this.$gtm && this.$gtm.enabled() && this.page?.trackingVariables?.trackingEnabled) {
          this.$gtm.trackEvent({
            event: 'content-view',
            ...this.page?.trackingVariables,
            status: '200'
          })
        }
        return result
      })
      .catch((reason: unknown) => {
        if (reason instanceof Response && reason.status === 400) {
          reason.json().then((result: CastingFormResult) => {
            const params = result.error?.params

            if (params) {
              Object.keys(params).forEach((key: string) => {
                for (const error of params[key]) {
                  this.formErrors.push(error as string)
                }
              })
            } else {
              this.formErrors.push('Nem sikerült a jelentkezés elküldése, kérjük próbálja meg újra.')
            }

            if (this.formErrors.length) {
              window.scrollTo({ top: this.formElement.offsetTop - 100, behavior: 'smooth' })
            }
          })
        } else {
          throw reason
        }
      })
      .finally(() => {
        this.sendButton.disabled = false
      })
  }

  getSelectOptions (parameters: { start: number, end: number } | { [key: string]: string }): { key: string, value: string }[] {
    const options: { key: string, value: string }[] = []

    if ('start' in parameters) {
      const start = parameters.start as number
      const end = parameters.end as number

      for (let i = start; i <= end; i++) {
        options.push({ key: `${i}`, value: `${i}` })
      }
    } else {
      for (const key of Object.keys(parameters)) {
        options.push({ key: key, value: parameters[key] })
      }
    }

    return options
  }

  isMultiCheckbox (parameters: unknown): boolean {
    return Array.isArray(parameters)
  }
}
