
import { ContentData, FilterOptions, MinimalContentData } from '@rtl/api'
import { mixins, Options, Prop, Watch } from 'vue-property-decorator'

import { CONTENT_ACTION, CONTENT_NAMESPACE, ContentListDecoratedPayload, ContentListPayload } from '../../store'
import { generateMetaInfo } from '../../utils'
import ContentCardLeftImg from '../components/content/ContentCardLeftImg.vue'
import Image from '../components/Image.vue'
import Loader from '../components/Loader.vue'
import NavLink from '../components/NavLink.vue'
import Pagination from '../components/Pagination.vue'
import PageLayout from '../layouts/PageLayout.vue'
import { FetchContentListMixin, GetPageMixin } from '../mixins'
import BannerWidget from '../widgets/BannerWidget.vue'

@Options({
  name: 'ContentListPage',
  components: {
    PageLayout,
    ContentCardLeftImg,
    BannerWidget,
    Image,
    NavLink,
    Pagination,
    Loader
  },
  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})
export default class ContentListPage extends mixins(GetPageMixin, FetchContentListMixin) {
  @Prop({
    type: String,
    default: ''
  })
  readonly title!: string

  @Prop({
    type: Object,
    default: () => ({})
  })
  readonly payload!: Partial<ContentListDecoratedPayload>

  @Prop({
    type: Boolean,
    default: false
  })
  readonly showHighlight!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showFilters!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly multipleFilters!: boolean

  paginate = 1
  length = 50
  selectedFilters: number[] = []

  get highlightedContent (): ContentData | MinimalContentData | null {
    return this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.LIST], this.highlightedContentListPayload)?.data.items?.[0] || null
  }

  get highlightedContentListPayload (): ContentListPayload {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { decorators, ...payload } = this.payload

    return {
      folderIds: !payload?.tags && this.page?.site?.folderId ? [this.page?.site?.folderId] : undefined,
      priority: 5,
      ...payload,
      limit: 1
    }
  }

  get contentListPayload (): ContentListPayload {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { decorators, ...payload } = this.payload

    return {
      folderIds: !payload?.tags && this.page?.site?.folderId ? [this.page?.site?.folderId] : undefined,
      excludedContentIds: this.highlightedContent ? [this.highlightedContent?.id] : undefined,
      ...payload,
      limit: this.length,
      offset: (this.paginate - 1) * this.length,
      totalCount: 1,
      filters: this.showFilters ? 1 : undefined,
      filterTags: this.selectedFilters.length ? this.selectedFilters : undefined
    }
  }

  async fetchContentList () {
    if (this.showHighlight && this.paginate === 1) {
      await this.storeFetchContentList(this.highlightedContentListPayload)
    }
    return this.storeFetchContentList(this.contentListPayload)
  }

  get filterOptions (): Array<FilterOptions> {
    return this.contentList?.filterOptions || []
  }

  isFilterSelected (filterValue: string): boolean {
    return this.selectedFilters.length > 0 && this.selectedFilters.includes(parseInt(filterValue))
  }

  selectFilter (filterValue: string | null) {
    let selectedFilters = [...this.selectedFilters]

    if (filterValue === null) {
      selectedFilters = []
    } else if (!this.multipleFilters) {
      selectedFilters = [parseInt(filterValue)]
    } else {
      if (selectedFilters.includes(parseInt(filterValue))) {
        selectedFilters.splice(this.selectedFilters.indexOf(parseInt(filterValue)), 1)
      } else {
        selectedFilters.push(parseInt(filterValue))
      }
    }

    if (selectedFilters.length === this.filterOptions.length) {
      selectedFilters = []
    }

    const routePath = this.$route.path
    const filtersString = selectedFilters.join(',')
    this.$router.push({ path: routePath, query: { szurok: filtersString.length ? filtersString : undefined } })
  }

  @Watch('$route.query.oldal', { immediate: true })
  updatePageFromRoute () {
    this.paginate = 'oldal' in this.$route.query
      ? Math.max(1, parseInt(this.$route.query.oldal as string))
      : 1
  }

  @Watch('$route.query.szurok', { immediate: true })
  updateSelectedFilterTagsFromRoute () {
    this.selectedFilters = []

    if ('szurok' in this.$route.query) {
      const filterIds = ((this.$route.query.szurok as string) || '').split(',')
      filterIds.forEach((id: string) => { this.selectedFilters.push(parseInt(id)) })
    }
  }
}
