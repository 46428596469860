
import { Inject, Options, Prop, Provide, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { PAGE_ACTION, PAGE_NAMESPACE, PagePayload, PageResult } from '../../store'
import { AdStore, htmlSerializer } from '../../utils'
import Renderer from './Renderer.vue'

@Options({
  name: 'ArticleIndexWidget',
  components: {
    Renderer
  }
})
export default class ArticleIndexWidget extends Vue {
  @Action(PAGE_ACTION.ITEM, { namespace: PAGE_NAMESPACE })
  storeFetchPage!: (payload: PagePayload) => Promise<PageResult>

  @Inject({ from: 'adStore' })
  readonly rootAdStore!: AdStore

  @Provide({ reactive: true })
  adStore: AdStore = {
    adoKeys: undefined,
    initialized: true,
    master: undefined,
    slaves: []
  }

  @Prop({
    type: String,
    default: '/'
  })
  readonly pageUrl!: string

  get page (): PageResult['data'] | null {
    return this.$store.getters['$cache/result']([PAGE_NAMESPACE, PAGE_ACTION.ITEM], this.pagePayload)?.data || null
  }

  get content () {
    return {
      template: htmlSerializer(this.page?.skeleton)
    }
  }

  get pagePayload (): PagePayload {
    const url = this.pageUrl.replace(/^\//g, '')
    return { url }
  }

  @Watch('pagePayload', { deep: true })
  async fetchPage () {
    return this.storeFetchPage(this.pagePayload)
  }

  @Watch('page.adoKeys', { immediate: true, deep: true })
  refreshAdoKeys () {
    this.adStore.adoKeys = this.page?.adoKeys
  }

  @Watch('rootAdStore.media', { immediate: true })
  refreshAdMedia () {
    this.adStore.media = this.rootAdStore.media
  }
}
