
import Headroom from 'headroom.js'
import { mixins, Options, Ref } from 'vue-property-decorator'

import FacebookFull from '../components/icons/FacebookFull.vue'
import { GetPageMixin } from '../mixins'
import { isMobile, Storage } from './../../utils'
import Close from './icons/Close.vue'

@Options({
  name: 'FacebookModal',
  components: {
    FacebookFull,
    Close
  }
})

export default class FacebookModal extends mixins(GetPageMixin) {
  showing = false
  hasShown = false
  key = 'fb-modal-expire'
  lifetime = 7 * 24 * 60 * 60 * 1000 // 7 days

  @Ref('facebookModal')
  readonly modal!: HTMLElement

  private headerOptions = {
    offset: 100,
    tolerance: {
      up: 55,
      down: 0
    }
  }

  get version () {
    if (window && window.facebookModalVersion) {
      switch (window.facebookModalVersion) {
        case 'a':
          return 'a'
        case 'b':
          return 'b'
        default:
          return this.getRandomVersion()
      }
    } else {
      return null
    }
  }

  get fbPluginSrc () {
    return `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frtlponthu&tabs=timeline&width=${this.iframeWidth}&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId`
  }

  get iframeWidth () {
    return isMobile() ? 280 : 314
  }

  getRandomVersion () {
    if (window && window.gaGlobal) {
      const gaId = window.gaGlobal.vid
      const isOdd = (parseInt(gaId[gaId.length - 1])) % 2 === 1
      return isOdd ? 'a' : 'b'
    } else {
      return (Math.random() < 0.5) ? 'a' : 'b'
    }
  }

  triggerMobile () {
    if (isMobile()) {
      const headroom = new Headroom(this.modal, {
        ...this.headerOptions,
        onPin: () => {
          if (!this.showing && !this.hasShown && this.version && this.shouldShowModal()) {
            this.showing = true
            this.hasShown = true
            this.measure('show', this.version)
            this.setExpire()
          }
        }
      })
      headroom.init()
    }
  }

  triggerDesktop (event: MouseEvent) {
    if (!isMobile() && !this.showing && !this.hasShown && this.version && this.shouldShowModal()) {
      if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
        this.showing = true
        this.hasShown = true
        this.measure('show', this.version)
        this.setExpire()
      }
    }
  }

  click (version?: string) {
    this.showing = false
    if (version) {
      this.measure('click', version)
    }
  }

  close (version: string) {
    this.showing = false
    this.measure('close', version)
  }

  measure (action: string, version: string | null) {
    if (this.$gtm && this.$gtm.enabled()) {
      this.$gtm.trackEvent({
        event: 'interaction',
        category: 'facebook-modal',
        label: version,
        action
      })
    }
  }

  shouldShowModal (): boolean {
    const expire = Storage.get(this.key)
    if (expire) {
      return Date.now() > expire + this.lifetime
    } else {
      return true
    }
  }

  setExpire () {
    Storage.set(this.key, Date.now())
  }

  mounted () {
    this.triggerMobile()
    document.addEventListener('mouseout', this.triggerDesktop)
  }

  unmounted () {
    document.removeEventListener('mouseout', this.triggerDesktop)
  }
}
