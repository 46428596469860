import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "news-card news-card--full-image news-card--full-image--medium" }
const _hoisted_2 = { class: "news-card__image-wrapper" }
const _hoisted_3 = { class: "news-card__inner-container" }
const _hoisted_4 = { class: "news-card__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_nav_link = _resolveComponent("nav-link")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createVNode(_component_nav_link, { to: _ctx.url }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Image, {
              "image-url": _ctx.imageUrl,
              alt: _ctx.title,
              class: "news-card__image"
            }, null, 8, ["image-url", "alt"]),
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}