
import { MenuItem } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import Facebook from '../icons/Facebook.vue'
import Instagram from '../icons/Instagram.vue'
import Twitter from '../icons/Twitter.vue'
import Image from '../Image.vue'
import NavLink from '../NavLink.vue'
import SocialMediaLinks from '../SocialMediaLinks.vue'
import TvShowHeroNav from '../tv-show/TvShowHeroNav.vue'
import WidgetBlock from '../widget/WidgetBlock.vue'

@Options({
  name: 'TvShowHeroWidgetBlock',
  components: {
    Image,
    NavLink,
    WidgetBlock,
    SocialMediaLinks,
    Facebook,
    Instagram,
    Twitter,
    TvShowHeroNav
  }
})
export default class TvShowHeroWidgetBlock extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string

  @Prop({ type: String, required: true })
  readonly logoUrl!: string

  @Prop({ type: String, required: true })
  readonly coverImgUrl!: string

  @Prop({ type: String })
  readonly info!: string | undefined

  @Prop({ type: String })
  readonly ctaBtnLabel!: string | undefined

  @Prop({ type: String })
  readonly ctaBtnUrl!: string | undefined

  @Prop({ type: String })
  readonly extraBtnLabel!: string | undefined

  @Prop({ type: String })
  readonly extraBtnUrl!: string | undefined

  @Prop({ type: Array })
  readonly menu!: MenuItem[] | undefined
}
