
import { defineAsyncComponent } from 'vue'
import { mixins, Options, Watch } from 'vue-property-decorator'

import { findElement, generateMetaInfo, getInnerHtml, htmlSerializer, parseHtmlString } from '../../utils'
import { GetPageMixin } from '../mixins'
import Renderer from '../widgets/Renderer.vue'
import StaticPage from './StaticPage.vue'

@Options({
  name: 'DynamicPage',
  components: {
    Renderer
  },
  metaInfo () {
    return !this.specialPage ? generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store) : {}
  }
})
export default class DynamicPage extends mixins(GetPageMixin) {
  get specialPage () {
    const type = this.page?.responseType as unknown as string | null
    switch (type) {
      case 'casting':
        return defineAsyncComponent(() => import(/* webpackChunkName: "casting-form-page" */ './CastingFormPage.vue'))
      case 'celeb':
      case 'character':
        return defineAsyncComponent(() => import(/* webpackChunkName: "celebrity-page" */ './CelebrityPage.vue'))
      case 'news':
        return StaticPage
    }
    return null
  }

  @Watch('page', { immediate: true })
  updateLayout () {
    if (this.$root) {
      (this.$root as any).layout = this.page?.layoutFile || null
    }
  }

  get content () {
    const parse = function (content: string): object | undefined {
      const module = { exports: { default: undefined } }
      const context = {
        module,
        exports: module.exports,
        require () {}
      }

      try {
        // eslint-disable-next-line no-new-func
        Function(...Object.keys(context), content).call(module.exports, ...Object.values(context))
      } catch (err) {
        console.error(err)
      }

      return module.exports.default
    }

    if (this.page?.html) {
      const html = this.page?.html
      const nodes = parseHtmlString(html)
      const script = findElement(nodes, 'script')
      const template = findElement(nodes, 'template')
      return {
        template: '<metainfo />' + getInnerHtml(template),
        script: parse(getInnerHtml(script))
      }
    }

    return {
      template: htmlSerializer(this.page?.skeleton)
    }
  }
}
