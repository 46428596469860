"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuizQuestionToJSONTyped = exports.QuizQuestionToJSON = exports.QuizQuestionFromJSONTyped = exports.QuizQuestionFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function QuizQuestionFromJSON(json) {
    return QuizQuestionFromJSONTyped(json, false);
}
exports.QuizQuestionFromJSON = QuizQuestionFromJSON;
function QuizQuestionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'quizId': !(0, runtime_1.exists)(json, 'quizId') ? undefined : json['quizId'],
        'question': !(0, runtime_1.exists)(json, 'question') ? undefined : json['question'],
        'lead': !(0, runtime_1.exists)(json, 'lead') ? undefined : json['lead'],
        'body': !(0, runtime_1.exists)(json, 'body') ? undefined : json['body'],
        'sequenceNr': !(0, runtime_1.exists)(json, 'sequenceNr') ? undefined : json['sequenceNr'],
        'imageId': !(0, runtime_1.exists)(json, 'imageId') ? undefined : json['imageId'],
        'archive': !(0, runtime_1.exists)(json, 'archive') ? undefined : json['archive'],
        'imageUrls': !(0, runtime_1.exists)(json, 'imageUrls') ? undefined : (0, _1.ImageUrlsFromJSON)(json['imageUrls']),
        'answers': !(0, runtime_1.exists)(json, 'answers') ? undefined : (json['answers'].map(_1.QuizAnswerFromJSON)),
    };
}
exports.QuizQuestionFromJSONTyped = QuizQuestionFromJSONTyped;
function QuizQuestionToJSON(value) {
    return QuizQuestionToJSONTyped(value, false);
}
exports.QuizQuestionToJSON = QuizQuestionToJSON;
function QuizQuestionToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'quizId': value.quizId,
        'question': value.question,
        'lead': value.lead,
        'body': value.body,
        'sequenceNr': value.sequenceNr,
        'imageId': value.imageId,
        'archive': value.archive,
        'imageUrls': (0, _1.ImageUrlsToJSON)(value.imageUrls),
        'answers': value.answers === undefined ? undefined : (value.answers.map(_1.QuizAnswerToJSON)),
    };
}
exports.QuizQuestionToJSONTyped = QuizQuestionToJSONTyped;
