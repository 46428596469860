
import { MinimalContentData } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import Image from '../components/Image.vue'
import Tag from '../components/Tag.vue'
import WidgetBlock from '../components/widget/WidgetBlock.vue'
import ArticleIndexWidget from '../widgets/ArticleIndexWidget.vue'
import RecommendedVideoWidget from '../widgets/RecommendedVideoWidget.vue'
import RelatedContentWidget from '../widgets/RelatedContentWidget.vue'
import StaticPage from './StaticPage.vue'

@Options({
  name: 'ArticlePage',
  components: {
    StaticPage,
    WidgetBlock,
    ArticleIndexWidget,
    RelatedContentWidget,
    Tag,
    Image,
    RecommendedVideoWidget
  }
})
export default class ArticlePage extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  readonly showHeadingTag!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showAuthors!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showRelatedContent!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showIndexPage!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly showRecommendedVideos!: boolean

  isLive (content: MinimalContentData): boolean {
    return content.params?.isLiveFeed === 1
  }

  onChangeRecommendationVisibility (visible: boolean) {
    if (visible) {
      (this.$refs.recommendation as ArticleIndexWidget)?.fetchPage()
    }
  }
}
