import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "casting-group"
}
const _hoisted_2 = {
  key: 0,
  class: "casting-group__divider"
}
const _hoisted_3 = {
  key: 1,
  class: "casting-group__title"
}
const _hoisted_4 = { class: "casting-group__forms" }
const _hoisted_5 = { class: "casting-group__row" }
const _hoisted_6 = { class: "casting-group-form__image-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_nav_link = _resolveComponent("nav-link")!

  return (_ctx.items?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (form, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: _normalizeClass(["casting-group__col", {
            'casting-group__col--3': _ctx.items.length < 4,
            'casting-group__col--4': _ctx.items.length >= 4,
          }])
              }, [
                _createVNode(_component_nav_link, {
                  to: form.url,
                  class: "casting-group-form"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_Image, {
                        class: "casting-group-form__image",
                        "image-url": form.imageUrls.imageUrl169,
                        alt: form.title,
                        loading: "lazy"
                      }, null, 8, ["image-url", "alt"])
                    ]),
                    _createElementVNode("h2", {
                      class: _normalizeClass(["casting-group-form__title", {
                'casting-group-form__title--small': _ctx.items.length >= 4
              }])
                    }, _toDisplayString(form.title), 3)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ], 2))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}