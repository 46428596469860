
import { Options, Vue } from 'vue-property-decorator'

import Close from './../icons/Close.vue'

@Options({
  name: 'ConfirmEditModal',
  components: {
    Close
  }
})
export default class ConfirmEditModal extends Vue {
  private lastScrollPos = 0

  cancel () {
    this.$emit('confirmEdit', false)
  }

  confirm () {
    this.$emit('confirmEdit', true)
  }

  mounted () {
    const htmlElement = document.querySelector('html') as HTMLHtmlElement
    this.lastScrollPos = window.pageYOffset
    htmlElement.style.overflow = 'hidden'
    htmlElement.style.position = 'fixed'
    htmlElement.style.top = `-${this.lastScrollPos}px`
    htmlElement.style.width = '100%'
  }

  unmounted () {
    const htmlElement = document.querySelector('html') as HTMLHtmlElement
    htmlElement.style.removeProperty('overflow')
    htmlElement.style.removeProperty('position')
    htmlElement.style.removeProperty('width')
    htmlElement.style.removeProperty('top')
    window.scrollTo(0, this.lastScrollPos)
  }
}
