"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetaTagsToJSONTyped = exports.MetaTagsToJSON = exports.MetaTagsFromJSONTyped = exports.MetaTagsFromJSON = void 0;
const runtime_1 = require("../runtime");
function MetaTagsFromJSON(json) {
    return MetaTagsFromJSONTyped(json, false);
}
exports.MetaTagsFromJSON = MetaTagsFromJSON;
function MetaTagsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'keywords': !(0, runtime_1.exists)(json, 'keywords') ? undefined : json['keywords'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'oglocale': !(0, runtime_1.exists)(json, 'og:locale') ? undefined : json['og:locale'],
        'ogdescription': !(0, runtime_1.exists)(json, 'og:description') ? undefined : json['og:description'],
        'ogurl': !(0, runtime_1.exists)(json, 'og:url') ? undefined : json['og:url'],
        'ogtype': !(0, runtime_1.exists)(json, 'og:type') ? undefined : json['og:type'],
        'ogtitle': !(0, runtime_1.exists)(json, 'og:title') ? undefined : json['og:title'],
        'ogsiteProperty': !(0, runtime_1.exists)(json, 'og:site_property') ? undefined : json['og:site_property'],
        'ogimage': !(0, runtime_1.exists)(json, 'og:image') ? undefined : json['og:image'],
        'ogimagesecureUrl': !(0, runtime_1.exists)(json, 'og:image:secure_url') ? undefined : json['og:image:secure_url'],
        'robots': !(0, runtime_1.exists)(json, 'robots') ? undefined : json['robots'],
    };
}
exports.MetaTagsFromJSONTyped = MetaTagsFromJSONTyped;
function MetaTagsToJSON(value) {
    return MetaTagsToJSONTyped(value, false);
}
exports.MetaTagsToJSON = MetaTagsToJSON;
function MetaTagsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'keywords': value.keywords,
        'description': value.description,
        'title': value.title,
        'og:locale': value.oglocale,
        'og:description': value.ogdescription,
        'og:url': value.ogurl,
        'og:type': value.ogtype,
        'og:title': value.ogtitle,
        'og:site_property': value.ogsiteProperty,
        'og:image': value.ogimage,
        'og:image:secure_url': value.ogimagesecureUrl,
        'robots': value.robots,
    };
}
exports.MetaTagsToJSONTyped = MetaTagsToJSONTyped;
