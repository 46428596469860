
import { mixins, Options } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import { FAQ_GETTERS, FAQ_MUTATION, FAQ_NAMESPACE, FaqTopic } from '../../store'
import { generateMetaInfo } from '../../utils'
import { GetPageMixin } from '../mixins'
import FaqCard from './../components/faq/FaqCard.vue'
import FaqContact from './../components/faq/FaqContact.vue'
import FaqFooter from './../components/faq/FaqFooter.vue'
import FaqNav from './../components/faq/FaqNav.vue'
import FaqSerach from './../components/faq/FaqSearch.vue'

@Options({
  name: 'FaqPage',
  components: {
    FaqContact,
    FaqFooter,
    FaqNav,
    FaqSerach,
    FaqCard
  },

  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})

export default class FaqPage extends mixins(GetPageMixin) {
  @Getter(FAQ_GETTERS.GET_DATA, { namespace: FAQ_NAMESPACE })
  faqData!: Array<FaqTopic>

  @Mutation(FAQ_MUTATION.SET_QUESTION, { namespace: FAQ_NAMESPACE })
  setQuestion!: (payload: string | null) => void;

  mounted () {
    this.setQuestion(null)
  }

  // todo
  created () {
    (this.$root as any).layout = 'div'
  }
}
