
import { Options, Prop, Vue } from 'vue-property-decorator'

@Options({
  name: 'VoteBanner'
})
export default class VoteBanner extends Vue {
  @Prop({
    type: String,
    required: true
  })
  readonly link!: string

  @Prop({
    type: String,
    required: true
  })
  readonly title!: string

  @Prop({
    type: String
  })
  readonly logo: string | undefined

  @Prop({
    type: String
  })
  readonly background: string | undefined

  @Prop({
    type: String
  })
  readonly color: string | undefined
}
