
import { Options, Prop, Vue } from 'vue-property-decorator'

import FacebookFull from '../components/icons/FacebookFull.vue'

@Options({
  name: 'FacebookFollow',
  components: {
    FacebookFull
  }
})

export default class FacebookFollow extends Vue {
  @Prop({ type: String })
  readonly type!: string
}
