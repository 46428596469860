import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "31",
  height: "31",
  viewBox: "0 0 31 31",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M15.5 21C12.468 21 10 18.532 10 15.5C10 12.468 12.468 10 15.5 10C18.532 10 21 12.467 21 15.5C21 18.533 18.532 21 15.5 21ZM15.5 13C14.121 13 13 14.122 13 15.5C13 16.878 14.121 18 15.5 18C16.879 18 18 16.879 18 15.5C18 14.121 16.879 13 15.5 13Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M19.3895 31H11.6105L10.5183 26.6177L7.89818 25.0898L3.52862 26.23L0.0138999 20.0487L3.25955 16.9292L3.25558 14.1077L0 10.9981L3.54847 4.71518L7.88627 5.92313L10.5183 4.38429L11.6105 0H19.3895L20.4827 4.38429L23.1147 5.92313L27.4525 4.71518L31 10.9981L27.7454 14.1077L27.7414 16.9292L30.9861 20.0487L27.4714 26.23L23.1028 25.0898L20.4827 26.6177L19.3895 31ZM13.9358 28.01H17.0642L17.8973 24.6672L22.666 21.8866L26.0149 22.7606L27.247 20.5929L24.7609 18.2039L24.7688 12.824L27.2619 10.442L26.0079 8.22042L22.6997 9.14233L17.8973 6.33275L17.0642 2.98997H13.9358L13.1037 6.33275L8.2993 9.14233L4.99308 8.22042L3.73811 10.442L6.23217 12.824L6.24011 18.2039L3.753 20.5929L4.98514 22.7606L8.33504 21.8876L13.1037 24.6672L13.9358 28.01Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_3)
  ]))
}