
import { Options, Inject, Vue } from "vue-property-decorator";
import { LoginPage } from "@rtl/ui";
import { Container, Page } from "@/views/components";

@Options({
  components: {
    Container,
    LoginPage,
    Page,
  },
})
export default class VoteLoginPage extends Vue {
  @Inject()
  platform!: string;
}
