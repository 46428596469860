import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "news-card news-card--list" }
const _hoisted_2 = { class: "news-card__image-wrapper" }
const _hoisted_3 = { class: "news-card__right" }
const _hoisted_4 = { class: "news-card__date" }
const _hoisted_5 = { class: "news-card__title" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag = _resolveComponent("tag")!
  const _component_Image = _resolveComponent("Image")!
  const _component_nav_link = _resolveComponent("nav-link")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createVNode(_component_nav_link, { to: _ctx.url }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.tag)
            ? (_openBlock(), _createBlock(_component_tag, {
                key: 0,
                class: "news-card__tag",
                tag: _ctx.tag
              }, null, 8, ["tag"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Image, {
            "image-url": _ctx.leadImageUrl,
            alt: _ctx.leadImageUrl,
            class: "news-card__image"
          }, null, 8, ["image-url", "alt"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$d(_ctx.contentData.releaseDate, 'medium' )) + " - " + _toDisplayString(_ctx.hour), 1),
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.contentData.title), 1),
          _createElementVNode("div", {
            class: "news-card__excerpt",
            innerHTML: _ctx.contentData.lead
          }, null, 8, _hoisted_6)
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}