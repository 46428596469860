"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetDataParametersToJSONTyped = exports.WidgetDataParametersToJSON = exports.WidgetDataParametersFromJSONTyped = exports.WidgetDataParametersFromJSON = void 0;
const runtime_1 = require("../runtime");
function WidgetDataParametersFromJSON(json) {
    return WidgetDataParametersFromJSONTyped(json, false);
}
exports.WidgetDataParametersFromJSON = WidgetDataParametersFromJSON;
function WidgetDataParametersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'contentId': !(0, runtime_1.exists)(json, 'contentId') ? undefined : json['contentId'],
        'imgIndex': !(0, runtime_1.exists)(json, 'imgIndex') ? undefined : json['imgIndex'],
    };
}
exports.WidgetDataParametersFromJSONTyped = WidgetDataParametersFromJSONTyped;
function WidgetDataParametersToJSON(value) {
    return WidgetDataParametersToJSONTyped(value, false);
}
exports.WidgetDataParametersToJSON = WidgetDataParametersToJSON;
function WidgetDataParametersToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'contentId': value.contentId,
        'imgIndex': value.imgIndex,
    };
}
exports.WidgetDataParametersToJSONTyped = WidgetDataParametersToJSONTyped;
