
import { mixins, Options } from "vue-property-decorator";
import { VoteItemFetcher } from "@/mixins";
import { stripHtml } from "@rtl/ui";
import {
  Page,
  ResponsiveImage,
  RtlNav,
  TransitionPage,
  TransitionStyle,
  VoteHeader,
} from "@/views/components";

@Options({
  components: {
    Page,
    ResponsiveImage,
    RtlNav,
    TransitionPage,
    TransitionStyle,
    VoteHeader,
  },
  metaInfo() {
    if (this.vote) {
      return {
        title: this.vote.main.title + " - RTL.hu",
        ...(this.vote.main.text && {
          description: stripHtml(this.vote.main.text),
        }),
      };
    }
    return {};
  },
})
export default class VoteLayoutPage extends mixins(VoteItemFetcher) {}
