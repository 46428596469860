
import { mixins, Options } from 'vue-property-decorator'

import { detectDeviceType, generateMetaInfo } from '../../utils'
import AdultConfirm from '../components/AdultConfirm.vue'
import Gallery from '../components/gallery/Gallery.vue'
import { FetchContentItemMixin } from '../mixins'

@Options({
  name: 'GalleryPage',
  components: {
    AdultConfirm,
    Gallery
  },
  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})
export default class GalleryPage extends mixins(FetchContentItemMixin) {
  async mounted () {
    if (detectDeviceType() !== 'web') {
      // ios doesn't support screen.orientation
      await screen.orientation?.lock('portrait')
    }
  }

  unmounted () {
    screen.orientation?.unlock()
  }
}
