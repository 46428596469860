
import { CastingFormListDataFields, CastingFormListDataFieldsGroup } from '@rtl/api'
import { mixins, Options } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { CastingFormListResult, FORM_ACTION, FORM_NAMESPACE, FormListPayload } from '../../store'
import { generateMetaInfo } from '../../utils'
import CastingGroup from '../components/casting/CastingGroup.vue'
import { GetPageMixin } from '../mixins'

interface Group {
  title: string | undefined
  items: CastingFormListDataFields[] | undefined
  groupId: number | undefined
}

@Options({
  name: 'CastingListPage',
  components: {
    CastingGroup
  },
  metaInfo () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})
export default class CastingListPage extends mixins(GetPageMixin) {
  @Action(FORM_ACTION.LIST, { namespace: FORM_NAMESPACE })
  storeFetchFormList!: (payload: FormListPayload) => Promise<CastingFormListResult>

  get groups (): Group[] {
    const groups: Group[] = []
    const defaultGroup: Group = { title: undefined, items: [], groupId: undefined }
    const formItems = this.formList?.items || null

    if (formItems && formItems.length) {
      for (const form of formItems) {
        const groupId = (form as CastingFormListDataFieldsGroup).groupId
        if (groupId) {
          groups.push({
            groupId,
            items: undefined,
            title: form.title
          })
        } else {
          defaultGroup.items?.push(form)
        }
      }

      if (defaultGroup.items?.length) {
        groups.unshift(defaultGroup)
      }
    }

    return groups
  }

  get formListPayload (): FormListPayload {
    return {
      limit: 50,
      offset: 0
    }
  }

  get formList (): CastingFormListResult['data'] | null {
    if (this.formListPayload) {
      return this.$store.getters['$cache/result']([FORM_NAMESPACE, FORM_ACTION.LIST], this.formListPayload)?.data || null
    }

    return null
  }

  async fetchFormList () {
    if (this.formListPayload) {
      return this.storeFetchFormList(this.formListPayload)
    }
  }

  serverPrefetch () {
    return this.fetchFormList()
  }

  mounted () {
    return this.fetchFormList()
  }
}
