
import { Options, Prop, Vue, Watch } from 'vue-property-decorator'

@Options({
  name: 'ExtraHeader'
})
export default class ExtraHeader extends Vue {
  @Prop({ type: String, default: '' })
  readonly url!: string

  isMounted = false

  mounted () {
    this.isMounted = true // for lazy loading
    this.handler()
  }

  @Watch('url')
  handler () {
    if (this.url) {
      window.addEventListener('message', this.messageHandler, false)
    } else {
      window.removeEventListener('message', this.messageHandler, false)
    }
  }

  messageHandler (event: MessageEvent) {
    const heightKey = 'height:'
    const linkKey = 'link:'
    const frame = this.$el.querySelector('iframe')

    if (frame && this.url && this.url.startsWith(event.origin) && typeof event.data === 'string') {
      if (event.data.startsWith(heightKey)) {
        const height = event.data.substring(heightKey.length)
        frame.style.height = height + 'px'
      } else if (event.data.startsWith(linkKey)) {
        this.$router.push(event.data.substring(linkKey.length))
      }
    }
  }
}
