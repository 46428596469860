"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadFormDataToJSONTyped = exports.UploadFormDataToJSON = exports.UploadFormDataFromJSONTyped = exports.UploadFormDataFromJSON = void 0;
const runtime_1 = require("../runtime");
function UploadFormDataFromJSON(json) {
    return UploadFormDataFromJSONTyped(json, false);
}
exports.UploadFormDataFromJSON = UploadFormDataFromJSON;
function UploadFormDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'email': json['email'],
        'gdprContribution': json['gdprContribution'],
        'comment': !(0, runtime_1.exists)(json, 'comment') ? undefined : json['comment'],
        'files': json['files'],
    };
}
exports.UploadFormDataFromJSONTyped = UploadFormDataFromJSONTyped;
function UploadFormDataToJSON(value) {
    return UploadFormDataToJSONTyped(value, false);
}
exports.UploadFormDataToJSON = UploadFormDataToJSON;
function UploadFormDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'email': value.email,
        'gdprContribution': value.gdprContribution,
        'comment': value.comment,
        'files': value.files,
    };
}
exports.UploadFormDataToJSONTyped = UploadFormDataToJSONTyped;
