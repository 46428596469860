import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "srcset", "onerror"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _ctx.src1x || _ctx.placeholder,
    srcset: _ctx.srcset,
    onerror: `this.onerror=null; this.srcset=null; this.src='${_ctx.placeholder}';`,
    loading: "lazy"
  }, null, 8, _hoisted_1))
}