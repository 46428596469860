

import { ContentData } from '@rtl/api'
import { PropType } from '@vue/runtime-core'
import { format } from 'date-fns'
import { Options, Prop, Vue } from 'vue-property-decorator'

import { isExternalUrl } from '../../../utils'
import Image from '../Image.vue'
import NavLink from '../NavLink.vue'
import Tag from '../Tag.vue'

@Options({
  name: 'BulkContentListCard',
  components: {
    Image,
    NavLink,
    Tag
  }
})
export default class BulkContentListCard extends Vue {
  @Prop({
    type: Object as PropType<ContentData>,
    required: false
  })
  readonly contentData?: ContentData

  get url () {
    const url = this.contentData?.outerUrl || this.contentData?.url || ''
    return !url || isExternalUrl(url) || url.startsWith('/') ? url : `/${url}`
  }

  get tag () {
    return this.contentData?.headingTag
  }

  get leadImageUrl () {
    return this.contentData?.leadImageUrls?.leadImageUrl
  }

  get hour () {
    return this.contentData?.releaseDate ? format(this.contentData?.releaseDate, 'HH:mm') : ''
  }
}
