
import { mixins, Options } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { generateMetaInfo } from '../../utils'
import { GetPageMixin } from '../mixins'
import { CONTACT_FORM_ACTION, CONTACT_FORM_NAMESPACE, FAQ_GETTERS, FAQ_NAMESPACE } from './../../store'
import ConfirmScreen from './../components/contactForm/ConfirmScreen.vue'
import ContactForm from './../components/contactForm/ContactForm.vue'
import FaqFooter from './../components/faq/FaqFooter.vue'
import FaqNav from './../components/faq/FaqNav.vue'
import ArrowRight from './../components/icons/ArrowRight.vue'
import BreadCrumbRight from './../components/icons/BreadCrumbRight.vue'

@Options({
  name: 'ContactFormPage',
  components: {
    FaqNav,
    FaqFooter,
    ArrowRight,
    ContactForm,
    ConfirmScreen,
    BreadCrumbRight
  },
  metaInfo () {
    return generateMetaInfo(
      this.$route,
      this.$router,
      this.page,
      undefined,
      this.$store
    )
  }
})
export default class ContactFormPage extends mixins(GetPageMixin) {
  @Getter(FAQ_GETTERS.GET_TOPIC_NAMES, { namespace: FAQ_NAMESPACE })
  topicNames!: Array<{ label: string; tag: string | undefined }>;

  @Action(CONTACT_FORM_ACTION.SET_DATA, { namespace: CONTACT_FORM_NAMESPACE })
  setFormData!: () => void

  sent = false

  formSentConfirmed (val: boolean) {
    this.sent = val
  }

  created () {
    (this.$root as any).layout = 'div'
    this.setFormData()
  }
}
