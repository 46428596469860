
import { mixins, Options } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import {
  FAQ_GETTERS,
  FAQ_MUTATION,
  FAQ_NAMESPACE,
  FaqTopic
} from '../../store'
import { generateMetaInfo } from '../../utils'
import { GetPageMixin } from '../mixins'
import FaqAccordion from './../components/faq/FaqAccordion.vue'
import FaqContact from './../components/faq/FaqContact.vue'
import FaqFooter from './../components/faq/FaqFooter.vue'
import FaqNav from './../components/faq/FaqNav.vue'
import BreadCrumbRight from './../components/icons/BreadCrumbRight.vue'

@Options({
  name: 'FaqTopicPage',
  components: {
    FaqContact,
    FaqFooter,
    FaqNav,
    FaqAccordion,
    BreadCrumbRight
  },
  metaInfo () {
    return generateMetaInfo(
      this.$route,
      this.$router,
      this.page,
      undefined,
      this.$store
    )
  }
})
export default class FaqTopicPage extends mixins(GetPageMixin) {
  @Mutation(FAQ_MUTATION.SET_TOPIC, { namespace: FAQ_NAMESPACE })
  setTopic!: (payload: string | string[]) => void

  @Getter(FAQ_GETTERS.GET_TOPIC, { namespace: FAQ_NAMESPACE })
  topicData!: FaqTopic

  @Getter(FAQ_GETTERS.GET_QUESTION, { namespace: FAQ_NAMESPACE })
  selectedQuestion!: string | null

  get topicName () {
    return this.topicData.label
  }

  get hasSubtopic () {
    return this.topicData.subTopics[0].label !== null
  }

  get selectedSubTopic (): string | null {
    if (this.hasSubtopic) {
      return this.$route.query.tema ? this.$route.query.tema as string : this.topicData.subTopics[0].tag
    } else {
      return null
    }
  }

  selectSubtopic (tag: string | null) {
    this.$router.push({ query: { tema: tag } })
  }

  isSelectedQuestion (sub: string | null, q: string | null) : boolean {
    return sub === this.selectedSubTopic && q === this.selectedQuestion
  }

  beforeUpdate () {
    if (this.topicName !== this.$route.params.topic) {
      this.setTopic(this.$route.params.topic)
    }
  }

  created () {
    (this.$root as any).layout = 'div'
    this.setTopic(this.$route.params.topic)
  }
}
