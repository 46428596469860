"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadFormResultDataToJSONTyped = exports.UploadFormResultDataToJSON = exports.UploadFormResultDataFromJSONTyped = exports.UploadFormResultDataFromJSON = void 0;
function UploadFormResultDataFromJSON(json) {
    return UploadFormResultDataFromJSONTyped(json, false);
}
exports.UploadFormResultDataFromJSON = UploadFormResultDataFromJSON;
function UploadFormResultDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'uploaderName': json['uploaderName'],
        'uploaderEmail': json['uploaderEmail'],
        'uploaderComment': json['uploaderComment'],
        'filename': json['filename'],
        'uploadedFilename': json['uploadedFilename'],
        'fileSize': json['fileSize'],
    };
}
exports.UploadFormResultDataFromJSONTyped = UploadFormResultDataFromJSONTyped;
function UploadFormResultDataToJSON(value) {
    return UploadFormResultDataToJSONTyped(value, false);
}
exports.UploadFormResultDataToJSON = UploadFormResultDataToJSON;
function UploadFormResultDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'uploaderName': value.uploaderName,
        'uploaderEmail': value.uploaderEmail,
        'uploaderComment': value.uploaderComment,
        'filename': value.filename,
        'uploadedFilename': value.uploadedFilename,
        'fileSize': value.fileSize,
    };
}
exports.UploadFormResultDataToJSONTyped = UploadFormResultDataToJSONTyped;
