"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2023.02.22.)
 * RTL 2021 API (2023.02.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostAdminPageResetToJSONTyped = exports.PostAdminPageResetToJSON = exports.PostAdminPageResetFromJSONTyped = exports.PostAdminPageResetFromJSON = void 0;
function PostAdminPageResetFromJSON(json) {
    return PostAdminPageResetFromJSONTyped(json, false);
}
exports.PostAdminPageResetFromJSON = PostAdminPageResetFromJSON;
function PostAdminPageResetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pageId': json['pageId'],
        'layoutId': json['layoutId'],
    };
}
exports.PostAdminPageResetFromJSONTyped = PostAdminPageResetFromJSONTyped;
function PostAdminPageResetToJSON(value) {
    return PostAdminPageResetToJSONTyped(value, false);
}
exports.PostAdminPageResetToJSON = PostAdminPageResetToJSON;
function PostAdminPageResetToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pageId': value.pageId,
        'layoutId': value.layoutId,
    };
}
exports.PostAdminPageResetToJSONTyped = PostAdminPageResetToJSONTyped;
