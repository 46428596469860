
import { Options, Prop, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import { CONTACT_FORM_GETTERS, CONTACT_FORM_MUTATION, CONTACT_FORM_NAMESPACE } from './../../../store'
import Check from './../icons/Check.vue'
import ConfirmEditModal from './ConfirmEditModal.vue'

@Options({
  name: 'ContactFormBox',
  components: {
    Check,
    ConfirmEditModal
  }
})
export default class ContactFormBox extends Vue {
  @Mutation(CONTACT_FORM_MUTATION.SET_STEP, { namespace: CONTACT_FORM_NAMESPACE })
  setStep!: (payload: number) => void

  @Getter(CONTACT_FORM_GETTERS.GET_STEP, { namespace: CONTACT_FORM_NAMESPACE })
  selectedStep!: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly disabled!: boolean

  @Prop({
    type: String,
    default: ''
  })
  readonly title!: string

  @Prop({
    type: Number,
    default: 1
  })
  readonly step!: number

  showModal = false

  edit () {
    this.showModal = true
  }

  confirmEdit (confirmed: boolean) {
    if (confirmed) {
      this.setStep(this.step)
    }
    this.showModal = false
  }
}
