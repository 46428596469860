import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content-filter"
}
const _hoisted_2 = { class: "content-filter__container" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "article__featured-wrapper" }
const _hoisted_5 = { class: "content-list-page__hero-overlay" }
const _hoisted_6 = { class: "content-list-page__hero-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_Image = _resolveComponent("Image")!
  const _component_nav_link = _resolveComponent("nav-link")!
  const _component_content_card_left_img = _resolveComponent("content-card-left-img")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_banner_widget = _resolveComponent("banner-widget")!
  const _component_page_layout = _resolveComponent("page-layout")!

  return (!_ctx.contentList)
    ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
    : (_openBlock(), _createBlock(_component_page_layout, {
        key: 1,
        class: "content-list-page",
        title: _ctx.title
      }, {
        side: _withCtx(() => [
          _createVNode(_component_banner_widget, {
            zone: "medium_rectangle_1",
            "show-background": false,
            "label-position": "bottom"
          }),
          _createVNode(_component_banner_widget, {
            zone: { desktop: 'half_page', tablet: 't_medium_rectangle_2', mobile: 'm_medium_rectangle_2', app: 'app_medium_rectangle_2' },
            "show-background": false,
            "label-position": "bottom"
          })
        ]),
        default: _withCtx(() => [
          (_ctx.showFilters)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(["content-filter__button", { 'selected': !_ctx.selectedFilters.length }]),
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.selectFilter(null)), ["prevent"]))
                  }, " Összes ", 2),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterOptions, (option) => {
                    return (_openBlock(), _createElementBlock("button", {
                      key: option.value,
                      type: "button",
                      class: _normalizeClass(["content-filter__button", { 'selected': _ctx.isFilterSelected(option.value) }]),
                      onClick: _withModifiers(($event: any) => (_ctx.selectFilter(option.value)), ["prevent"])
                    }, _toDisplayString(option.label), 11, _hoisted_3))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.showHighlight && _ctx.paginate === 1 && _ctx.highlightedContent)
            ? (_openBlock(), _createBlock(_component_nav_link, {
                key: 1,
                to: _ctx.highlightedContent.url,
                class: "content-list-page__hero"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_Image, {
                      "image-url": _ctx.highlightedContent.leadImageUrls?.leadImageUrl169,
                      alt: _ctx.highlightedContent.title,
                      class: "article__featured"
                    }, null, 8, ["image-url", "alt"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.highlightedContent.title), 1)
                  ])
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentList?.items, (item, i) => {
              return (_openBlock(), _createBlock(_component_content_card_left_img, {
                key: i,
                content: item,
                "show-title": true,
                "show-tag": true,
                "show-lead": true,
                "show-image": true,
                "show-duration": true
              }, null, 8, ["content"]))
            }), 128))
          ]),
          _createVNode(_component_pagination, {
            page: _ctx.paginate,
            limit: _ctx.length,
            "total-count": _ctx.contentList?.totalCount,
            "page-param-name": "oldal"
          }, null, 8, ["page", "limit", "total-count"])
        ]),
        _: 1
      }, 8, ["title"]))
}